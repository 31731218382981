import React, { useEffect, useState } from 'react';

// custom imports
import SearchAutocomplete from 'app/components/inputs/search-input';
import { IProps } from '.';
import { sendSearchToIntercom } from '../../helpers';
import {
  InputWrapper,
  SearchButton,
  SearchIcon,
  SearchIconContainer,
  SearchInputContainer,
  TextContainer,
} from './styles';

const Search: React.FC<IProps> = ({
  isMarketplace,
  keyword,
  filter,
  setFilter,
  suggestionsCount,
  isNavbar,
  fetchSubstance,
  urlQuery,
  isElastic,
  ...props
}) => {
  const [text, setText] = useState(filter.product);
  const [options, setOptions] = useState<ISelectItem[]>([]);
  const [value, setValue] = useState<ISelectItem | undefined>({
    value: '0',
    label: '',
  });
  const amountOfOptions = 10;

  useEffect(() => {
    if (isMarketplace && keyword && keyword !== text) {
      setFilter({ ...filter, product: keyword.toLowerCase() }, urlQuery);
    }
  }, [keyword]);

  useEffect(() => {
    if (!isMarketplace) {
      setText('');
    }
  }, [isMarketplace]);

  useEffect(() => {
    if (filter.product !== text) {
      setText(filter.product);
    }
  }, [filter]);

  const handleChange = (v: string, option?: any) => {
    setText(v);
    setValue(option);
    fetchSubstance(v, setOptions);
    if (option) {
      setFilter(
        {
          ...filter,
          product: option.label.toLowerCase(),
          productId: parseFloat(option.value),
          type: parseFloat(option.type),
          isNavbar: !!isNavbar,
        },
        urlQuery
      );
    }
  };

  const handleClick = () => {
    sendSearchToIntercom(text);
    if (isMarketplace) {
      setFilter(
        {
          ...filter,
          product:
            (!isElastic &&
              text &&
              options[0] &&
              options[0].label.toLowerCase()) ||
            text.toLowerCase(),
          productId: value ? parseFloat(value.value) : undefined,
          isNavbar: !!isNavbar,
        },
        urlQuery
      );
    } else {
      setFilter(
        {
          ...filter,
          product: text.toLowerCase(),
          productId: value ? parseFloat(value.value) : undefined,
          isNavbar: !!isNavbar,
        },
        urlQuery
      );
    }
  };

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      sendSearchToIntercom(text);

      (document.activeElement as HTMLElement).blur();
      setFilter(
        {
          ...filter,
          product:
            (!isElastic &&
              text &&
              options[0] &&
              options[0].label.toLowerCase()) ||
            text.toLowerCase(),
          productId: undefined,
          isNavbar: isNavbar ? true : false,
          type: undefined,
        },
        urlQuery
      );
    }
  };

  return (
    <SearchInputContainer>
      <InputWrapper>
        <SearchIconContainer>
          <SearchIcon src="/img/search-icon.png" />
        </SearchIconContainer>
        <TextContainer>
          <SearchAutocomplete
            value={text}
            onChange={handleChange}
            placeholder={'Search by product name'}
            applyStringFilter={true}
            amountOfVisibleOptions={suggestionsCount || amountOfOptions}
            autocompleteValues={options}
            onKeyPress={handleKeyPress}
          />
        </TextContainer>
      </InputWrapper>
      <SearchButton
        id={isNavbar ? 'navbar-search-button' : 'marketplace-search-button'}
        onClick={handleClick}
      >
        <span>Search</span>
        <img src={'/img/search-icon.svg'} />
      </SearchButton>
    </SearchInputContainer>
  );
};

const Components = {
  SearchInputContainer,
  InputWrapper,
  SearchIconContainer,
  SearchIcon,
  TextContainer,
  SearchButton,
};
export { Components };
export default Search;
