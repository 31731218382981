import React from 'react';
import styled from 'styled-components';

const AdminButtonStyle = styled.div<{ marginRight?: string }>`
  border-bottom: 2px solid ${(props) => props.theme.colors.accent};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${(props) => props.theme.colors.accent};
  cursor: pointer;
  margin-top: 9px;
  margin-bottom: 9px;
  margin-right: ${(props) => props.marginRight};
`;
interface Props {
  onClick?: () => any;
  label?: string;
  marginRight?: string;
}

const AdminButton: React.FC<Props> = ({
  onClick,
  label = '',
  marginRight = '25px',
}) => {
  return (
    <AdminButtonStyle marginRight={marginRight} onClick={onClick}>
      {label}
    </AdminButtonStyle>
  );
};

export default AdminButton;
