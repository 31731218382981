import { routes } from 'app/config';
import { IWithApolloClient } from 'app/store/modules/global/types';
import { filterMarketplace } from 'app/store/modules/marketplace/actions';
import { withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import Breadcrumb from './breadcrumb';
interface DispatchProps {
  onRouteClick: (route: string, client: any) => any;
}

interface OwnProps {
  currPage?: string;
  noMargin?: boolean;
}

export default withApollo<OwnProps>(
  connect<any, DispatchProps, OwnProps & IWithApolloClient>(
    null,
    (dispatch) => {
      return {
        onRouteClick: (route: string, client: any) => {
          if (route === routes.marketplace) {
            dispatch(
              filterMarketplace({
                filter: {
                  isNavbar: false,
                  product: '',
                  productId: undefined,
                },
                client,
              })
            );
          }
        },
      };
    }
  )(Breadcrumb)
);
