import React from 'react';

const Menu: React.FC = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 7.05c0 .525.425.95.95.95h16.1a.95.95 0 00.95-.95v-.1a.95.95 0 00-.95-.95H3.95a.95.95 0 00-.95.95v.1zM3.95 13a.95.95 0 01-.95-.95v-.1a.95.95 0 01.95-.95h16.1a.95.95 0 01.95.95v.1a.95.95 0 01-.95.95H3.95zm0 5a.95.95 0 01-.95-.95v-.1a.95.95 0 01.95-.95h16.1a.95.95 0 01.95.95v.1a.95.95 0 01-.95.95H3.95z"
      fill="#186CE8"
    />
  </svg>
);

export default Menu;
