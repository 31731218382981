import { GAActions, GACategory, ReactGA } from 'client/tracker';
import TextInput from 'app/components/inputs/text-input-bordered';
import ErrorNotification from 'app/components/notifications/error-oval';
import TermsOfServiceModal from 'app/modules/landings/terms-of-service-modal';
import { _, media } from 'app/styles/breakpoint';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { IProps } from './index';
import { sg } from '../../../utils/safe-get';
import { ConditionalContainer } from '../../../components/misc/conditional-container';
import Divider from '../login-form/divider';
import TrustedByCompanies from './trusted-by-companies';
import { anonymousUserId } from 'app/config';
import { useSelector } from 'app/helpers/redux';
import isBrowser from 'app/store/utils/is-browser';

export const GetStartedContainer = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.background};
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const GetStartedFormWrapper = styled.div<{ isModal?: boolean }>`
  border-radius: 6px;
  ${(props) => (props.isModal ? 'margin-top: 0;' : 'margin-top: 35px;')}
  ${_(media.min.md)} {
    ${(props) => (props.isModal ? 'margin-bottom: 0;' : 'margin-bottom: 63px;')}
  }
  ${_(media.max.tablet)} {
    ${(props) => (props.isModal ? 'margin-bottom: 0;' : 'margin-bottom: 43px;')}
    padding-bottom: 40px;
  }
  ${_(media.max.sm)} {
    ${(props) => (props.isModal ? 'margin-bottom: 0;' : 'margin-bottom: 37px;')}
    padding-bottom: 20px;
  }
  background-color: ${(props) => props.theme.colors.white};
  display: flex;
  flex-direction: row;
  ${(props) =>
    props.isModal
      ? ''
      : `box-shadow: 0 8px 24px 0 ${props.theme.colors.shadow};`}
`;

export const GetStartedFormContainer = styled.div`
  width: 550px;
  display: flex;
  flex-direction: column;

  ${_(media.max.sm)} {
    width: 100%;
  }
`;

export const GetStartedHeader = styled.div<{ isModal?: boolean }>`
  ${_(media.max.xs)} {
    ${(props) => (props.isModal ? 'margin-top: 21px;' : 'margin-top: 41px;')}
  }
  ${(props) => (props.isModal ? 'margin-top: 41px;' : 'margin-top: 70px;')}
  padding-left: 10%;
  padding-right: 10%;
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.grayExtraDark};
`;

export const GetStartedSubHeader = styled.div`
  margin-top: 4px;
  padding-left: 10%;
  padding-right: 10%;
  margin-bottom: 13px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.grayExtraDark};
`;

export const GetStartedText = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.93;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.grayText};
  margin-top: 15px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;
export const GetStartedTextLink = styled.div`
  color: ${(props) => props.theme.colors.primary};
  text-decoration: underline;
  margin-left: 5px;
  cursor: pointer;
`;

export const InputContainer = styled.div<{ isModal?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 10%;
  padding-right: 10%;
  ${(props) => (props.isModal ? 'padding-bottom: 0;' : 'padding-bottom: 10%;')}
`;

export const GetStartedButton = styled.div`
  height: 50px;
  border-radius: 3px;
  background-color: ${(props) => props.theme.colors.warning};
  width: 100%;
  margin-top: 19px;
  font-size: 18px;
  font-weight: bold;
  line-height: 19px;
  text-align: center;
  color: ${(props) => props.theme.colors.white};
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  :hover {
    background-color: ${({ theme }) => theme.colors.orange};
  }
`;

export const LinkedinButtonContainer = styled.div`
  width: 100%;
  height: 50px;
  border-radius: 2px;
  background-color: ${(props) => props.theme.colors.blueLinkedinLight};
  :hover {
    background-color: ${(props) => props.theme.colors.blueLinkedinHover};
  }
  display: flex;
  cursor: pointer;
`;

export const LinkedinIcon = styled.div`
  background-color: ${(props) => props.theme.colors.blueLinkedinDark};
  width: 50px;
  height: 50px;
  border-radius: 2px;
  color: ${(props) => props.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  line-height: 19px;
`;

export const LinkedinText = styled.div`
  font-size: 18px;
  font-weight: bold;
  line-height: 19px;
  text-align: center;
  color: ${(props) => props.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const TermsContainer = styled.div`
  width: 100%;
  display: flex;
`;

export const TermsContainerText = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.grayText};
`;

export const TOS = styled.span`
  color: ${(props) => props.theme.colors.primary};
  text-decoration: underline;
  margin-left: 5px;
  cursor: pointer;
`;

const PipelinepharmaLogo = styled.img`
  width: 193px;
  height: 34px;
  object-fit: contain;
  margin-top: 36px;
`;

const TrustedByCompaniesWrapper = styled.div`
  ${_(media.min.md)} {
    display: flex;
  }
  ${_(media.max.tablet)} {
    display: none;
  }
`;

const TrustedByCompaniesBottomWrapper = styled.div`
  ${_(media.min.md)} {
    display: none;
  }
  ${_(media.max.tablet)} {
    display: flex;
    justify-content: center;
  }
`;

const BASE_URL = process.env.V2_API_URL;
let win: any;

export const passwordErrorMessage = 'Password is required';
export const passwordTooShortErrorMessage = 'Password too short';
export const businessEmailErrorMessage = 'Email is required';
export const businessEmailValidErrorMessage = 'Enter valid email address';

const GetStarted: React.FC<IProps> = ({
  toLogIn,
  register,
  error,
  clearRegisterError,
  isModal,
  linkedinSuccessAction,
  isLanding,
  ...props
}) => {
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [businessEmail, setBusinessEmail] = useState('');
  const [businessEmailError, setBusinessEmailError] = useState('');
  const validateEmail = (email: string) => {
    // eslint-disable-next-line
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const [formError, setFormError] = useState('');

  const [modalOpen, setModalOpen] = useState(false);
  const email = useSelector((state) => state.auth?.loggedInUser?.email);
  useEffect(() => {
    if (
      (error !== '' && formError === '') ||
      (formError !== '' && error !== formError && error !== '')
    ) {
      setFormError(error);
      clearRegisterError();
    }
  }, [error]);

  const validate = () => {
    clearRegisterError();

    let valid = true;
    const sixSymbols = 6;
    if (password === '') {
      setPasswordError(passwordErrorMessage);
      valid = false;
    } else if (password.length < sixSymbols) {
      setPasswordError(passwordTooShortErrorMessage);
      valid = false;
    }

    if (businessEmail === '') {
      setBusinessEmailError(businessEmailErrorMessage);
      valid = false;
    }
    if (!validateEmail(businessEmail)) {
      setBusinessEmailError(businessEmailValidErrorMessage);
      valid = false;
    }

    return valid;
  };

  if (
    typeof window !== 'undefined' &&
    typeof window !== undefined &&
    window !== undefined
  ) {
    window.closeModal = (loginSuccess: boolean) => {
      const uuid = isBrowser() && localStorage.getItem(anonymousUserId);
      if (loginSuccess) {
        win.close();
        ReactGA.event({
          category: GACategory ? GACategory.LinkedinAuth : '',
          action: GAActions ? GAActions.linkedinRegisterSuccess : '',
          label: '/auth/success/linkedin',
        });
        linkedinSuccessAction();
        ReactGA.event({
          category: GACategory ? GACategory.RFQ : '',
          action: GAActions ? GAActions.RFQ_GET_STARTED : '',
          label: undefined,
          anonymousUserId: uuid,
          email,
        });
      } else {
        ReactGA.event({
          category: GACategory ? GACategory.LinkedinAuth : '',
          action: GAActions ? GAActions.linkedinRegisterFailed : '',
          label: '/auth/failed/linkedin',
          email,
        });
        setFormError('Linkedin login failed');
      }
    };
  }

  return (
    <div>
      <TermsOfServiceModal
        isOpen={modalOpen}
        closeModal={() => {
          setModalOpen(false);
        }}
      />

      <Container isModal={isModal || false}>
        <GetStartedFormWrapper isModal={isModal || false}>
          <GetStartedFormContainer>
            <GetStartedHeader isModal={isModal || false}>
              Create your free account!
            </GetStartedHeader>
            <GetStartedSubHeader>
              {isModal && !isLanding
                ? 'Create an account to submit a request and track your deals.'
                : 'Join 3000+ businesses who trust Pipelinepharma'}
            </GetStartedSubHeader>
            <InputContainer isModal={isModal}>
              <form>
                <ConditionalContainer
                  show={sg(() => formError.length !== 0, false)}
                >
                  <ErrorNotification label={formError} />
                </ConditionalContainer>

                <TextInput
                  id={'sign-up-email'}
                  value={businessEmail.trim()}
                  label={'Business email address'}
                  onChange={(text: string) => {
                    setBusinessEmail(text.trim());
                    if (businessEmail !== '') {
                      setBusinessEmailError('');
                    }
                  }}
                  inputMarginTop={'9px'}
                  marginTop={'20px'}
                  error={businessEmailError}
                />
                <TextInput
                  id={'sign-up-password'}
                  value={password}
                  label={'Password'}
                  onChange={(text: string) => {
                    setPassword(text);
                    if (text !== '') {
                      setPasswordError('');
                    }
                  }}
                  type={'password'}
                  showPasswordIcon={true}
                  inputMarginTop={'9px'}
                  marginTop={'20px'}
                  autocomplete={'off'}
                  error={passwordError}
                />
                <GetStartedButton
                  data-testid="get-started-button"
                  onClick={() => {
                    if (validate()) {
                      register(password, businessEmail, isModal);
                      window.scrollTo(0, 0);
                      const uuid =
                        isBrowser() && localStorage.getItem(anonymousUserId);
                      ReactGA.event({
                        category: GACategory ? GACategory.RFQ : '',
                        action: GAActions ? GAActions.RFQ_GET_STARTED : '',
                        label: undefined,
                        anonymousUserId: uuid,
                      });
                    }
                  }}
                >
                  Get started
                </GetStartedButton>
                <Divider />
                <LinkedinButtonContainer
                  onClick={() => {
                    if (window !== undefined && typeof window !== undefined) {
                      const openInNewTab = (url: string) => {
                        win = window.open(
                          url,
                          'popUpWindow',
                          'height=500,width=500,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes'
                        );
                      };
                      openInNewTab(BASE_URL + '/auth/linkedin');
                    }
                  }}
                >
                  <LinkedinIcon>in</LinkedinIcon>
                  <LinkedinText>Continue with Linkedin</LinkedinText>
                </LinkedinButtonContainer>
              </form>
              <GetStartedText>
                Already using Pipelinepharma?
                <GetStartedTextLink
                  onClick={isModal ? props.haveAccountCallback : toLogIn}
                >
                  Log in here
                </GetStartedTextLink>
              </GetStartedText>
              <Terms callback={() => setModalOpen(true)} />
            </InputContainer>
          </GetStartedFormContainer>
          <TrustedByCompaniesWrapper>
            <TrustedByCompanies isModal={isModal} />
          </TrustedByCompaniesWrapper>
        </GetStartedFormWrapper>
      </Container>
      {!isModal && (
        <TrustedByCompaniesBottomWrapper>
          <TrustedByCompanies isModal={isModal} />
        </TrustedByCompaniesBottomWrapper>
      )}
    </div>
  );
};
const Terms: React.FC<{ callback: () => any }> = ({ callback }) => (
  <TermsContainer>
    <TermsContainerText>
      By signing up you agree to Pipelinepharma’s
      <TOS
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          callback();
        }}
      >
        Terms of Service
      </TOS>
    </TermsContainerText>
  </TermsContainer>
);
const Container: React.FC<{ isModal: boolean }> = ({ children, isModal }) =>
  isModal ? (
    <>{children}</>
  ) : (
    <GetStartedContainer>
      <PipelinepharmaLogo src={'/img/pp-logo-rebranded/logo-03.svg'} />
      {children}
    </GetStartedContainer>
  );
export default GetStarted;
