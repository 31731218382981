import React, { useCallback, useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import MenuLink from './MenuLink';
import { ActiveLinkData } from './types';
import { Menu, Close, User } from '../atoms/icons';
import { LogoHorizontal } from '../atoms/branding';
import ModalPortal from '../atoms/ModalPortal';
import { MenuSublinks } from './MenuSublink';
import { _, media } from 'app/styles/breakpoint';
import { logout } from 'app/store/modules/auth/actions';
import { AnchorButtonBase } from './MenuDesktop';

export interface MenuMobileProps {
  /**
   * What is the link data?
   */
  links: ActiveLinkData[];
  /**
   * Is the user logged in?
   */
  isLoggedIn: boolean;
  /**
   * User first name if logged in
   */
  firstName?: string;
  /**
   * class name of the component
   */
  className?: string;
}

const MenuButton = styled.button`
  grid-area: menu;
  justify-self: end;
  width: 24px;
  height: 24px;
  display: grid;
  justify-content: center;
  align-content: center;
  border-radius: 4px;
  cursor: pointer;

  margin-left: 14px;
  padding: 0;
  background: none;
  border: none;

  &:hover {
    background-color: ${(props) => props.theme.colors.gray100};
  }

  &:active {
    background-color: ${(props) => props.theme.colors.gray300};
  }
`;

const slideAnimation = keyframes`
    from {
        transform: translateX(100%);
        opacity: 0.5;
        width: 0;
        right: -48px;
        padding: 12px 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
        width: calc(100% - 48px);
        right: 0;
        padding: 12px 24px;
    }
`;

const MobileMenuContainer = styled.div<{ $open: boolean }>`
  width: 0;
  height: 100%;
  min-height: 100vh;
  position: absolute;
  right: -48px;
  overflow-x: hidden;
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px 0;

  background-color: ${(props) => props.theme.colors.white};

  ${({ $open }) =>
    $open
      ? css`
          animation-name: ${slideAnimation};
        `
      : ''};
  animation-duration: 300ms;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  z-index: 9000;
`;

const MainMenu = styled.div`
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-auto-rows: min-content;
  grid-row-gap: 28px;
`;

const UserMenu = styled.div`
  padding: 28px 0 12px;
  width: min-content;
`;

const CloseButton = styled.button`
  justify-self: end;
  align-self: center;
  width: 24px;
  height: 24px;
  display: grid;
  justify-content: center;
  align-content: center;
  border-radius: 4px;
  cursor: pointer;

  margin-left: 14px;
  padding: 0;
  background: none;
  border: none;

  &:hover {
    background-color: ${(props) => props.theme.colors.gray100};
  }

  &:active {
    background-color: ${(props) => props.theme.colors.gray300};
  }

  & > svg {
    fill: ${(props) => props.theme.colors.black};

    &:hover {
      fill: ${(props) => props.theme.colors.secondary400};
    }

    &:active {
      fill: ${(props) => props.theme.colors.secondary600};
    }
  }
`;

const StyledMenuLink = styled(MenuLink)`
  grid-column: 1 / -1;
  padding: 0;
  justify-self: start;
`;

const StyledBoldMenuLink = styled(StyledMenuLink)`
  font-weight: 700;
  justify-self: start;
`;

const StyledMenuSublinks = styled(MenuSublinks)`
  grid-column: 1 / -1;
  display: grid;
  /* grid-row-gap: 28px; */
  justify-self: start;
  > div:last-child {
    grid-row-gap: 28px;
    padding: 0 0 0 32px;
  }
`;

const SublinkWithCount = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 8px;
`;

const SublinkCount = styled.span`
  color: rgb(24, 108, 232);
  background-color: #eff5fe;
  padding: 2px 8px;
  border-radius: 4px;
  font-weight: 700;
`;

const StyledIconLink = styled.a`
  width: 152px;
  ${_(media.max.sm)} {
    & > svg {
      width: 150px;
    }
  }
`;

const RawLink = styled.span`
  cursor: pointer;
  padding: 0;
  font-weight: 700;
  width: max-content;
`;

const Mobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
`;

const SignInButton = styled(AnchorButtonBase)`
  grid-column: 1 / -1;
  margin: -12px 0 -16px;
  border-color: ${(props) => props.theme.colors.tertiaryMain};
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.tertiaryMain};
`;
const SignUpButton = styled(AnchorButtonBase)`
  grid-column: 1 / -1;
  margin-bottom: 8px;
`;

const MenuMobile: React.FC<MenuMobileProps> = ({
  links,
  isLoggedIn,
  firstName = '',
  className = '',
}) => {
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  /**
   * @description     Close the modal when path changes, usually a redirect has happened
   *                  as a result of the user clicking one of the options in this component
   */
  useEffect(() => {
    setOpen(false);
  }, [pathname, setOpen]);

  /**
   * @description     Close the modal
   */
  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <Mobile className={className}>
      <MenuButton onClick={() => setOpen(true)}>
        <Menu />
      </MenuButton>
      <ModalPortal open={open} onBackdropClick={handleClose}>
        <MobileMenuContainer
          $open={open}
          onClick={(event) => event.stopPropagation()}
        >
          <MainMenu>
            <StyledIconLink href="/">
              <LogoHorizontal />
            </StyledIconLink>

            <CloseButton onClick={handleClose}>
              <Close />
            </CloseButton>
            {isLoggedIn ? null : (
              <>
                <SignInButton href="/login">Sign in</SignInButton>
                <SignUpButton href="/get-started">Sign up</SignUpButton>
              </>
            )}
            {links.length > 0 &&
              links.map(({ sublinks, link, ...linkData }) =>
                sublinks ? (
                  <StyledMenuSublinks
                    key={linkData.text}
                    label={<RawLink>{linkData.text}</RawLink>}
                    isOpenInitially
                    {...linkData}
                  >
                    {sublinks.map(
                      ({
                        link: sublink,
                        text: sublinkText,
                        count: sublinkCount,
                        ...sublinkData
                      }) => (
                        <StyledMenuLink
                          key={sublinkText}
                          link={sublink as string}
                          {...sublinkData}
                        >
                          {sublinkCount !== undefined ? (
                            <SublinkWithCount>
                              <span>{sublinkText}</span>
                              <SublinkCount>{sublinkCount}</SublinkCount>
                            </SublinkWithCount>
                          ) : (
                            sublinkText
                          )}
                        </StyledMenuLink>
                      )
                    )}
                  </StyledMenuSublinks>
                ) : (
                  <StyledBoldMenuLink
                    key={linkData.text}
                    {...linkData}
                    link={link as string}
                  >
                    {linkData.text}
                  </StyledBoldMenuLink>
                )
              )}
          </MainMenu>
          {isLoggedIn ? (
            <UserMenu>
              <StyledMenuSublinks
                label={
                  <>
                    <User />
                    <RawLink>{firstName}</RawLink>
                  </>
                }
                isRight
                isOpenInitially
              >
                <StyledMenuLink link="/user-settings" active={false} isSublink>
                  Settings
                </StyledMenuLink>
                <StyledMenuLink
                  active={false}
                  onClick={() => dispatch(logout())}
                >
                  Logout
                </StyledMenuLink>
              </StyledMenuSublinks>
            </UserMenu>
          ) : null}
        </MobileMenuContainer>
      </ModalPortal>
    </Mobile>
  );
};

export default MenuMobile;
