import gql from 'graphql-tag';
import { useState, useEffect, useMemo } from 'react';
import { useApolloClient } from 'react-apollo';
import { LinkData, ActiveLinkData } from './types';

// TODO: Figure out if there are any already used method to simplify where query
const navCount = gql`
  query admin_onboarding_aggregate {
    admin_onboarding_aggregate(
      where: {
        _and: [
          {
            _or: [
              {
                Product: {
                  _and: [
                    { deleted_at: { _is_null: true } }
                    { form_specification: { _is_null: false } }
                    { awaiting_approval: { _eq: true } }
                    { strength: { _is_null: false } }
                    {
                      _and: [
                        { Company: { _and: [{ hidden: { _eq: false } }] } }
                      ]
                    }
                  ]
                }
              }
            ]
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

const updateAdminLinks = (adminLinks: LinkData[], onboardingCount?: number) => {
  if (!onboardingCount) {
    return adminLinks;
  }
  return adminLinks.map((link) => {
    // TODO: Find better way to select link that need to get count
    if (link.text === 'Products') {
      const mappedSublinks = link.sublinks?.map((sublink) => {
        if (sublink.text === 'Onboarding') {
          return {
            ...sublink,
            count: onboardingCount,
          };
        }
        return sublink;
      });
      return {
        ...link,
        sublinks: mappedSublinks,
      };
    }
    return link;
  });
};

export const useActiveLinks = ({
  publicLinks,
  privateLinks,
  adminLinks,
  pathname,
  isLoggedIn,
  isAdmin,
}: {
  publicLinks: LinkData[];
  privateLinks: LinkData[];
  adminLinks: LinkData[];
  pathname: string;
  isLoggedIn: boolean;
  isAdmin: boolean;
}) => {
  const [links, setLinks] = useState<ActiveLinkData[]>([]);
  const [onboardingCount, setOnboardingCount] = useState<number | undefined>();
  const client = useApolloClient();

  useEffect(() => {
    if (!isAdmin) {
      return;
    }
    client
      .query({
        query: navCount,
        fetchPolicy: 'no-cache',
      })
      .then(({ data }: any) => {
        setOnboardingCount(data?.admin_onboarding_aggregate.aggregate.count);
      });
  }, [isAdmin, client]);

  const currentLinks = useMemo(
    () =>
      isLoggedIn
        ? isAdmin
          ? updateAdminLinks(adminLinks, onboardingCount)
          : privateLinks
        : publicLinks,
    [
      isLoggedIn,
      isAdmin,
      publicLinks,
      privateLinks,
      adminLinks,
      onboardingCount,
    ]
  );

  const activeLinks = useMemo(
    () =>
      currentLinks.map(({ text, link, force = false, sublinks }) => {
        const active = pathname === link || pathname === `${link}[slug]`;
        const activeSublinks = sublinks?.length
          ? sublinks.map(({ link, text, force, count }) => ({
              active: pathname === link || pathname === `${link}[slug]`,
              text,
              link,
              force,
              count,
            }))
          : undefined;
        return {
          active,
          text,
          link,
          force,
          sublinks: activeSublinks,
        };
      }),
    [currentLinks, pathname]
  );

  // Reset links when user login status or active links list changes
  useEffect(() => {
    setLinks(activeLinks);
  }, [isLoggedIn, activeLinks]);

  return links;
};
