import Tooltip from 'app/components/tooltip/tooltip-component';
import React from 'react';
import styled from 'styled-components';
import { ConditionalContainer } from '../../misc/conditional-container';

const InputContainer = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  margin-right: 20px;
  position: relative;
`;

const Label = styled.label`
  font-size: 14px;
  line-height: 22px;
  color: ${(props) => props.theme.colors.grayExtraDark};
  cursor: pointer;
  margin-left: 10px;
`;

const Icon = styled.svg`
  flex: none;
  width: 20px;
  height: 20px;
`;

const IconSmall = styled.svg`
  flex: none;
  width: 6px;
  height: 6px;
`;

const IconPath = styled.path<{ selected: boolean }>`
  fill: ${(props) =>
    props.selected ? props.theme.colors.accent : props.theme.colors.muted};
  ${InputContainer}:hover & {
    fill: ${(props) => props.theme.colors.accent};
  }
`;

const SmallIconContainer = styled.div`
  position: absolute;
  display: flex;
`;
const RadioContainer = styled.div`
  position: relative;
  align-items: center;
  display: flex;
  justify-content: center;
`;

const LabelContainer = styled.div`
  align-items: center;
  display: flex;
`;

const IconCircle = styled.circle`
  fill: ${(props) => props.theme.colors.accent};
`;

interface Props<T> {
  value: T;
  option: T;
  onChange: (value: T | undefined) => any;
  label: string;
  tooltip?: string;
  id?: string;
  className?: string;
}
const Checkbox: <T>(props: Props<T>) => React.ReactElement<Props<T>> = ({
  label,
  value,
  onChange,
  option,
  tooltip,
  id,
  className,
}) => {
  return (
    <InputContainer
      className={className}
      id={id || label}
      onClick={() => {
        if (value === option) {
          onChange(undefined);
        } else {
          onChange(option);
        }
      }}
    >
      <RadioContainer>
        <Icon
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
        >
          <IconPath
            selected={Object.is(value, option)}
            d={
              'M10 19a9 9 0 1 0 0-18 9 9 0 0 0 0 18zm0 1C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10z'
            }
          />
        </Icon>
        <ConditionalContainer show={Object.is(value, option)}>
          <SmallIconContainer>
            <IconSmall
              xmlns="http://www.w3.org/2000/svg"
              width="6"
              height="6"
              viewBox="0 0 6 6"
            >
              <IconCircle
                cx="11"
                cy="184"
                r="3"
                fill="#FCA216"
                fillRule="evenodd"
                transform="translate(-8 -181)"
              />
            </IconSmall>
          </SmallIconContainer>
        </ConditionalContainer>
      </RadioContainer>
      <LabelContainer>
        <Label>{label}</Label>
        <Tooltip tooltip={tooltip} />
      </LabelContainer>
    </InputContainer>
  );
};

export const Components = {
  InputContainer,
  Label,
  Icon,
  IconSmall,
  IconPath,
  SmallIconContainer,
  RadioContainer,
  LabelContainer,
  IconCircle,
};
export default Checkbox;
