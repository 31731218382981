import React from 'react';
import styled from 'styled-components';

const InputContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
`;

const CheckboxStyle = styled.div<{ checked: boolean }>`
  margin-right: 12px;
  width: 25px;
  height: 25px;
  background-color: ${(props) => props.theme.colors.checkBoxBackground};
  padding: 2;
  border: solid 1px ${(props) => props.theme.colors.white};
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  border: solid 1px ${(props) => props.theme.colors.muted};
  ${InputContainer}:hover & {
    border: solid 1px ${(props) => props.theme.colors.warning};
  }
  ${(props) =>
    props.checked && `border: solid 1px ${props.theme.colors.warning};`}
`;

const CheckStyles = styled.img`
  width: 12px;
  height: 9.5px;
  object-fit: contain;
  user-select: none;
`;

const Label = styled.label`
  font-size: 14px;
  line-height: 22px;
  color: ${(props) => props.theme.colors.grayExtraDark};
  cursor: pointer;
`;

interface Props {
  value: boolean;
  label?: string;
  onChange: (value: boolean) => any;
}

const CheckboxSimple: React.FC<Props> = ({ label, value, onChange }) => {
  return (
    <InputContainer id={label}>
      <div>
        <CheckboxStyle
          checked={value}
          onClick={(ev) => {
            ev.stopPropagation();
            onChange(!value);
          }}
        >
          {value && <CheckStyles src="/img/check.svg" />}
        </CheckboxStyle>
      </div>
      {label && (
        <div>
          <Label>{label}</Label>
        </div>
      )}
    </InputContainer>
  );
};

export default CheckboxSimple;
