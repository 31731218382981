import { connect } from 'react-redux';
import ProductInfo from './product-info';

import { routes } from 'app/config';
import { IWithApolloClient } from 'app/store/modules/global/types';
import { fetchProductSuccess } from 'app/store/modules/marketplace/actions';
import { AnyListItem, Product } from 'app/store/modules/products/types';
import { IStore } from 'app/store/types';
import { withApollo } from 'react-apollo';
import { RouteComponentProps, withRouter } from 'react-router';
import { getProductEligibleToAddInDeal } from '../../../../../../store/modules/marketplace/query';
import sg from '../../../../../../utils/safe-get';

export interface StateProps {
  product?: Product;
  isAdmin: boolean;
  userId: number;
  userCompanyId: number;
  authCheckComplete: boolean;
}

export interface DispatchProps {
  fetchProductAction: (product: Product) => any;
  userHasDeal: (
    userId: number,
    isAdmin: boolean,
    substance1: string,
    substance2?: string | undefined,
    substance3?: string | undefined,
    doseFormProduct?: string | undefined,
    notAvailableMarkets?: string | undefined
  ) => any;
}

export interface OwnProps {
  productListItem: AnyListItem;
  type: number;
  productId: number;
  isMarketplace?: boolean;
}

export default withRouter<any, any>(
  withApollo<OwnProps & RouteComponentProps<any>>(
    //
    connect<
      StateProps,
      DispatchProps,
      OwnProps & RouteComponentProps<any> & IWithApolloClient,
      IStore
    >(
      (
        state: IStore,
        ownProps: OwnProps & RouteComponentProps<any> & IWithApolloClient
      ) => {
        const product = ownProps.productListItem.productId
          ? state.marketplace.marketplaceProductCache[
              ownProps.productListItem.productId
            ]
          : undefined;

        return {
          product,
          isAdmin: state.auth.loggedInUser
            ? state.auth.loggedInUser.isAdmin
            : false,
          isLoggedIn: state.auth.loggedIn,
          userId: state.auth.loggedInUser.id,
          userCompanyId:
            state.auth.loggedInUser && state.auth.loggedInUser.Company
              ? state.auth.loggedInUser.Company.id
              : 0,
          authCheckComplete: state.auth.authCheckComplete,
        };
      },
      (
        dispatch,
        ownProps: OwnProps & RouteComponentProps<any> & IWithApolloClient
      ) => {
        return {
          fetchProductAction: (product?: Product) => {
            if (product) {
              dispatch(fetchProductSuccess(product));
            } else {
              ownProps.history.push(routes.home);
            }
          },
          userHasDeal: async (
            userId,
            isAdmin,
            substance1,
            substance2,
            substance3,
            doseFormCategory,
            notAvailableMarkets
          ) => {
            const eligibleDeals: any[] = [];
            if (doseFormCategory) {
              const res = await getProductEligibleToAddInDeal({
                client: ownProps.client,
                userId,
                isAdmin,
                substance1,
                substance2,
                substance3,
                doseFormCategory,
              });
              const activeDeals = sg(() => res!.data.deals, undefined);
              if (activeDeals) {
                activeDeals.map((d: any) => {
                  if (notAvailableMarkets && d.countriesOfInterest !== null) {
                    const markets = notAvailableMarkets.split(',');
                    const intersection = markets!.filter((country: string) =>
                      d.countriesOfInterest.includes(country)
                    );
                    if (intersection.length < 1) {
                      eligibleDeals.push(d);
                    }
                  } else {
                    eligibleDeals.push(d);
                  }
                });
              }
            }
            return eligibleDeals.length > 0 ? 1 : 0;
          },
        };
      }
    )(ProductInfo)
  )
);
