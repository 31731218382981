import GetStartedButton from 'app/components/buttons/get-started-button';
import CookieModal from 'app/components/cookie/cookie-window';
import { Components } from 'app/components/inputs/search-input';
import SuggestAlternativeSellersModal from 'app/components/modals/suggest-alternative-sellers-modal';
import SearchInput, {
  Components as WrapperComponents,
} from 'app/components/search/components/search-input';
import { routes } from 'app/config';
import { isBrowser } from 'app/store/utils/is-browser';
import { _, Breakpoint, media } from 'app/styles/breakpoint';
import { useMeasure } from 'app/utils/hooks';
import React from 'react';
import { Link as ReduxLink, NavLink } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';
import styled, { css } from 'styled-components';
import { IProps } from './index';
import { ConditionalContainer } from '../conditional-container';
import MenuSection from 'app/components/next/MenuSection';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const IntercomAPI = require('react-intercom').IntercomAPI;

const maxEmailLengthToShow = 9;
const modalClassName = 'ReactModal__Body--open';

interface ISearchContainer {
  visible: boolean;
  open: boolean;
}

const SocialContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 200px;
  margin-top: 15px;
  margin-bottom: 15px;
  height: 100%;
  margin-right: 5%;
  margin-left: 5%;
`;
const SocialItem = styled.a`
  width: 48px;
  height: 48px;
  border-radius: 24px;
  background-color: ${({ theme }) => theme.colors.socialItemBackground};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    background-color: ${({ theme }) => theme.colors.primary};
  }
`;
const LogedInContainer = styled.div`
  align-items: center;
  cursor: pointer;
  :hover {
    color: ${({ theme }) => theme.colors.grayExtraDark};
  }
  ${_(media.max.tablet)} {
    width: 100%;
    min-height: 60px;
    max-height: 60px;
  }

  &.drop-down {
    max-height: unset;
  }
  .down-icon {
    height: 6px;
    box-sizing: content-box;
    padding: 5px;
  }
  .user {
    display: flex;
    min-height: 60px;
    max-height: 60px;
  }
  .user > * {
    margin-top: auto;
    margin-bottom: auto;
  }
  .user,
  .logged-drop-down > div > * {
    padding-left: 5%;
  }
`;
const Icon = styled.svg`
  flex: none;
  .drop-down {
    fill: ${({ theme }) => theme.colors.navbarNotActive};
  }
  .icon {
    fill: ${({ theme }) => theme.colors.muted};
  }
  .avatar {
    fill: ${({ theme }) => theme.colors.muted};
  }
  ${SocialItem}:hover & .icon {
    fill: ${({ theme }) => theme.colors.white};
  }
  ${LogedInContainer}:hover & .avatar {
    fill: ${({ theme }) => theme.colors.accent};
  }
`;

const NavbarItemStyle = styled(ReduxLink)`
  cursor: pointer;
  font-family: 'Nunito Sans';
  color: ${({ theme }) => theme.colors.navbarNotActive};
  font-weight: 600;
  margin-right: 30px;

  ${_(media.max.tablet)} {
    display: flex;
  }
  ${_(media.max.tablet)} {
    &.hide {
      display: none;
    }
  }
`;

const ShowMobile = styled.li`
  display: none !important;
  ${_(media.max.tablet)} {
    display: flex !important;
    flex-direction: column;
    align-items: flex-start;
  }
`;
const HideMobile = styled.li`
  display: flex !important;
  ${_(media.max.tablet)} {
    display: none !important;
  }
`;
const TopNav = styled.section`
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 4px 24px 0 rgba(53, 64, 82, 0.08);
  background-color: ${({ theme }) => theme.colors.white};
  padding: 0 42px;
  height: 100%;

  ${_(media.max.sm)} {
    padding: 0 5%;
  }
  ${_(media.max.tablet)} {
    .menu-wrapper {
      display: none;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      box-shadow: 0 4px 24px 0 rgba(53, 64, 82, 0.08);
      z-index: -1;
      background-color: white;
      overflow: auto;
    }
  }
`;

const Menu = styled.ul`
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin: 0;
  padding: 0;
  > li {
    display: flex;
    align-items: center;
  }
  > ${ShowMobile}, > ${HideMobile} {
    align-items: center;
  }

  ${_(media.max.tablet)} {
    min-height: 100%;
    width: 100%;

    flex-direction: column;
    justify-content: center;
    align-items: center;

    > li {
      justify-content: flex-start;
      margin: 0;
      width: 100%;
      background-color: ${({ theme }) => theme.colors.white};
    }
    > li:not(:last-child) {
      border-bottom: 1px solid ${({ theme }) => theme.colors.grayLight};
    }
    > ${ShowMobile}, > ${HideMobile} {
      justify-content: center;
    }
    li {
      min-height: 60px;
      height: fit-content;
      height: auto;
    }
    li:last-child {
      margin-top: auto;
    }
  }
`;

const SmallImg = styled.img`
  display: none;
  width: 49px !important;
  max-height: 35px;

  ${_(media.max.md)} {
    display: block;
  }
  ${_(media.max.tablet)} {
    width: 28px !important;
    display: none;
  }
  /* ${_(media.max.sm)} {
    display: block;
  } */
`;
const LargeLogo = styled.img`
  width: 194px;
  height: 35px;
  position: relative;
  top: -3px;
  ${_(media.max.md)} {
    display: none;
  }
  ${_(media.max.tablet)} {
    display: block;
  }
  ${_(media.max.sm)} {
    display: none;
    /* width: 133px;
    height: 24px; */
  }
`;

const MobileLogo = styled.img`
  width: 153px;
  height: 26px;
  display: none;

  ${_(media.max.sm)} {
    display: block;
  }
`;

const NavbarLink = styled(NavLink)`
  white-space: nowrap;
  cursor: pointer;
  font-family: 'Nunito Sans';
  color: ${({ theme }) => theme.colors.navbarNotActive};
  font-weight: 600;
  margin-right: 30px;
  ${_(media.min.tablet)} {
    min-height: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  ${_(media.max.tablet)} {
    margin: 0;
    padding: 20px 0;
    width: 100%;
  }
  &.logout {
    display: flex;
    flex-direction: row;
  }
  &.active {
    color: ${({ theme }) => theme.colors.primary};
  }
  &.margin-6 {
    margin-right: 6px;
  }
  &.left-padding-10 {
    padding-left: 10px;
  }
  &.right-padding-5 {
    padding-right: 5px;
  }
  &.sign-up {
    margin-right: 0;
  }
  &.logout {
    &:hover {
      color: ${({ theme }) => theme.colors.primary};
      .drop-down {
        fill: ${({ theme }) => theme.colors.primary};
      }
    }
    display: flex;
    padding-left: 5%;
    p {
      margin-left: 13px;
    }
  }
  p {
    width: 100%;
    margin: auto;
    font-size: 15px;
    margin-left: 5%;

    ${_(media.max.tablet)} {
      width: 95%;
    }
  }
  :hover {
    color: ${({ theme }) => theme.colors.primary};
  }
  ${Menu} li.blue-button & {
    > a {
      max-height: 40px;
    }
    &.active p,
    &:hover p {
      background-color: ${({ theme }) => theme.colors.primaryHover};
    }
    ${_(media.max.tablet)} {
      margin: 0;
      padding: 10px 0;
      width: 100%;
    }
    p {
      min-width: 151px;
      width: fit-content;
      border-radius: 2px;
      padding: 10px 20px;
      color: ${({ theme }) => theme.colors.white};
      letter-spacing: 0.3px;
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }
`;
const MenuButtonContainer = styled.label`
  height: 16px;
  padding: 4px 0 4px 19px;
  box-sizing: content-box;
  display: none;
  cursor: pointer;
  border-left: 1px solid ${({ theme }) => theme.colors.grayLight};
  margin-top: auto;
  margin-bottom: auto;
  ${_(media.max.tablet)} {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`;
const MenuButton = styled.div`
  position: relative;
  margin-top: auto;
  margin-bottom: auto;

  &,
  ::before,
  ::after {
    display: block;
    transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
    background-color: ${({ theme }) => theme.colors.grayExtraDark};
    height: 2px;
    width: 19px;
    border-radius: 1px;
  }
  ::before,
  ::after {
    position: absolute;
    content: '';
  }
  ::before {
    margin-top: -7px;
  }
  ::after {
    margin-top: 7px;
  }
`;
const MenuToggle = styled.input`
  display: none;
  :checked + ${MenuButtonContainer} ${MenuButton}::before {
    margin-top: 0px;
    transform: rotate(45deg);
  }
  :checked + ${MenuButtonContainer} ${MenuButton} {
    background: rgba(255, 255, 255, 0);
  }
  :checked + ${MenuButtonContainer} ${MenuButton}::after {
    margin-top: 0px;
    transform: rotate(-45deg);
  }

  ${_(media.max.tablet)} {
    &.open-menu:checked ~ .menu-wrapper {
      display: block;
    }
  }
`;
export const NavbarProfile = styled.div`
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.navbarNotActive};
  border-left: 1px solid ${({ theme }) => theme.colors.grayLight};
  padding-left: 30px;

  > div {
    padding-left: 10px;
    padding-right: 5px;
    margin-top: auto;
    margin-bottom: auto;
  }
  > div:first-child {
    padding-left: 0px;
    display: flex;
  }
`;
const LoginContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

export const ProfileAvatarPlaceholder = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 5px;
`;
const LogedUser = styled.div`
  cursor: pointer;
  white-space: nowrap;
  font-family: 'Nunito Sans';
  color: ${({ theme }) => theme.colors.navbarNotActive};
  font-weight: 600;
  margin-left: 13px;
  margin-right: 9px;
  :hover {
    color: ${({ theme }) => theme.colors.grayExtraDark};
  }
  ${NavbarProfile}:hover {
    color: ${({ theme }) => theme.colors.grayExtraDark};
  }
`;
const HideableContainer: React.FC<{ open?: boolean }> = ({
  children,
  open = false,
}) => {
  const [bind, { height: viewHeight }] = useMeasure();
  const spring = useSpring({
    height: open ? viewHeight : 0,
  });
  if (!isBrowser()) {
    return <div {...bind}>{children}</div>;
  }

  return (
    <animated.div
      className="logged-drop-down"
      style={{
        overflow: 'hidden',
        ...spring,
      }}
    >
      <div {...bind}>{children}</div>
    </animated.div>
  );
};
const LogedDropDown = styled.div`
  position: absolute;
  top: 66px;
  display: none;
  background-color: ${({ theme }) => theme.colors.white};
  transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
  height: 0;
  box-shadow: 0 4px 28px 0 rgba(53, 64, 82, 0.1);

  ${_(media.min.md)} {
    right: 30px;
  }

  ${NavbarProfile}:hover &,
  &:hover {
    flex-direction: column;
    align-items: center;
    height: fit-content;
    display: flex;
    width: 175px;
  }
`;

const NavbarDropdownLink = styled(NavLink)`
  cursor: pointer;
  font-family: 'Nunito Sans';
  width: 100%;
  font-weight: 600;
  min-height: 60px;
  max-height: 60px;
  display: flex;
  padding-right: 26px;
  padding-left: 26px;

  ${_(media.max.tablet)} {
    padding-right: 5%;
    padding-left: 5%;
  }
  .logged-drop-down & {
    padding-left: 0px;
  }
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.navbarNotActive};
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.primary};
  border-width: 0;

  &.active,
  :hover {
    color: ${({ theme }) => theme.colors.primary};
    background-color: ${({ theme }) => theme.colors.primaryLight};
    .drop-down {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }
  &.active {
    border-left-width: 3px;
  }
  p {
    margin: auto auto auto 15px;
  }
`;
const AllContainer = styled.div`
  position: fixed;
  width: 100%;
  z-index: 100;

  &,
  & ~ .navbar-height {
    height: ${(props) => props.theme.spacing.header}px;
  }
  .menu-wrapper {
    top: ${(props) => props.theme.spacing.header}px;
  }

  ${_(media.max.sm)} {
    &,
    & ~ .navbar-height {
      height: 52px;
    }

    .menu-wrapper {
      top: 52px;
    }
  }
  ${_(media.max.tablet)} &.toggle {
    height: 100%;
  }

  img {
    object-fit: contain;
  }
`;
const SubLinkContainerWrapper = styled.div`
  display: block;
  width: 100%;
  padding-right: 5%;
  padding-left: 5%;
  ${_(media.max.tablet)} {
    padding-bottom: 20px;
  }
`;
const SubLinkContainer = styled(NavLink)`
  display: none;
  width: 100%;
  min-height: 40px;
  max-height: 40px;
  padding-top: 10px;
  padding-left: 33px;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.navbarNotActive};

  ${_(media.max.tablet)} {
    display: flex;
  }

  &.active,
  :hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;
const SubLinkText = styled.div`
  font-size: 14px;
  font-weight: 600;
  font-family: 'Nunito Sans';
  margin-left: 3px;
`;
const SubLinkCount = styled.div`
  height: 14px;
  border-radius: 7px;
  background-color: ${({ theme }) => theme.colors.primaryLight};
  font-size: 10px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.primary};
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-left: 7px;
  margin-top: 2px;
  padding-left: 5px;
  padding-right: 5px;

  &.active {
    background-color: ${({ theme }) => theme.colors.grayExtraDark};
  }
`;
const SearchInputOverride = styled.div`
  flex: 1;
  display: none;
  border: none;
  margin-top: auto;
  margin-bottom: auto;

  ${WrapperComponents.SearchIconContainer}, ${WrapperComponents.SearchButton} {
    display: none;
  }

  ${Components.SelectInputContainer} {
    flex: 1;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }
  ${Components.Input},
  ${WrapperComponents.TextContainer},
  ${WrapperComponents.SearchInputContainer} {
    padding: 0;
    ${_(media.min.md)} {
      font-size: 15px;
    }
  }
  ${Components.SelectInputContainer},
  ${WrapperComponents.SearchInputContainer} {
    height: unset;
  }
  ${WrapperComponents.SearchInputContainer} {
    box-shadow: none;
  }

  ${Components.OptionsContainer} {
    ${_(media.max.tablet)} {
      position: fixed;
      top: ${(props) => props.theme.spacing.header}px;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: white;
      border-width: 0;
      border-top-width: 1px;

      max-height: unset;
      max-width: unset;
      border-radius: unset;
    }
    ${_(media.max.sm)} {
      top: 52px;
    }
  }
`;
const SearchContainer = styled.div<ISearchContainer>`
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  position: relative;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: 15px;
  border-width: 0;
  border-style: solid;
  cursor: text;
  border-color: rgba(53, 64, 82, 0.08);

  .search-input-element-class,
  .search-input-element-class::placeholder {
    font-size: 15px;
  }

  ${_(media.min.md)} {
    flex: 1;
    padding-left: 25px;
    border-left-width: 1px;
    height: 100%;

    ${SearchInputOverride} {
      display: flex;
    }

    ${Icon} {
      cursor: default;
    }
  }
  ${Icon} {
    margin: auto;
    /* margin-left: 15px; */
    margin-right: 12px;
    cursor: pointer;
  }
  ${(props) =>
    props.open &&
    css`
      ${_(media.sm)} {
        margin-left: 2.5%;
        border-left-width: 1px;
      }
    `}
  ${(props) =>
    props.open &&
    css`
      flex: 1;
      ${SearchInputOverride} {
        display: flex;
      }
      ${Icon} {
        cursor: default;
      }
    `}
`;

const RotatingArrow: React.FC<{ open?: boolean }> = ({
  open = false,
  children,
}) => {
  const spring = useSpring(
    open
      ? {
          transform: 'rotate(180deg)',
        }
      : {
          transform: 'rotate(0deg)',
        }
  );
  return (
    <animated.div
      style={{
        ...spring,
        height: 16,
        transformOrigin: 'center center',
      }}
    >
      {children}
    </animated.div>
  );
};

interface IState {
  toggle: boolean;
  searchOpen: boolean;
  navbarSearchvisible: boolean;
  toggleDropDown: boolean;
}

class Navbar extends React.Component<IProps, IState> {
  private reff: React.RefObject<HTMLDivElement>;
  public constructor(props: IProps) {
    super(props);

    this.state = {
      toggle: false,
      searchOpen: false,
      navbarSearchvisible: false,
      toggleDropDown: false,
    };

    this.reff = React.createRef();

    this.renderDropDownLinks = this.renderDropDownLinks.bind(this);
    this.renderLogedIn = this.renderLogedIn.bind(this);
    this.handleOnScroll = this.handleOnScroll.bind(this);
    this.toggleSearch = this.toggleSearch.bind(this);
  }

  public componentDidMount() {
    this.handleOnScroll();

    if (window) {
      window.addEventListener('scroll', this.handleOnScroll);
    }
    if (!this.props.navbarCountFetchComplete) {
      this.props.fetchNavCount();
    }
  }
  public componentWillUnmount() {
    document.body.classList.remove(modalClassName);
    if (window) {
      window.removeEventListener('scroll', this.handleOnScroll);
    }
  }
  // eslint-disable-next-line
  public componentWillReceiveProps(nextProps: IProps) {
    if (!this.props.checkAuthComplete && nextProps.checkAuthComplete) {
      this.props.fetchNavCount();
      if (!this.props.navbarCountFetchComplete) {
        this.props.fetchNavCount();
      }
    }
  }
  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (
      this.state.toggle &&
      !document.body.classList.contains(modalClassName)
    ) {
      document.body.classList.add(modalClassName);
    } else if (
      !this.state.toggle &&
      !this.props.suggestAlternativeSellersModalOpen
    ) {
      document.body.classList.remove(modalClassName);
    }

    const {
      match: { url },
      screenWidth,
    } = prevProps;
    const mobileWidth = 500;
    if (
      url &&
      (url.includes('/request-details') || url.includes('/archive-info')) &&
      screenWidth < mobileWidth
    ) {
      IntercomAPI('update', {
        hide_default_launcher: true,
      });
    } else {
      IntercomAPI('update', {
        hide_default_launcher: false,
      });
    }
  }

  public render() {
    const {
      match: { url },
    } = this.props;
    const { toggle, searchOpen, navbarSearchvisible } = this.state;

    const isMarketplace =
      url.includes('/product/') ||
      url.includes('/trade-product/') ||
      url.includes('/wholesale-product/') ||
      url.includes('/offer/') ||
      url.includes('/trade-offer/') ||
      url.includes('/wholesale-offer/') ||
      url.includes(routes.marketplace) ||
      url.includes('-manufacturers') ||
      url.includes('/pharmaceutical/manufacturers/');

    const Logout = (
      <>
        <Icon
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            className="drop-down"
            fillRule="evenodd"
            d="M18 9c0 4.962-4.038 9-9 9s-9-4.038-9-9 4.038-9 9-9 9 4.038 9 9zM2 9c0 3.86 3.14 7 7 7s7-3.14 7-7-3.14-7-7-7-7 3.14-7 7zm6-3h2v6H8V6z"
          />
        </Icon>
        <p>Log out</p>
      </>
    );
    const smallScreen = ![
      Breakpoint.Large,
      Breakpoint.ExtraLarge,
      Breakpoint.Medium,
    ].includes(this.props.breakpoint);
    const searchable = smallScreen || isMarketplace;
    // eslint-disable-next-line
    /*[
        routes.home,
        routes.marketplace,
        routes.product,
        routes.offer,
        routes.product_trade,
        routes.offer_trade
      ].findIndex(
        route =>
          !!matchPath(this.props.currentRoute, { path: route, exact: true })
      ) !== -1;*/

    return (
      <>
        <MenuSection />
        <div className="navbar-height" />
        <CookieModal />
        <SuggestAlternativeSellersModal />
      </>
    );
  }

  private handleOnScroll() {
    const search = document.getElementById('search-container');
    if (search) {
      if (this.reff && this.reff.current && !this.state.searchOpen) {
        const bottom = search.getBoundingClientRect().bottom;
        const navHeight = this.reff.current.clientHeight;
        if (bottom < navHeight) {
          if (!this.state.navbarSearchvisible) {
            this.setState({ navbarSearchvisible: true });
          }
        } else {
          if (this.state.navbarSearchvisible) {
            this.setState({ navbarSearchvisible: false });
          }
        }
      }
    } else {
      if (!this.state.navbarSearchvisible) {
        this.setState({ navbarSearchvisible: true });
      }
    }
  }
  private renderDropDownLinks(Logout: any) {
    return (
      <>
        <NavbarDropdownLink
          to={routes.products}
          activeClassName="active"
          onClick={() => {
            this.props.onRouteClick(routes.products);
            this.setState({ toggle: false });
          }}
        >
          <Icon
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="15"
            viewBox="0 0 19 15"
          >
            <path
              className="drop-down"
              d="M11.875 7.105h1.583V5.526h-1.583v1.58zm-3.167 0h1.584V5.526H8.708v1.58zm-3.166 0h1.583V5.526H5.542v1.58zm-3.959 6.316v-1.579h15.834v1.58H1.583zM3.167 1.58h12.666l.001 8.684H3.167V1.58zm14.25 8.684V1.58c0-.87-.71-1.579-1.584-1.579H3.167c-.874 0-1.584.708-1.584 1.579v8.684H0v3.158C0 14.291.71 15 1.583 15h15.834C18.29 15 19 14.292 19 13.421v-3.158h-1.583z"
            />
          </Icon>
          <p>Products</p>
        </NavbarDropdownLink>
        <SubLinkContainerWrapper>
          <SubLinkContainer
            to={routes.products}
            activeClassName="active"
            onClick={() => {
              this.props.onRouteClick(routes.products);
              this.setState({ toggle: false });
            }}
          >
            <SubLinkText>List</SubLinkText>
            <ConditionalContainer show={this.props.productListCount > 0}>
              <SubLinkCount>{this.props.productListCount}</SubLinkCount>
            </ConditionalContainer>
          </SubLinkContainer>
          {this.props.isAdmin && (
            <SubLinkContainer
              to={routes.admin_onboarding}
              onClick={() => {
                this.props.onRouteClick(routes.admin_onboarding);
                this.setState({ toggle: false });
              }}
            >
              <SubLinkText>Onboarding</SubLinkText>
              <ConditionalContainer show={this.props.onboardingCount > 0}>
                <SubLinkCount> {this.props.onboardingCount}</SubLinkCount>
              </ConditionalContainer>
            </SubLinkContainer>
          )}
        </SubLinkContainerWrapper>
        <NavbarDropdownLink
          key={'3'}
          to={this.props.isAdmin ? routes.deals : routes.deals_buying}
          activeClassName="active"
          onClick={() => {
            this.setState({ toggle: false });
            this.props.onRouteClick(
              this.props.isAdmin ? routes.deals : routes.deals_buying
            );
          }}
        >
          <Icon
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="18"
            viewBox="0 0 17 18"
          >
            <path
              className="drop-down"
              fillRule="evenodd"
              d="M1.693 15.73h13.541V8.739H1.693v6.99zM0 17.477h16.927V6.991H0v10.486zm4.232-5.243h8.463v-1.748H4.232v1.748zm-2.54-6.99h13.542V3.494H1.693v1.748zm1.693-3.496h10.157V0H3.385v1.748z"
            />
          </Icon>
          <p>Deals</p>
        </NavbarDropdownLink>
        <ConditionalContainer show={!this.props.isAdmin}>
          <SubLinkContainerWrapper>
            <SubLinkContainer
              to={routes.deals_buying}
              activeClassName="active"
              onClick={() => {
                this.props.onRouteClick(routes.deals_buying);
                this.setState({ toggle: false });
              }}
            >
              <SubLinkText>I am buying</SubLinkText>
              <ConditionalContainer show={this.props.dealsBuyingCount > 0}>
                <SubLinkCount>{this.props.dealsBuyingCount}</SubLinkCount>
              </ConditionalContainer>
            </SubLinkContainer>
            <SubLinkContainer
              to={routes.deals_selling}
              key={'22'}
              activeClassName="active"
              onClick={() => {
                this.props.onRouteClick(routes.deals_selling);
                this.setState({ toggle: false });
              }}
            >
              <SubLinkText>I am selling</SubLinkText>
              <ConditionalContainer show={this.props.dealsSellingCount > 0}>
                <SubLinkCount>{this.props.dealsSellingCount}</SubLinkCount>
              </ConditionalContainer>
            </SubLinkContainer>
          </SubLinkContainerWrapper>
        </ConditionalContainer>
        {this.props.isAdmin && (
          <NavbarDropdownLink
            key={'4'}
            to={routes.dealsarchive}
            activeClassName="active"
            onClick={() => {
              this.props.onRouteClick(routes.dealsarchive);
              this.setState({ toggle: false });
            }}
          >
            <Icon
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="13"
              viewBox="0 0 18 13"
            >
              <path
                className="drop-down"
                fillRule="evenodd"
                d="M16.498 5.063H15v-1.52a1.51 1.51 0 0 0-1.5-1.518h-5L6.5 0h-5C.672 0 0 .68 0 1.519v9.112a1.51 1.51 0 0 0 1.5 1.519H14c.517 0 .998-.27 1.272-.714l2.498-4.05c.624-1.01-.095-2.323-1.272-2.323zM1.688 1.519h4.19l2 2.025h5.435c.103 0 .187.085.187.19v1.329H4.75c-.526 0-1.013.278-1.284.734L1.5 9.095V1.708c0-.05.02-.098.055-.134a.186.186 0 0 1 .133-.055zM14 10.63H2.25l2.414-4.05H16.5l-2.5 4.05z"
              />
            </Icon>
            <p>Archive</p>
          </NavbarDropdownLink>
        )}
        <NavbarDropdownLink
          to={routes.usersettings}
          key={'5'}
          activeClassName="active"
          onClick={() => {
            this.props.onRouteClick(routes.usersettings);
            this.setState({ toggle: false });
          }}
        >
          <Icon
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="19"
            viewBox="0 0 19 19"
          >
            <path
              className="drop-down"
              fillRule="evenodd"
              d="M17.813 10.688h-.695a7.664 7.664 0 0 1-1.391 3.36l.55.55c.231.23.347.535.347.839a1.183 1.183 0 0 1-2.027.84l-.55-.55a7.66 7.66 0 0 1-3.36 1.39v.695a1.187 1.187 0 1 1-2.374 0v-.694a7.666 7.666 0 0 1-3.36-1.39l-.55.55a1.182 1.182 0 0 1-.84.347c-.304 0-.608-.115-.84-.348a1.182 1.182 0 0 1-.348-.837c0-.305.115-.61.348-.842l.55-.55a7.664 7.664 0 0 1-1.39-3.36h-.696a1.188 1.188 0 0 1 0-2.376h.695a7.663 7.663 0 0 1 1.39-3.36l-.55-.55a1.183 1.183 0 0 1 0-1.679 1.182 1.182 0 0 1 1.68 0l.55.55a7.673 7.673 0 0 1 3.36-1.39v-.695a1.187 1.187 0 1 1 2.375 0v.694a7.671 7.671 0 0 1 3.36 1.39l.55-.549a1.183 1.183 0 0 1 1.68 0c.232.232.348.536.348.84 0 .303-.116.607-.348.84l-.55.549a7.664 7.664 0 0 1 1.39 3.36h.696a1.188 1.188 0 0 1 0 2.376zM9.5 3.562A5.944 5.944 0 0 0 3.563 9.5 5.944 5.944 0 0 0 9.5 15.438 5.944 5.944 0 0 0 15.438 9.5 5.944 5.944 0 0 0 9.5 3.562zm0 9.5a3.562 3.562 0 1 1 0-7.124 3.562 3.562 0 0 1 0 7.124zm0-5.343c-.982 0-1.781.799-1.781 1.781 0 .982.799 1.781 1.781 1.781.982 0 1.781-.799 1.781-1.781 0-.982-.799-1.781-1.781-1.781z"
            />
          </Icon>
          <p>Settings</p>
        </NavbarDropdownLink>
        {Logout}
      </>
    );
  }
  private renderLogedIn(Logout: any, hidden = false) {
    return (
      <>
        <LogedInContainer className="drop-down">
          <div
            className="user"
            onClick={() => {
              if (hidden) {
                this.setState({ toggleDropDown: !this.state.toggleDropDown });
              }
            }}
          >
            <Icon
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                className="avatar"
                d="M9 0C4.05 0 0 4.05 0 9s4.05 9 9 9 9-4.05 9-9-4.05-9-9-9zm0 2.7c1.53 0 2.7 1.17 2.7 2.7 0 1.53-1.17 2.7-2.7 2.7-1.53 0-2.7-1.17-2.7-2.7 0-1.53 1.17-2.7 2.7-2.7zm0 12.78c-2.25 0-4.23-1.17-5.4-2.88 0-1.8 3.6-2.79 5.4-2.79 1.8 0 5.4.99 5.4 2.79-1.17 1.71-3.15 2.88-5.4 2.88z"
              />
            </Icon>
            <LogedUser>
              {this.props.nameSurname ||
                `${this.props.user.email.substring(
                  0,
                  maxEmailLengthToShow
                )}...`}
            </LogedUser>
            <RotatingArrow open={hidden && this.state.toggleDropDown}>
              <img className="down-icon" src={'/img/selectDownIcon.svg'} />
            </RotatingArrow>
          </div>
          {hidden ? (
            <HideableContainer open={this.state.toggleDropDown}>
              {this.renderDropDownLinks(Logout)}
            </HideableContainer>
          ) : (
            <LogedDropDown>{this.renderDropDownLinks(Logout)}</LogedDropDown>
          )}
        </LogedInContainer>
      </>
    );
  }

  private toggleSearch(toggle: boolean) {
    if (!this.state.searchOpen && toggle && this.state.toggle) {
      this.setState({ toggle: false, searchOpen: toggle }, this.handleOnScroll);
    } else if (
      (this.state.searchOpen && !toggle) ||
      (!this.state.searchOpen && toggle)
    ) {
      this.setState({ searchOpen: toggle }, this.handleOnScroll);
    }
  }
}

export default Navbar;
