import React from 'react';

type Props = {
  className?: string;
  [key: string]: any;
};

const ChevronDown: React.FC<Props> = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="#186CE8" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 15.5a1 1 0 01-.71-.29l-4-4a1.004 1.004 0 111.42-1.42L12 13.1l3.3-3.18a1 1 0 111.38 1.44l-4 3.86a1 1 0 01-.68.28z"
      fill="inherit"
    />
  </svg>
);

export default ChevronDown;
