import React from 'react';
import { useLocation } from 'react-router-dom';

import MenuSectionPure from './MenuSection';
import menuSectionData from './data';

const MenuSection = () => {
  const location = useLocation();
  const pathname = location?.pathname ?? '';
  return <MenuSectionPure pathname={pathname} {...menuSectionData} />;
};

export default MenuSection;
