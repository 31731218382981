import styled from 'styled-components';

// custom imports
import { media } from 'app/styles/breakpoint';

export const SearchInputContainer = styled.div`
  width: 100%;
  height: 80px;
  border-radius: 4px;
  box-shadow: 0 4px 28px 0 rgba(53, 64, 82, 0.1);
  background-color: ${(props) => props.theme.colors.white};
  padding: 8px;
  font-size: 15px;
  display: flex;
  align-items: center;

  ${media.max.md`
  height: 66px;
  padding: 7px;
`}

  ${media.max.sm`
  padding-right: 4px;
  height: 50px;
`}
`;

export const InputWrapper = styled.div`
  flex: 3;
  display: flex;
`;

export const SearchIconContainer = styled.div`
  padding: 24px;
  align-items: center;
  display: flex;

  ${media.max.md`
  padding: 16px;
`}

  ${media.max.sm`
  padding: 8px;
  display: none;
`}
`;

export const SearchIcon = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;

  ${media.max.md`
  width: 20px;
  height: 20px;
`}

  ${media.max.sm`
  width: 16px;
  height: 16px;
`}
`;

export const TextContainer = styled.div`
  padding-top: 24px;
  padding-bottom: 24px;
  width: 100%;
  font-size: 22px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.64;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.grayExtraDark};
  ${media.max.sm`
padding-left: 10px;
`}
`;

export const SearchButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 64px;
  border-radius: 3.5px;
  background-color: ${(props) => props.theme.colors.warning};

  font-size: 22px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.02;
  letter-spacing: normal;
  text-align: center;
  font-family: 'Nunito Sans';
  color: ${(props) => props.theme.colors.white};
  cursor: pointer;
  :hover {
    background-color: ${({ theme }) => theme.colors.orange};
  }
  img {
    display: none;
  }
  ${media.max.md`
font-size: 18px;
height: 50px;


`}
  ${media.max.sm`
    span{
      display: none;
    }

    max-width: 42px;
    height: 42px;

    img{
      display: block;
    }
`}
`;
