import styled from 'styled-components';

// custom imports
import { media } from 'app/styles/breakpoint';

export const LabelStyle = styled.label`
  transition: all 200ms ease;
  font-size: 14px;
  pointer-events: all;
  color: ${(props) => props.theme.colors.grayText};
  z-index: 1;
`;

export const SelectInputContainer = styled.div`
  display: block;
  position: relative;
`;

export const ConditionalContainer = styled.div<{ show: boolean }>`
  ${(props) => !props.show && 'display: none;'}
`;

export const Input = styled.input`
  padding-top: 18px;
  padding-bottom: 18px;
  padding-right: 15px;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.grayExtraDark};
  font-family: 'Nunito Sans';
  background-color: transparent;
  border: 0px;
  width: 100%;

  ::placeholder {
    font-family: 'Nunito Sans';
    color: ${(props) => props.theme.colors.grayDark};
    opacity: 1;
    font-size: 18px;
  }

  :focus {
    outline: none;
    color: ${(props) => props.theme.colors.grayExtraDark};
  }

  ${media.max.md`
    font-size: 18px;
  `}

  ${media.max.sm`
    font-size: 14px;
  `}
`;

export const ErrorLine = styled.div`
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
`;

export const ErrorMessage = styled.div`
  font-size: 11px;
  color: ${({ theme }) => theme.colors.errorMessage};
`;

export const ErrorIcon = styled.img`
  width: 10px;
  height: 9px;
  object-fit: contain;
`;

export const OptionsContainer = styled.div<{ visibleBorder?: boolean }>`
  position: absolute;
  background: ${(props) => props.theme.colors.white};
  ${(props) =>
    props.visibleBorder && `border: 1px solid ${props.theme.colors.grayLight};`}
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 2px 4px 5px ${(props) => props.theme.colors.shadow};
  min-width: 250px;
  max-width: 500px;
  max-height: 505px;
  overflow: auto;
  z-index: 10;
  top: calc(100% + 20px);
  left: -80px;

  ${media.max.md`
    left: -60px;
    top: calc(100% + 10px);
  `}

  ${media.max.sm`
    left: -40px;
    top: calc(100% + 5px);
    width: calc(100% + 46px);
  `}

  z-index: 10;
  .select-style & {
    box-shadow: 0 4px 28px 0 rgba(53, 64, 82, 0.1);
    border-radius: 0;
    margin: 8px 0;
    padding: 4px 0;
    width: 100%;
    left: 0;
    max-width: unset;
    min-width: unset;
    top: 100%;
    border: none;
  }
`;

export const Option = styled.div`
  display: flex;
  height: 50px;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  outline: none;

  &:hover {
    background: ${(props) => props.theme.colors.primaryLightHover};
  }

  &:not(:first-child) {
    border-top: 1px solid ${(props) => props.theme.colors.grayLight};
  }

  p {
    display: flex;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
    font-size: 18px;
    color: ${(props) => props.theme.colors.grayExtraDark};
    white-space: nowrap;
  }

  .select-style & {
    height: unset;
    padding: 8px 12px;
    border: none;
  }

  .select-style & p {
    padding: 0;
    margin: 0;
    font-size: 15px;
  }
`;
