import CancelButton from 'app/components/buttons/cancel-button';
import ViewDealButton from 'app/components/buttons/orange-button';
import Modal from 'app/components/modals/overrided-modal';
import WarningNotification from 'app/components/notifications/warning-large';
import { media } from 'app/styles/breakpoint';
import colors from 'app/styles/colors';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { IProps } from './';

const ModalContainer = styled.div`
  margin: 30px;
  margin-top: 15px;
  z-index: 100;
  padding-top: 0px;
  align-items: flex-start;
  ${media.max.lg`
    margin: 30px;
    margin-top: 15px
  `}
  ${media.max.md`
    margin: 15px;
  `}
  ${media.max.sm`
    margin: 15px;
  `}
`;

const IconContainer = styled.div`
  width: 100%;
  text-align: right;
`;

const CloseIcon = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
`;

const ButtonLine = styled.div`
  margin-top: 38px;
  display: flex;
  ${media.max.sm`
    flex-direction: column;

  `}
`;

const DealExistsModal: React.FC<IProps> = ({ closeModal, isOpen, link }) => {
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: colors.white,
      padding: 20,
      borderRadius: 3,
      border: 0,
      boxShadow: colors.modalShadow,
      width: '880px',
      maxWidth: '100%',
    },
    overlay: {
      backgroundColor: colors.modalOverlay,
      zIndex: 1000,
    },
  };

  const Divider = styled.div`
    width: 10px;
    height: 10px;
  `;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
      ariaHideApp={false}
    >
      <IconContainer>
        <div onClick={closeModal}>
          <CloseIcon src={'/img/close.svg'} />
        </div>
      </IconContainer>
      <ModalContainer>
        <WarningNotification
          hideHeading={true}
          label={'You already have a deal for this item.'}
          closable={false}
        />
        <ButtonLine>
          <CancelButton onClick={closeModal} label={'Close'} />
          <Divider />
          <Link to={link}>
            <ViewDealButton onClick={closeModal} label={'View deal'} />
          </Link>
        </ButtonLine>
      </ModalContainer>
    </Modal>
  );
};

export default DealExistsModal;
