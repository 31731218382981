import React, { useState } from 'react';
import styled from 'styled-components';

import { ChevronDown } from '../atoms/icons';
import { _, media } from 'app/styles/breakpoint';

export interface MenuSublinksProps {
  /**
   * Link text -- Can be text elements
   */
  children: React.ReactNode;
  /**
   * What is the label?
   */
  label?: React.ReactChild;
  /**
   * Is the menu open initially?
   */
  isOpenInitially?: boolean;
  /**
   * Is the menu anchored to the right?
   */
  isRight?: boolean;
  /**
   * For overrides
   */
  className?: string;
}

const Container = styled.div`
  position: relative;
`;
const Label = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-column-gap: 8px;
  width: min-content;
  cursor: pointer;

  & > svg:last-child {
    fill: ${(props) => props.theme.colors.black};
  }
`;
const Sublinks = styled.div<{ $isOpen: boolean; $isRight: boolean }>`
  display: grid;
  position: absolute;
  border-radius: 8px;
  padding-left: 16px;
  padding-right: 16px;
  min-width: 176px;
  max-height: 0;
  font-size: 16px;
  justify-items: start;
  background-color: ${(props) => props.theme.colors.white};
  z-index: 9000;
  grid-row-gap: 20px;
  box-shadow: rgb(48 49 51 / 5%) 0px 0px 1px, rgb(48 49 51 / 10%) 0px 16px 24px;
  transition: all 300ms;
  overflow: hidden;
  ${_(media.max.sm)} {
    position: relative;
    box-shadow: none;
    z-index: 0;
    min-width: 0px;
  }
  &.isOpen {
    ${_(media.max.sm)} {
      margin-top: 28px;
    }
    max-height: 200px;
    padding-top: 16px;
    padding-bottom: 16px;
  }
  /* & > a:not(:last-child) {
    padding-bottom: 16px !important;
  } */
  ${({ $isRight }) => ($isRight ? 'right: 0;' : '')};
`;

const MenuSublinks: React.FC<MenuSublinksProps> = ({
  children,
  label,
  isOpenInitially = false,
  isRight = false,
  className,
}) => {
  const [isOpen, setIsOpen] = useState(isOpenInitially);

  return (
    <Container className={className}>
      <Label onClick={() => setIsOpen(!isOpen)}>
        {label}
        <ChevronDown
          style={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
        />
      </Label>
      <Sublinks
        className={isOpen ? 'isOpen' : ''}
        $isOpen={isOpen}
        $isRight={isRight}
      >
        {children}
      </Sublinks>
    </Container>
  );
};

export { MenuSublinks };
