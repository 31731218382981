import { routes } from 'app/config';
import { media } from 'app/styles/breakpoint';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
// eslint-disable-next-line @typescript-eslint/no-var-requires
import { StateProps } from './index';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const IntercomAPI = require('react-intercom').IntercomAPI;
import { Link } from 'react-router-dom';
import styled from 'styled-components';
const CookieWrapper = styled.div`
  width: 100%;
  background-color: #164679;
  position: fixed;
  z-index: 100;
  min-height: 54px;
  opacity: 0.9;
  bottom: 0;
  z-index: 999;
`;
const CookiesContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 54px;
  ${media.min.lg`
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0px;
  padding-right: 0px;
`}
  ${media.max.md`
    padding-left: 42px;
    padding-right: 42px;
  `}
  ${media.max.sm`
    padding-left: 34px;
    padding-right: 34px;
  `}
   ${media.max.xs`
    padding-left: 20px;
    padding-right: 20px;
  `}
`;

const CookieLink = styled(Link)`
  text-decoration: underline;
  color: white;
`;

const CookieText = styled.p`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.white};
`;

const CookieButton = styled.div`
  width: 90px;
  min-width: 90px;
  height: 34px;
  border-radius: 2px;
  font-size: 13px;
  background-color: ${(props) => props.theme.colors.primary};
  :hover {
    background-color: ${(props) => props.theme.colors.primaryHover};
  }
  cursor: pointer;
  color: ${(props) => props.theme.colors.white};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 18px;
  ${media.max.sm`
  margin-left: 12px;
  width: 70px;
  min-width: 70px;
  height: 34px;
  `}
`;

const Cookies: React.FC<StateProps> = ({ screenWidth }) => {
  const [cookies, setCookie] = useCookies(['cookiePolicyAccept']);
  const [hideWindow, setHideWindow] = useState(true);
  useEffect(() => {
    setHideWindow(false);
  }, []);

  const hide =
    cookies === undefined ||
    (cookies.cookiePolicyAccept &&
      cookies.cookiePolicyAccept.toString() === 'true')
      ? true
      : false;

  if (hideWindow || hide) {
    return <div />;
  } else {
    const hideWidth = 900;
    if (screenWidth < hideWidth) {
      IntercomAPI('update', {
        hide_default_launcher: true,
      });
    }
  }

  return (
    <CookieWrapper>
      <CookiesContainer>
        <CookieText>
          By using our website you are consenting to our use of cookies in
          accordance with our{' '}
          <CookieLink to={routes.cookies}>Cookie policy</CookieLink> .
        </CookieText>
        <CookieButton
          data-testid="cookie-button"
          onClick={() => {
            setCookie('cookiePolicyAccept', true);
            setHideWindow(true);
            IntercomAPI('update', {
              hide_default_launcher: false,
            });
          }}
        >
          Got it
        </CookieButton>
      </CookiesContainer>
    </CookieWrapper>
  );
};

export default Cookies;
