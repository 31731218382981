import { routes } from 'app/config';
import { logout, requestFetchNavCount } from 'app/store/modules/auth/actions';
import { setCurrentPage } from 'app/store/modules/requests/actions';
import { IWithApolloClient } from 'app/store/modules/global/types';
import { filterMarketplace } from 'app/store/modules/marketplace/actions';
import { setProductFilter } from 'app/store/modules/products/actions';
import { IStore } from 'app/store/types';
import { sg } from 'app/utils/safe-get';
import { withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Breakpoint } from '../../../styles/breakpoint';
import Navbar from './navbar';

interface IStateProps {
  user: any;
  loggedIn: boolean;
  isAdmin: boolean;
  nameSurname: string;
  productListCount: number;
  dealsBuyingCount: number;
  dealsSellingCount: number;
  onboardingCount: number;
  breakpoint: Breakpoint;
  checkAuthComplete: boolean;
  currentRoute: string;
  navbarCountFetchComplete: boolean;
  screenWidth: number;
  suggestAlternativeSellersModalOpen: boolean;
}
interface IDispatchProps {
  onRouteClick: (route: string) => any;
  onLogout: () => any;
  clearFilter: (route: string) => any;
  fetchNavCount: () => any;
}
interface IOwnProps extends IWithApolloClient, RouteComponentProps {}

export interface IProps extends IStateProps, IDispatchProps, IOwnProps {}
export default withApollo<any>(
  withRouter(
    connect<IStateProps, IDispatchProps, IOwnProps, IStore>(
      ({ auth, router, device, products }) => ({
        isAdmin: sg(() => auth.loggedInUser.isAdmin, false),
        loggedIn: auth.loggedIn,
        user: sg(() => auth.loggedInUser, null),
        nameSurname: sg(() => auth.loggedInUser.firstName, ''),
        productListCount: auth.navbarCount.productCount,
        dealsBuyingCount: auth.navbarCount.dealsBuyingCount,
        dealsSellingCount: auth.navbarCount.dealsSellingCount,
        breakpoint: device.breakpoint,
        checkAuthComplete: auth.authCheckComplete,
        currentRoute: router.location.pathname,
        navbarCountFetchComplete: auth.navbarCountFetchComplete,
        onboardingCount: auth.navbarCount.onboardingCount,
        screenWidth: device.width,
        suggestAlternativeSellersModalOpen:
          products.suggestAlternativeSellersModalOpen,
      }),
      (dispatch, ownProps) => {
        const clearFilterFun = (route: string) => {
          if (route === routes.products) {
            dispatch(
              setProductFilter({
                company: '',
                form: '',
                product: '',
                visible: true,
                lastUpdateFrom: undefined,
                lastUpdateTo: undefined,
              })
            );
          }
          if (
            route === routes.deals ||
            routes.deals_buying ||
            routes.deals_selling
          ) {
            dispatch(setCurrentPage(0));
          }

          if (route === routes.marketplace) {
            dispatch(
              filterMarketplace({
                filter: {
                  isNavbar: true,
                  product: '',
                  productId: undefined,
                },
                client: ownProps.client,
              })
            );
          }
        };
        return {
          onRouteClick: (route: string) => {
            clearFilterFun(route);
            window.scrollTo(0, 0);
          },
          onLogout: () => dispatch(logout()),
          clearFilter: (route: string) => {
            clearFilterFun(route);
          },
          fetchNavCount: () =>
            dispatch(requestFetchNavCount({ client: ownProps.client })),
        };
      }
    )(Navbar)
  )
);
