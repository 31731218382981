import React from 'react';

const User: React.FC = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M6 21v-2a4 4 0 014-4h4a4 4 0 014 4v2M16 7a4 4 0 11-8 0 4 4 0 018 0z"
      stroke="#000"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default User;
