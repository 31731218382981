import { IWithApolloClient } from 'app/store/modules/global/types';
import { requestFetchTopProducts } from 'app/store/modules/marketplace/actions';
import { AnyListItem } from 'app/store/modules/products/types';
import { IStore } from 'app/store/types';
import { withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import ProductContainer from './top-products';

interface IStateProps {
  productList: AnyListItem[];
  isAdmin: boolean;
}

interface IOwnProps {
  substanceName: string;
  substance2Name: string;
  substance3Name: string;
  brandName: string;
  type: number;
  isMarketplace?: boolean;
  productId: number;
}

interface IDispatchProps {
  requestFetchTopProduct: () => any;
}

export interface IProps
  extends IStateProps,
    IOwnProps,
    IDispatchProps,
    IWithApolloClient {}
export default withApollo<IOwnProps>(
  connect<IStateProps, IDispatchProps, IOwnProps & IWithApolloClient, IStore>(
    (state, ownProps) => {
      return {
        productList: state.marketplace.marketplaceTopProducts,
        isAdmin: state.auth.loggedInUser
          ? state.auth.loggedInUser.isAdmin
          : false,
      };
    },
    (dispatch, ownProps) => {
      return {
        requestFetchTopProduct: () => {
          if (ownProps.substanceName !== '') {
            dispatch(
              requestFetchTopProducts({
                substanceName: ownProps.substanceName,
                substance2Name: ownProps.substance2Name,
                substance3Name: ownProps.substance3Name,
                brandName: ownProps.brandName,
                client: ownProps.client,
                type: ownProps.type,
                productId: ownProps.productId,
              })
            );
          }
        },
      };
    }
  )(ProductContainer)
);
