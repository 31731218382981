import React from 'react';
import styled from 'styled-components';

const Container = styled.div<any>`
  //
  width: ${(props) =>
    props.isMarketplaceList ? '282px' : 'calc(100% - 15px)'};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //
  margin: ${(props) => (props.isMarketplaceList ? '15px 0' : '0 0 15px 15px')};
  background-color: ${(props) => props.theme.colors.white};
  padding: 30px;
  box-shadow: ${(props) =>
    props.theme.colors.boxShadowParam + ' ' + props.theme.colors.filtersShadow};
  margin-bottom: 80px;
`;

const Icon = styled.img`
  width: 140px;
  height: 140px;
  object-fit: contain;
  margin-bottom: 32px;
`;

const NeedHelpText = styled.h2`
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: ${(props) => props.theme.colors.grayExtraDark};
  line-height: 1.35;
  letter-spacing: normal;
  text-align: center;
  margin-bottom: 19px;
  margin-top: 0;
`;

const HelpText = styled.div`
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.73;
  letter-spacing: normal;
  text-align: center;
  width: 100%;
  word-wrap: wrap;
`;

const Links = styled.a`
  color: ${(props) => props.theme.colors.primary};
  overflow-wrap: break-word;
`;

interface IProps {
  isMarketplaceList?: boolean;
  fullProductName?: string;
}

const NeedHelp: React.FC<IProps> = ({ isMarketplaceList, fullProductName }) => {
  return (
    //
    <Container isMarketplaceList={isMarketplaceList}>
      <Icon src={'/img/need-help.svg'} />
      <NeedHelpText>
        {fullProductName ? `Need help with ${fullProductName}?` : 'Need help?'}{' '}
      </NeedHelpText>
      <HelpText>
        Our customer support is here to help. E-mail us at{' '}
        <Links href="mailto:support@pipelinepharma.com">
          support@pipelinepharma.com
        </Links>
        , call us on <Links href="tel:+37052075842">+370-5-207-5842</Links>.
      </HelpText>
    </Container>
  );
};

export default NeedHelp;
