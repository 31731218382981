import { _, media } from 'app/styles/breakpoint';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

export const OrangeButtonContainer = styled.div`
  width: 100%;
  max-width: 160px;
  .tooltip-style {
    width: 305px;
    border: solid 1px ${(props) => props.theme.colors.grayLight} !important;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.18px;
    color: ${(props) => props.theme.colors.grayExtraDark};
  }
  .place-top::before {
    border-top: 8px solid ${(props) => props.theme.colors.grayLight} !important;
  }
`;

export const OrangeButtonStyle = styled.div<{ isSecondary?: boolean }>`
  min-width: 100px;
  max-width: 160px;
  ${_(media.max.sm)} {
    width: 100%;
    max-width: 160px;
  }
  ${_(media.min.tablet)} {
    width: 160px;
  }
  height: 50px;
  ${_(media.max.xs)} {
    height: 42px;
  }
  border-radius: 2px;
  ${(props) =>
    props.isSecondary && `border: solid 2px ${props.theme.colors.accent};`};
  background-color: ${(props) =>
    props.isSecondary ? props.theme.colors.white : props.theme.colors.accent};
  font-size: 18px;
  ${_(media.max.sm)} {
    font-size: 16px;
  }
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: normal;
  text-align: center;
  color: ${(props) =>
    props.isSecondary ? props.theme.colors.accent : props.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  :hover {
    background-color: ${(props) =>
      props.isSecondary
        ? props.theme.colors.orangeHover
        : props.theme.colors.orange};
  }
`;
interface Props {
  label: string;
  onClick?: () => any;
  id?: string;
  tooltipText?: string;
  isSecondary?: boolean;
}
const OrangeButton: React.FC<Props> = ({
  label,
  onClick,
  id,
  tooltipText,
  isSecondary,
}) => {
  const tooltipId = `${label}-${id}`;

  return (
    <OrangeButtonContainer onClick={onClick}>
      {tooltipText && (
        <ReactTooltip
          id={tooltipId}
          effect="solid"
          multiline={true}
          className="tooltip-style"
          type="light"
          offset={{ right: 50 }}
          border={true}
        >
          {tooltipText}
        </ReactTooltip>
      )}
      <OrangeButtonStyle
        id={id}
        data-tip={true}
        data-for={tooltipId}
        isSecondary={isSecondary}
      >
        {label} {isSecondary}
      </OrangeButtonStyle>
    </OrangeButtonContainer>
  );
};

export default OrangeButton;
