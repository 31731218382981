import { push } from 'connected-react-router';
import { withApollo } from 'react-apollo';
import { connect } from 'react-redux';

// custom imports
import { routes } from 'app/config';
import {
  openPopupIfNeeded,
  setCreateAccountModalOpen,
  setGetStartedModalOpen,
} from 'app/store/modules/auth/actions';
import {
  codePlacesRelatedToVerifyEmailModal,
  MarketplaceExistingDeal,
  User,
} from 'app/store/modules/auth/types';
import { createMultipleProductRequests } from 'app/store/modules/requests/actions';
import { ICreateDealForm } from 'app/store/modules/requests/types';
import { IWithApolloClient } from 'app/store/modules/global/types';
import {
  hasFetched,
  requestFetchProducts,
} from 'app/store/modules/products/actions';
import { setSuggestAlternativeSellersModalOpen } from 'app/store/modules/products/actions';
import { AnyListItem, Product } from 'app/store/modules/products/types';
import { IStore } from 'app/store/types';
import { Dictionary } from 'app/utils/dictionary';
import { sg, sgg } from 'app/utils/safe-get';
import SuggestAlternativeSellers from './suggest-alternative-sellers';

interface IDispatchProps {
  fetchProducts: (
    substance: string,
    marketOfInterests: string | undefined
  ) => any;
  skip: (loggedInUser: User) => any;
  send: (
    selectedProducts: AnyListItem[],
    createDealForm: ICreateDealForm,
    loggedInUser: User
  ) => any;
}
interface IStateProps {
  searchTerm: string;
  createDealForm: ICreateDealForm;
  suggestedProducts: AnyListItem[];
  hasFetched: boolean;
  loggedInUser: User;
  breakpoint: string;
  userDealProducts: MarketplaceExistingDeal[];
  productsCache: Dictionary<Product>;
}
export type IProps = IStateProps & IDispatchProps & IWithApolloClient;

export default withApollo<any>(
  connect<IStateProps, IDispatchProps, IWithApolloClient, IStore>(
    (state) => ({
      searchTerm: state.marketplace.filter.product,
      createDealForm: state.requests.createDealForm,
      suggestedProducts: state.products.visibleProductIds,
      hasFetched: state.products.hasFetched,
      loggedInUser: state.auth.loggedInUser,
      breakpoint: state.device.breakpoint,
      userDealProducts: state.auth.navbarCount.marketplaceExistingDealIds,
      productsCache: state.products.productsCache,
      productsTradeCache: state.products.productsTradeCache,
    }),
    (dispatch, ownProps) => ({
      fetchProducts: (
        substance: string,
        marketOfInterests: string | undefined
      ) => {
        return dispatch(
          requestFetchProducts({
            client: ownProps.client,
            filter: {
              product: substance,
              company: '',
              form: '',
              visible: true,
              marketOfInterests,
            },
            isSuggestedProducts: true,
            pageSize: 100,
          })
        );
      },
      skip: (loggedInUser: User) => {
        if (!(loggedInUser && loggedInUser.id)) {
          dispatch(setGetStartedModalOpen(true));
        } else if (
          !loggedInUser.firstName ||
          !loggedInUser.lastName ||
          !(loggedInUser.Company && loggedInUser.Company.id)
        ) {
          dispatch(setCreateAccountModalOpen(true));
        }
        dispatch(setSuggestAlternativeSellersModalOpen(false));
        dispatch(hasFetched(false));
        dispatch(
          openPopupIfNeeded({
            calledFrom:
              codePlacesRelatedToVerifyEmailModal.suggestAlternativeSellers,
          })
        );
      },
      send: (
        selectedProducts: AnyListItem[],
        createDealForm: ICreateDealForm,
        loggedInUser: User
      ) => {
        const dealType: number = sgg(
          () => createDealForm.product!.dealType,
          undefined
        );
        const estimatedAnualQuantity: string = sgg(
          () => createDealForm.product!.estimatedAnualQuantity,
          undefined
        );
        const leadingPriceLevel: string = sgg(
          () => createDealForm.product!.leadingPriceLevel,
          undefined
        );

        const expectSignContract: string = sgg(
          () => createDealForm.product!.expectSignContract,
          undefined
        );

        const market: string = sgg(
          () => createDealForm.product!.market,
          undefined
        );
        const comments: string = sgg(
          () => createDealForm.product!.comments,
          undefined
        );
        const dealsGroup: string = sgg(
          () => createDealForm.product!.dealsGroup,
          undefined
        );
        const currentUserCompanyId = sg(() => loggedInUser.Company!.id, 0);
        const product = sg(() => createDealForm.product!.product, undefined);

        if (selectedProducts.length > 0) {
          dispatch(
            createMultipleProductRequests({
              client: ownProps.client,
              values: {
                selectedProducts,
                dealType,
                market,
                estimatedAnualQuantity,
                leadingPriceLevel,
                expectSignContract,
                comments,
                dealsGroup,
                currentUserCompanyId,
                product,
              },
            })
          );
        }
        if (!(loggedInUser && loggedInUser.id)) {
          dispatch(setGetStartedModalOpen(true));
        } else if (
          !loggedInUser.firstName ||
          !loggedInUser.lastName ||
          !(loggedInUser.Company && loggedInUser.Company.id)
        ) {
          dispatch(setCreateAccountModalOpen(true));
        } else {
          dispatch(push(routes.deals_buying));
        }
        dispatch(setSuggestAlternativeSellersModalOpen(false));
        dispatch(hasFetched(false));
        dispatch(
          openPopupIfNeeded({
            calledFrom:
              codePlacesRelatedToVerifyEmailModal.buyerQualificationModal,
          })
        );
      },
    })
  )(SuggestAlternativeSellers)
);
