import React from 'react';

const Close: React.FC = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="#186CE8" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.412 12l4.3-4.29a1.004 1.004 0 00-1.42-1.42l-4.29 4.3-4.29-4.3a1.004 1.004 0 10-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 00-.006 1.414l.006.006a1 1 0 001.414.006l.006-.006 4.29-4.3 4.29 4.3a1 1 0 001.414.006l.006-.006a1 1 0 00.006-1.414l-.006-.006-4.3-4.29z"
      fill="inherit"
    />
  </svg>
);

export default Close;
