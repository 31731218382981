import React, { useEffect, useState } from 'react';

// custom imports
import { GAActions, GACategory, ReactGA } from 'client/tracker';
import OrangeButtonLarge from 'app/components/buttons/orange-button-large';
import Loader from 'app/components/loader';
import ProductItem from 'app/modules/marketplace/components/marketplace-list/components/product-item';
import SuccessLarge from 'app/components/notifications/success-large';
import { MarketplaceExistingDeal, User } from 'app/store/modules/auth/types';
import { toTitleCase } from 'app/store/modules/global/actions';
import { AnyListItem } from 'app/store/modules/products/types';
import { Breakpoint } from 'app/styles/breakpoint';
import { sg, sgg } from 'app/utils/safe-get';
import { IProps } from './index';
import {
  ButtonsContainer,
  Header,
  Heading,
  SelectedText,
  SendButtonWrapper,
  SkipButtonWrapper,
  Subheading,
  SuggestAlternativeSellersContainer,
} from './styles';
import { useSelector } from 'app/helpers/redux';
import { anonymousUserId } from 'app/config';
import isBrowser from 'app/store/utils/is-browser';

const changeProductSelection = (
  productItems: AnyListItem[],
  selectedProducts: AnyListItem[],
  setSelectedProducts: (selectedProducts: AnyListItem[]) => any,
  id: number,
  isSelected: boolean
) => {
  const selectedProduct: AnyListItem | undefined = productItems.find(
    (productItem) => productItem.productId === id
  );

  if (selectedProduct) {
    if (isSelected) {
      setSelectedProducts([...selectedProducts, selectedProduct]);
    } else {
      setSelectedProducts([
        ...selectedProducts.filter((product) => product.productId !== id),
      ]);
    }
  }
};

const requestsAlreadyCreatedForProduct = (
  userDealProducts: MarketplaceExistingDeal[],
  productId?: number
) => {
  return !!sgg(
    () =>
      userDealProducts.find(
        (existingDeal) =>
          !!existingDeal.Product &&
          !!existingDeal.Product.mainProductId &&
          existingDeal.Product.mainProductId === productId
      ),

    false
  );
};

const renderProductItems = (
  loggedInUser: User,
  skip: (loggedInUser: User) => any,
  productItems: AnyListItem[],
  selectedProducts: AnyListItem[],
  setSelectedProducts: (selectedProducts: AnyListItem[]) => any,
  userDealProducts: MarketplaceExistingDeal[],
  mainDealProductId?: number
) => {
  const itemsForRendering =
    productItems &&
    productItems.map((item: AnyListItem) => {
      if (
        mainDealProductId &&
        item.productId !== mainDealProductId &&
        !requestsAlreadyCreatedForProduct(userDealProducts, item.productId)
      ) {
        return (
          <div
            key={`${item.productId}_
            ${item.updatedAt.toString()}`}
          >
            {item.productId && (
              <ProductItem
                listItem={item}
                isMarketplace={true}
                useProductCache={true}
                hideGetOfferButton={true}
                isProductSelected={
                  !!selectedProducts.find(
                    (selectedProduct: AnyListItem) =>
                      !!selectedProduct.productId &&
                      !!item.productId &&
                      selectedProduct.productId === item.productId
                  )
                }
                changeProductSelection={(id: number, isSelected: boolean) => {
                  changeProductSelection(
                    productItems,
                    selectedProducts,
                    setSelectedProducts,
                    id,
                    isSelected
                  );
                }}
              />
            )}
          </div>
        );
      }
    });
  if (itemsForRendering.filter((item) => item).length === 0) {
    skip(loggedInUser);
  } else {
    return itemsForRendering;
  }
};

const SuggestAlternativeSellers: React.FC<IProps> = ({
  searchTerm,
  createDealForm,
  loggedInUser,
  suggestedProducts,
  fetchProducts,
  hasFetched,
  skip,
  send,
  breakpoint,
  userDealProducts,
  productsCache,
  productsTradeCache,
  ...props
}) => {
  const substanceOrBrand: string = sgg(
    () => searchTerm,
    () => createDealForm.product!.product!.Substance1.name,
    null
  );
  const mainDealProductId = sg(
    () => createDealForm.product!.product!.id,
    undefined
  );

  const marketOfInterests = sg(() => createDealForm.product!.market, '');

  const [selectedProducts, setSelectedProducts] = useState<AnyListItem[]>([]);

  useEffect(() => {
    if (substanceOrBrand) {
      fetchProducts(substanceOrBrand, marketOfInterests);
    }
  }, [substanceOrBrand]);

  if (!substanceOrBrand) {
    return null;
  } else if (!hasFetched) {
    return (
      <SuggestAlternativeSellersContainer>
        <Loader isLoading={true} firstLoad={true} />
      </SuggestAlternativeSellersContainer>
    );
  } else if (
    suggestedProducts.length === 1 &&
    mainDealProductId &&
    suggestedProducts[0].productId === mainDealProductId
  ) {
    skip(loggedInUser);
  }
  const suggestedProductsWithSeller = suggestedProducts.map(
    (suggestedProduct: any) => {
      const cacheProduct = productsCache[suggestedProduct.productId || 0];
      suggestedProduct.sellerUserId = sg(
        () => cacheProduct.createdBy,
        undefined
      );
      suggestedProduct.sellerCompanyId = sg(
        () => cacheProduct.Company.id,
        undefined
      );
      return suggestedProduct;
    }
  );
  const email = useSelector((state) => state.auth?.loggedInUser?.email);
  const anonymousUserIdItem =
    isBrowser() && localStorage.getItem(anonymousUserId);
  return (
    <>
      <SuggestAlternativeSellersContainer>
        {!!loggedInUser.id && (
          <SuccessLarge
            hideSuccess={true}
            closable={false}
            label={'Great! Your request has been successfully submitted!'}
          />
        )}
        <Header>
          <Heading isAuthenticated={!!loggedInUser.id}>
            Select and send request to alternative{' '}
            {toTitleCase(substanceOrBrand)} sellers!
          </Heading>
          <Subheading>
            Request details will be automatically generated based on your
            initial request.
          </Subheading>
        </Header>
        {renderProductItems(
          loggedInUser,
          skip,
          suggestedProductsWithSeller,
          selectedProducts,
          setSelectedProducts,
          userDealProducts,
          mainDealProductId
        )}
      </SuggestAlternativeSellersContainer>
      <ButtonsContainer>
        <SkipButtonWrapper>
          {breakpoint === Breakpoint.ExtraSmall && (
            <SelectedText>{selectedProducts.length} selected</SelectedText>
          )}
          <OrangeButtonLarge
            id="send-request-to-alternative-sellers"
            label="Skip"
            onClick={() => {
              skip(loggedInUser);
              ReactGA.event({
                category: GACategory ? GACategory.Marketplace : '',
                action: GAActions
                  ? GAActions.requestAlternativeSellersSkip
                  : '',
                email,
                anonymousUserId: anonymousUserIdItem,
              });
            }}
            isSecondary={true}
          />
        </SkipButtonWrapper>
        <SendButtonWrapper>
          {!(breakpoint === Breakpoint.ExtraSmall) && (
            <SelectedText>{selectedProducts.length} selected</SelectedText>
          )}
          <OrangeButtonLarge
            id="send-request-to-alternative-sellers"
            label="Send"
            onClick={() => {
              send(selectedProducts, createDealForm, loggedInUser);
              ReactGA.event({
                category: GACategory ? GACategory.Marketplace : '',
                action: GAActions
                  ? GAActions.requestAlternativeSellersSend
                  : '',
                email,
                anonymousUserId: anonymousUserIdItem,
              });
            }}
          />
        </SendButtonWrapper>
      </ButtonsContainer>
    </>
  );
};

export default SuggestAlternativeSellers;
