import { GAActions, GACategory, ReactGA } from 'client/tracker';
import Breadscrumb from 'app/modules/general/breadcrumbs/breadcrumb-marketplace';
import NeedHelp from 'app/modules/marketplace/components/marketplace-list/components/need-help';
import ProductInfo from 'app/modules/marketplace/components/product-info/components/product-info';
import TopProducts from 'app/modules/marketplace/components/product-info/components/top-products';
import SetSeoMetaTitle from 'app/modules/seo/seo-meta-data';
import { getCountries, toTitleCase } from 'app/store/modules/global/actions';
import { EU28Countries, TypeOrigin } from 'app/store/modules/global/types';
import {
  getProductLink,
  getProductSubstanceName,
} from 'app/store/modules/products/actions';
import { media } from 'app/styles/breakpoint';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { IProps } from './index';
import { anonymousUserId } from 'app/config';
import { useSelector } from 'app/helpers/redux';
import isBrowser from 'app/store/utils/is-browser';

export const hideTopProductsAdjustmentForType = 2;

const MarketplaceListContainer = styled.div`
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
  background-color: ${(props) => props.theme.colors.background};
  display: flex;
  ${media.min.lg`
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
`}
  ${media.max.md`
  padding-left:5%;
  padding-right:5%;
`}
`;

const MarketplaceItemsContainer = styled.div`
  width: 100%;
`;

const MarketplaceMarketingContainer = styled.div`
  width: 25%;
  ${media.max.tablet`
    display:none;
  `}
`;

const MarketplaceFoundHeader = styled.h1`
  font-size: 26px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.grayExtraDark};
  width: 100%;
  ${media.min.lg`
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
`}
  ${media.max.md`
    padding-left: 5%;
    padding-right: 5%;
  `}
`;

const MarketplaceOthersHeader = styled.h2`
  font-size: 26px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.grayExtraDark};
  width: 100%;
  ${media.min.lg`
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
`}
`;

const ProductContainer: React.FC<IProps> = ({
  product,
  productId,
  type,
  isMarketplace,
  productList,
}) => {
  const isManufacturing =
    type === TypeOrigin.licensing ||
    type === TypeOrigin.licensing + hideTopProductsAdjustmentForType;

  let productIdFinal = product && isManufacturing ? product.id : undefined;

  let productLinkName = '';
  let productName = '';
  let productSubstance = '';
  let productCountry = '';
  let productFirstSubstance = '';
  let productSecondSubstance = '';
  let productThirdSubstance = '';
  let productFormDose = '';
  let brandName = '';
  let typeName = '';
  let typeNames = '';
  let prefix = '';
  let productLink = '';
  const mainType = type > 1 ? type - hideTopProductsAdjustmentForType : type;

  let id = 0;
  if (product) {
    prefix = 'product';
    productLink = encodeURIComponent(getProductLink(product, true));
    const euCountry = EU28Countries.find(
      (country) => country.id === product.finishedDoseCountry[0]
    );
    productCountry = euCountry
      ? 'European Union'
      : getCountries([product.finishedDoseCountry]);
    productLinkName = toTitleCase(getProductSubstanceName(product));
    productName = productLinkName;
    productIdFinal = productId;
    productSubstance = toTitleCase(getProductSubstanceName(product));
    productFirstSubstance = product.Substance1.name;
    productSecondSubstance = product.Substance2 ? product.Substance2.name : '';
    productThirdSubstance = product.Substance3 ? product.Substance3.name : '';
    productFormDose =
      product.formSpecification +
      ' ' +
      product.strength +
      (product.volume ? ' - ' + product.volume : '');
    brandName = '';
    typeName = 'manufacturer in';
    typeNames = 'manufacturers';

    id = product.id;
  }

  const linkForSeo = `/${prefix}/${mainType}/${productLink}`;
  const fullProductName = `${productLinkName} ${productFormDose}`;

  const isFastTrack =
    product && !!(product && (!product.adminCreated || product.fastProduct));

  const email = useSelector((state) => state.auth?.loggedInUser?.email);

  const anonymousUserIdItem =
    isBrowser() && localStorage.getItem(anonymousUserId);

  useEffect(() => {
    if (product && !isMarketplace) {
      if (isFastTrack) {
        ReactGA.event({
          category: GACategory ? GACategory.ProductInfoPages : '',
          action: GAActions ? GAActions.fastTrackProductInfoVisit : '',
          label: `${product ? 'product.id: ' + product.id : ''}`,
          email,
          anonymousUserId: anonymousUserIdItem,
        });
      } else {
        ReactGA.event({
          category: GACategory ? GACategory.ProductInfoPages : '',
          action: GAActions ? GAActions.regularProductInfoVisit : '',
          label: `${product ? 'product.id: ' + product.id : ''}`,
          email,
          anonymousUserId: anonymousUserIdItem,
        });
      }
    }
  }, [isFastTrack]);

  return (
    <>
      <Breadscrumb currPage={productName} />
      <MarketplaceFoundHeader>
        {/* Fill in your requirements to receive a commercial offer. */}
        {productLinkName} {productFormDose} {typeName} {productCountry}
      </MarketplaceFoundHeader>

      <SetSeoMetaTitle
        metaTitle={`${productLinkName} ${productFormDose} ${typeName} ${productCountry} | Pipelinepharma`}
        canonical={linkForSeo}
      />
      <MarketplaceListContainer>
        <MarketplaceItemsContainer>
          <ProductInfo
            productListItem={{
              ranking: 0,
              updatedAt: new Date(),
              productId: productIdFinal,
            }}
            productId={productId}
            type={mainType}
            isMarketplace={isMarketplace}
          />
          {productList.length > 0 && type === mainType && (
            <MarketplaceOthersHeader>
              Other {productSubstance} {typeNames}:
            </MarketplaceOthersHeader>
          )}
          {type === mainType && (
            <TopProducts
              key={
                productFirstSubstance +
                productSecondSubstance +
                productThirdSubstance +
                id
              }
              substanceName={productFirstSubstance}
              substance2Name={productSecondSubstance}
              substance3Name={productThirdSubstance}
              brandName={brandName}
              type={mainType}
              productId={id}
            />
          )}
        </MarketplaceItemsContainer>

        <MarketplaceMarketingContainer>
          <NeedHelp fullProductName={fullProductName} />
        </MarketplaceMarketingContainer>
      </MarketplaceListContainer>
    </>
  );
};

export default ProductContainer;
