import { addToRequestFromPotentialSuppliers } from 'app/store/modules/deal/actions';
import { ICreateProductDeal } from 'app/store/modules/deal/types';
import { IWithApolloClient } from 'app/store/modules/global/types';
import { getProductEligibleToAddInDeal } from 'app/store/modules/marketplace/query';
import { Product } from 'app/store/modules/products/types';
import { IStore } from 'app/store/types';
import sg from 'app/utils/safe-get';
import { withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import AddToDeal from './add-to-deal';

interface IStateProps {
  isAdmin: boolean;
  userId: number;
}
interface IDispatchProps {
  getDealFromUser: (
    userId: number,
    isAdmin: boolean,
    substance1: string,
    substance2?: string | undefined,
    substance3?: string | undefined,
    doseFormCategory?: string | undefined,
    notAvailableMarkets?: string | undefined
  ) => any;
  productAddToDeal: (params: ICreateProductDeal) => any;
}
interface IOwnProps {
  isMarketplace: boolean | undefined;
  offerButton: boolean;
  redirectToDeal: boolean;
  productDetails?: Product;
  showMoreGetOfferButtonInMarketplace: any;
}

export type IProps = IStateProps & IDispatchProps & IOwnProps;

export default withApollo<IOwnProps>(
  connect<IStateProps, IDispatchProps, IOwnProps & IWithApolloClient, IStore>(
    (state, ownProps) => {
      return {
        isAdmin: state.auth.loggedInUser.isAdmin,
        userId: state.auth.loggedInUser.id,
      };
    },
    (dispatch, ownProps) => ({
      getDealFromUser: async (
        userId,
        isAdmin,
        substance1,
        substance2,
        substance3,
        doseFormCategory,
        notAvailableMarkets
      ) => {
        const eligibleDeals: any[] = [];
        if (doseFormCategory) {
          const res = await getProductEligibleToAddInDeal({
            client: ownProps.client,
            userId,
            isAdmin,
            substance1,
            substance2,
            substance3,
            doseFormCategory,
          });
          const activeDeals = sg(() => res!.data.deals, undefined);
          if (activeDeals) {
            activeDeals.map((d: any) => {
              if (notAvailableMarkets && d.countriesOfInterest !== null) {
                const markets = notAvailableMarkets.split(',');
                const intersection = markets!.filter((country: string) =>
                  d.countriesOfInterest.includes(country)
                );
                if (intersection.length < 1) {
                  eligibleDeals.push(d);
                }
              } else {
                eligibleDeals.push(d);
              }
            });
          }
        }
        return {
          dealEligible: eligibleDeals.length > 0 ? 1 : 0,
          requests: eligibleDeals,
        };
      },
      productAddToDeal: (params) => {
        dispatch(
          addToRequestFromPotentialSuppliers({
            client: ownProps.client,
            values: { ...params },
          })
        );
      },
    })
  )(AddToDeal)
);
