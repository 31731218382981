import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { _, media } from 'app/styles/breakpoint';
import { LogoHorizontal } from '../atoms/branding';
import MenuDesktop from './MenuDesktop';
import type { LinkData } from './types';
import MenuMobile from './MenuMobile';
import { useActiveLinks } from './hooks';
import { privateLegacyPaths, publicLegacyPaths } from './data';

export interface MenuSectionProps {
  /**
   * What is the current path name?
   */
  pathname: string;
  /**
   * What are the public links?
   */
  publicLinks: LinkData[];
  /**
   * What are the private links?
   */
  privateLinks: LinkData[];
  /**
   * What are the admin links?
   */
  adminLinks: LinkData[];
}

const Content = styled.div`
  display: flex;
  width: 100%;
  padding: 26px 0;
  ${_(media.max.sm)} {
    padding: 12px 0;
  }
`;

const StyledIconLink = styled.a`
  width: 260px;
  margin-right: 32px;

  ${_(media.max.sm)} {
    & > svg {
      width: 150px;
    }
  }
`;

const getAdditionalStyle = (pathname: string) => {
  const paths = pathname.split('/');
  if (
    pathname === '/' ||
    publicLegacyPaths.includes(pathname) ||
    (paths.length > 1 && !privateLegacyPaths.includes(paths[1]))
  ) {
    return {
      maxWidth: '1220px',
      margin: '0 auto',
      padding: '0 20px',
    };
  }
  return {
    maxWidth: '1390px',
    margin: '0 auto',
    paddingLeft: '33px',
    paddingRight: '25px',
  };
};

const Header = styled.header`
  width: 100%;
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: col;
  justify-items: center;
  align-items: center;
  .menu-mobile {
    display: none;
  }
  ${_(media.max.sm)} {
    .menu-desktop {
      padding: 0 23px;
      display: none;
    }
    .menu-mobile {
      display: flex;
    }
  }

  background-color: ${(props) => props.theme.colors.white};
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
`;

const MenuSection: React.FC<MenuSectionProps> = ({
  pathname,
  publicLinks,
  privateLinks,
  adminLinks,
}: MenuSectionProps) => {
  const loggedInUser: any = useSelector((state) => state?.auth?.loggedInUser);
  const isLoggedIn = loggedInUser.id ? true : false;
  const activeLinks = useActiveLinks({
    isLoggedIn,
    isAdmin: loggedInUser?.isAdmin,
    publicLinks,
    privateLinks,
    adminLinks,
    pathname,
  });

  return (
    <HeaderContainer>
      <Header as="header" style={getAdditionalStyle(pathname)}>
        <Content>
          <StyledIconLink href="/">
            <LogoHorizontal />
          </StyledIconLink>
          <MenuDesktop
            className="menu-desktop"
            links={activeLinks}
            isLoggedIn={isLoggedIn}
            firstName={loggedInUser?.firstName}
          />
          <MenuMobile
            className="menu-mobile"
            links={activeLinks}
            isLoggedIn={isLoggedIn}
            firstName={loggedInUser?.firstName}
          />
        </Content>
      </Header>
    </HeaderContainer>
  );
};

export default MenuSection;
