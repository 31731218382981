import {
  allCountries as countries,
  allCountriesItem,
  EU28Countries as euCountries,
} from 'app/store/modules/global/types';
import {
  MarketplaceFilter,
  MarketplaceFilterType,
} from 'app/store/modules/marketplace/types';
import { isEmpty, isNumber } from 'lodash';
import { sg } from './safe-get';

export const isFilterEmpty = (
  filter: MarketplaceFilter,
  filterName: MarketplaceFilterType
) => {
  if (filter[filterName] && typeof filter[filterName] === 'object') {
    return Object.keys(filter[filterName]!).every(
      (key) => !filter[filterName]![key]
    );
  }

  return !(
    (!Array.isArray(filter[filterName]) && !!filter[filterName]) ||
    (Array.isArray(filter[filterName]) &&
      (filter[filterName] as any[]).length > 0)
  );
};

export const atLeastOneFilterIsActive = (filter: MarketplaceFilter) =>
  !(
    isFilterEmpty(filter, MarketplaceFilterType.masForSale) &&
    isFilterEmpty(filter, MarketplaceFilterType.offerFor) &&
    isFilterEmpty(filter, MarketplaceFilterType.countryOfOrigin) &&
    isFilterEmpty(filter, MarketplaceFilterType.dossierStatus) &&
    isFilterEmpty(filter, MarketplaceFilterType.dossierType) &&
    isFilterEmpty(filter, MarketplaceFilterType.gmpApprovals) &&
    isFilterEmpty(filter, MarketplaceFilterType.formSpecification) &&
    isFilterEmpty(filter, MarketplaceFilterType.doseCategory) &&
    isFilterEmpty(filter, MarketplaceFilterType.therapyArea) &&
    isFilterEmpty(filter, MarketplaceFilterType.productionCountry) &&
    isFilterEmpty(filter, MarketplaceFilterType.registrationCountry) &&
    isFilterEmpty(filter, MarketplaceFilterType.doseCategoryTrade) &&
    isFilterEmpty(filter, MarketplaceFilterType.therapyAreaTrade) &&
    isFilterEmpty(filter, MarketplaceFilterType.manufacturerProducts)
  );

export const getNonEuCountriesAndEu = () => {
  const nonEuCountries = countries
    .filter((country) => country.region !== true)
    .filter(
      (country) => !euCountries.find((euCountry) => country.id === euCountry.id)
    );
  nonEuCountries.unshift({
    id: 'eu',
    name: 'European Union',
    order: 0,
  });
  return nonEuCountries;
};
export const getAllCountriesAndEU = () => {
  return [
    {
      id: 'eu',
      name: 'European Union',
      order: 0,
    },
    ...countries,
  ];
};

const getAvailableDocumentsString = (availableDocumentsFilter: {
  coa: boolean;
  coo: boolean;
}) => {
  if (
    sg(() => availableDocumentsFilter.coa, false) &&
    sg(() => availableDocumentsFilter.coo, false)
  ) {
    return 'coa,coo';
  } else if (sg(() => availableDocumentsFilter.coa, false)) {
    return 'coa';
  } else if (sg(() => availableDocumentsFilter.coo, false)) {
    return 'coo';
  } else {
    return '';
  }
};

export const getfiltersUrlQuery = (filterValue: MarketplaceFilter) => {
  let filtersPath = '';
  Object.entries(filterValue).map((singleFilter) => {
    if (singleFilter[0] === MarketplaceFilterType.availableDocuments) {
      if (
        !isEmpty(singleFilter[1]) &&
        getAvailableDocumentsString(singleFilter[1]) !== ''
      ) {
        filtersPath =
          (filtersPath ? `${filtersPath}&` : '') +
          (`${singleFilter[0]}=` +
            getAvailableDocumentsString(singleFilter[1]));
      }
    } else if (
      singleFilter[0] === MarketplaceFilterType.manufacturerProducts &&
      singleFilter[1]
    ) {
      filtersPath = filtersPath
        ? filtersPath + '&manufacturerProducts=true'
        : 'manufacturerProducts=true';
    } else if (
      singleFilter[0] === MarketplaceFilterType.traderProducts &&
      singleFilter[1]
    ) {
      filtersPath = filtersPath
        ? filtersPath + '&wholesalerProducts=true'
        : 'wholesalerProducts=true';
    } else if (
      singleFilter[0] !== MarketplaceFilterType.product &&
      singleFilter[0] !== MarketplaceFilterType.masForSale &&
      singleFilter[0] &&
      singleFilter[1] &&
      (isNumber(singleFilter[1]) ||
        !isEmpty(singleFilter[1]) ||
        singleFilter[1] === true)
    ) {
      filtersPath =
        (filtersPath ? `${filtersPath}&` : '') +
        singleFilter[0] +
        '=' +
        singleFilter[1].toString();
    } else if (
      singleFilter[0] === MarketplaceFilterType.masForSale &&
      singleFilter[0] &&
      singleFilter[1] &&
      !isEmpty(singleFilter[1])
    ) {
      const allCountriesItemIndex = singleFilter[1].findIndex(
        (country: string) => country === allCountriesItem.id
      );
      let filteredCountriesArray = singleFilter[1];
      if (singleFilter[1].length > 1 && allCountriesItemIndex > -1) {
        if (allCountriesItemIndex === singleFilter[1].length - 1) {
          filteredCountriesArray = [allCountriesItem.id];
        } else {
          filteredCountriesArray.shift();
        }
      }
      filtersPath =
        (filtersPath ? `${filtersPath}&` : '') +
        singleFilter[0] +
        '=' +
        filteredCountriesArray.toString();
    }
  });
  return filtersPath;
};
