import { GAActions, GACategory, ReactGA } from 'client/tracker';
import {
  openPopupIfNeeded,
  setCreateAccountModalOpen,
  setGetStartedModalOpen,
} from 'app/store/modules/auth/actions';
import {
  codePlacesRelatedToVerifyEmailModal,
  User,
} from 'app/store/modules/auth/types';
import { setSuggestAlternativeSellersModalOpen } from 'app/store/modules/products/actions';
import { hasFetched } from 'app/store/modules/products/actions';
import { IStore } from 'app/store/types';
import { connect } from 'react-redux';
import SuggestAlternativeSellersModal from './suggest-alternative-sellers-modal';

export default connect(
  (state: IStore) => {
    return {
      isOpen: state.products.suggestAlternativeSellersModalOpen,
      loggedInUser: state.auth.loggedInUser,
      breakpoint: state.device.breakpoint,
    };
  },
  (dispatch) => {
    return {
      closeModal: (loggedInUser: User) => {
        if (!(loggedInUser && loggedInUser.id)) {
          dispatch(setGetStartedModalOpen(true));
        } else if (
          !loggedInUser.firstName ||
          !loggedInUser.lastName ||
          !(loggedInUser.Company && loggedInUser.Company.id)
        ) {
          dispatch(setCreateAccountModalOpen(true));
        }
        dispatch(setSuggestAlternativeSellersModalOpen(false));
        dispatch(hasFetched(false));
        ReactGA.event({
          category: GACategory ? GACategory.Marketplace : '',
          action: GAActions ? GAActions.requestAlternativeSellersSkipClose : '',
          email: loggedInUser?.email,
        });
        dispatch(
          openPopupIfNeeded({
            calledFrom:
              codePlacesRelatedToVerifyEmailModal.suggestAlternativeSellersModal,
          })
        );
      },
    };
  }
)(SuggestAlternativeSellersModal);
