import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { Animate } from '../Animate';
// import t from '../../@theme/index';

export interface ModalPortalProps {
  /**
   * Is the modal open
   */
  open?: boolean;
  /**
   * What happens when the backdrop is clicked
   */
  onBackdropClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  /**
   * Overrides
   */
  className?: string;
}

const Backdrop = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9000;

  background: rgba(0, 0, 0, 0.25);

  transition: opacity 200ms;

  &.enter,
  &.exit-active {
    opacity: 0;
  }

  &.enter {
    transition-duration: 0ms;
  }
`;

const ModalPortal: React.FC<ModalPortalProps> = ({
  children,
  open = false,
  onBackdropClick = () => void 0,
  className,
}) => {
  const [mounted, setMounted] = useState(false);

  /**
   * Used to avoid targeting document.body for the portal when it doesn't
   * exist
   */
  useEffect(() => {
    const waitForDocument = setInterval(() => {
      if (document.body) {
        clearInterval(waitForDocument);
        setMounted(true);
      }
    }, 50);
  }, []);

  return mounted
    ? ReactDOM.createPortal(
        <Animate
          in={open}
          duration="prompt"
          unmountOnExit
          appear
          mountOnEnter
          onEntered={() =>
            setTimeout(() => (document.body.style.overflow = 'hidden'), 150)
          }
          onExiting={() => (document.body.style.overflow = '')}
        >
          <Backdrop className={className} onClick={onBackdropClick}>
            {children}
          </Backdrop>
        </Animate>,
        document.body
      )
    : null;
};

export default ModalPortal;
