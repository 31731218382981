import FastTrack from 'app/components/inputs/fast-track';
import RemindPasswordModal from 'app/components/inputs/remind-password-modal';
import Loader from 'app/components/loader';
import AddToDeal from 'app/modules/marketplace/components/marketplace-list/components/add-to-deal';
import ProductInfo from 'app/modules/marketplace/components/marketplace-list/components/product-info';
import RequestForm from 'app/modules/marketplace/components/request-form';
import DealExistsModal from 'app/components/modals/deal-exists-modal';
import GetStartedModal from 'app/components/modals/marketplace-get-started-modal';
import { routes } from 'app/config';
import { toTitleCase } from 'app/store/modules/global/actions';
import { IWithApolloClient, TypeOrigin } from 'app/store/modules/global/types';
import { getProductName } from 'app/store/modules/marketplace/actions';
import {
  getAvailableFor,
  getCountries,
  getCountriesEuAsOne,
  getDossierComplete,
  getDossierStatus,
  getDossierType,
  getGPMApprovals,
  getProductLink,
  getStabilityZones,
} from 'app/store/modules/products/actions';
import { convertToProduct } from 'app/store/modules/products/fragments/product';
import { getProductQuery } from 'app/store/modules/products/query';
import { _, media } from 'app/styles/breakpoint';
import { push } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, RouteComponentProps, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import {
  MarketingBubble,
  MarketingSectionWrapper,
} from '../../../marketplace-list/components/marketing-bubble';
import { DispatchProps, OwnProps, StateProps } from './index';
import isBrowser from 'app/store/utils/is-browser';

const Wrapper = styled.div`
  margin-bottom: 40px;
  ${media.max.sm`
    margin-bottom: 20px;
  `}
`;

const Container = styled.div`
  width: 100%;
  padding: 26px 30px;
  box-shadow: ${(props) =>
    props.theme.colors.boxShadowParam + ' ' + props.theme.colors.filtersShadow};
  background-color: ${(props) => props.theme.colors.white};
  margin-bottom: 15px;
  margin-right: 15px;
  ${media.max.sm`
    padding-left:5%;
    padding-right:5%;
    margin-right: 0;
  `}
`;

const Header = styled.div`
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.colors.grayLight};
  padding-bottom: 18px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
`;

const ProductNameContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ProductHeader = styled.h2`
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  color: ${(props) => props.theme.colors.grayExtraDark};
  margin-bottom: 1px;
  margin-top: 0;
`;
const ProductSubHeader = styled.h3`
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: ${(props) => props.theme.colors.grayText};
  margin: 0;
  width: 100%;
`;

const ProductAllInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;
const ProductInfoContainer = styled.div`
  min-width: 50%;
  max-width: 50%;
  ${media.max.sm`
    width: 100%;
    max-width: 100%;
  `}
`;

const ProductInfoFullWidthContainer = styled.div`
  min-width: 100%;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid ${(props) => props.theme.colors.grayLight};
  ${media.max.sm`
    width: 100%;
  `}

  ${_(media.max.sm)} {
    .vertical {
      flex-direction: column;
    }
  }
`;

type Props = DispatchProps &
  OwnProps &
  StateProps &
  IWithApolloClient &
  RouteComponentProps<any>;

const dosierUnderDevelopmentId = 2;

const ProductInfoItem: React.FC<Props> = ({
  product: marketplaceProduct,
  productListItem,
  client,
  fetchProductAction,
  isAdmin,
  isLoggedIn,
  productId,
  type,
  isMarketplace,
  history,
  userCompanyId,
  authCheckComplete,
  userHasDeal,
  userId,
}) => {
  const dispatch = useDispatch();
  const [product, setProduct] = useState(marketplaceProduct);
  useEffect(() => {
    if (
      client &&
      !product &&
      type.toString() === TypeOrigin.licensing.toString()
    ) {
      let isMounted = true;
      getProductQuery({ client, id: productId, isAdmin, isLoggedIn }).then(
        (response: any) => {
          if (response?.data?.products && isMounted) {
            const productResponse = response.data.products.map((x: any) =>
              convertToProduct(x)
            )[0];
            fetchProductAction(productResponse);
            setProduct(productResponse);
          }
        }
      );
      return () => {
        isMounted = false;
      };
    }
    if (client && type.toString() === TypeOrigin.trade.toString()) {
      dispatch(push(routes.marketplace));
    }
  }, [authCheckComplete]);

  useEffect(() => {
    // http:// localhost:3000/product/0/dipivefrinhydrochloride-manufacturer-109987
    if (product && product.Company && product.Company.hidden) {
      history.push('/product-not-found');
    }
  }, [product]);
  const [eligibleForAddToDeal, setEligibleForAddToDeal] = useState<number>(0);
  useEffect(() => {
    if (product && userId) {
      userHasDeal(
        userId,
        isAdmin,
        product.Substance1.name.replace(/ .*/, ''),
        product.Substance2
          ? product.Substance2.name.replace(/ .*/, '')
          : undefined,
        product.Substance3
          ? product.Substance3.name.replace(/ .*/, '')
          : undefined,
        product.doseFormCategory,
        product.notAvailableMarkets.toString()
      ).then((eligible: any) => {
        setEligibleForAddToDeal(eligible);
      });
    }
  });

  let productName = '';
  let formStrenght = '';
  let marketingMessage = '';
  let productPageLink = '';
  let adminComments = '';
  let adminCreated;
  let fastProduct;
  if (product) {
    productName = getProductName(product);
    formStrenght =
      (product.formSpecification
        ? toTitleCase(product.formSpecification)
        : '') +
      ' ' +
      (product.strength ? product.strength : '') +
      (product.volume ? ' - ' + product.volume : '');
    marketingMessage = product.marketingMessage ? product.marketingMessage : '';

    adminComments = isAdmin ? product.adminComments : '';
    productPageLink =
      '/products/' + encodeURIComponent(getProductLink(product, true));
    adminCreated = product.adminCreated;
    fastProduct = product.fastProduct;
  }

  const shouldRenderFullWidth = () => {
    if (product) {
      const all =
        getCountries(product.notAvailableMarkets) +
        getCountries(product.registeredIn) +
        getAvailableFor(product) +
        (product.commentsOnThePatentStatus
          ? product.commentsOnThePatentStatus
          : '') +
        adminComments;

      return all.length > 0;
    } else {
      return false;
    }
  };
  const showRequestButtonIfNotMyProduct =
    product !== null &&
    product !== undefined &&
    product.Company &&
    !(userCompanyId === product.Company.id && !product.adminCreated);

  const location = useLocation();
  console.log(location);

  useEffect(() => {
    if (isBrowser() && !isMarketplace && product && (window as any).omnisend) {
      try {
        console.log('send omnisend');
        (window as any).omnisend.push([
          'track',
          '$productViewed',
          {
            $productID: product.id.toString(),
            $variantID: product.id.toString(),
            $currency: 'EUR',
            $price: 1, //price in cents (integer)
            $oldPrice: 1, //old price in cents (integer)
            $title: productName,
            $description: '',
            $imageUrl:
              'https://pipelinepharma.com/img/pp-logo-rebranded/logo-02.svg',
            $productUrl: 'http://pipelinepharma.com/' + location.pathname,
            $vendor: product.companyId.toString(),
          },
        ]);
      } catch (e) {
        console.log(e);
      }
    }
  }, [product]);

  return (
    <Wrapper>
      <Loader
        firstLoad={true}
        isLoading={
          !product &&
          productListItem.productId !== undefined &&
          productListItem.productId !== null
        }
      >
        <Container>
          <Header>
            <ProductNameContainer>
              {isMarketplace && showRequestButtonIfNotMyProduct ? (
                <Link to={productPageLink}>
                  <ProductHeader>{productName}</ProductHeader>
                  <ProductSubHeader>{formStrenght}</ProductSubHeader>
                </Link>
              ) : (
                <div>
                  <ProductHeader>{productName}</ProductHeader>
                  <ProductSubHeader>{formStrenght}</ProductSubHeader>
                </div>
              )}
              <MarketingSectionWrapper>
                {marketingMessage !== '' &&
                  marketingMessage !== undefined &&
                  marketingMessage !== null && (
                    <MarketingBubble>{marketingMessage}</MarketingBubble>
                  )}
                {(adminCreated === false || fastProduct) && (
                  <FastTrack
                    marketingMessageExist={
                      marketingMessage !== undefined &&
                      marketingMessage !== null &&
                      marketingMessage.length > 0
                    }
                  />
                )}
              </MarketingSectionWrapper>
            </ProductNameContainer>
            {product && (
              <AddToDeal
                offerButton={false}
                isMarketplace={true}
                showMoreGetOfferButtonInMarketplace={true}
                redirectToDeal={true}
                productDetails={product}
              />
            )}
          </Header>
          <ProductAllInfoContainer>
            <ProductInfoContainer>
              {product && (
                <ProductInfo
                  label={'Dossier type'}
                  value={product && getDossierType(product.dosierType)}
                />
              )}
              {product && (
                <ProductInfo
                  label={'Dossier status'}
                  value={product && getDossierStatus(product.dosierStatus)}
                />
              )}
              {product && product.dosierStatus === dosierUnderDevelopmentId && (
                <ProductInfo
                  label={'Completion date'}
                  value={product && getDossierComplete(product)}
                />
              )}
              {product && (
                <ProductInfo
                  label={'GMP approvals'}
                  value={
                    product &&
                    getGPMApprovals(product.gmpApprovalForThisProduct)
                  }
                />
              )}
              {product && (
                <ProductInfo
                  label={'Free MAs'}
                  value={
                    product &&
                    getCountries(product.marketingAuthorizationsAchievedCountry)
                  }
                />
              )}
              {product && (
                <ProductInfo
                  label={'Shelf life'}
                  value={
                    product && product.shelfLife !== '' && product.shelfLife
                  }
                />
              )}
            </ProductInfoContainer>
            <ProductInfoContainer>
              {product && (
                <ProductInfo
                  label={'Country of origin'}
                  value={
                    product && getCountriesEuAsOne(product.finishedDoseCountry)
                  }
                />
              )}
              {product && (
                <ProductInfo
                  label={'Stability zones'}
                  value={
                    product && getStabilityZones(product.stabilityDataZones)
                  }
                />
              )}
              {product &&
                product.clinicalData &&
                product.clinicalData !== '' &&
                product.clinicalData !== null && (
                  <ProductInfo
                    label={'Clinical data'}
                    value={product.clinicalData}
                  />
                )}
              {product && (
                <ProductInfo
                  label={'CPP available from'}
                  value={product && getCountries(product.cppAvailableFrom)}
                />
              )}
              {product && (
                <ProductInfo
                  label={'Batch size'}
                  value={
                    product && product.batchSize !== '' && product.batchSize
                  }
                />
              )}
              {product && (
                <ProductInfo
                  label={'MOQ'}
                  value={product && product.moq !== '' && product.moq}
                />
              )}
              {product && (
                <ProductInfo
                  label={'Pack size'}
                  value={product && product.packSize !== '' && product.packSize}
                />
              )}
            </ProductInfoContainer>
            {shouldRenderFullWidth() && (
              <ProductInfoFullWidthContainer>
                {product && (
                  <ProductInfo
                    label={'Unavailable markets'}
                    value={product && getCountries(product.notAvailableMarkets)}
                  />
                )}
                {product && (
                  <ProductInfo
                    label={'Registered in'}
                    value={product && getCountries(product.registeredIn)}
                  />
                )}
                {product && (
                  <ProductInfo
                    label={'Available for'}
                    value={product && getAvailableFor(product)}
                  />
                )}
                {product &&
                  product.commentsOnThePatentStatus &&
                  product.commentsOnThePatentStatus !== '' && (
                    <ProductInfo
                      className="vertical"
                      label={'Comments on patent'}
                      value={product && product.commentsOnThePatentStatus}
                    />
                  )}

                {product && isAdmin && (
                  <ProductInfo label={'Admin comments'} value={adminComments} />
                )}
              </ProductInfoFullWidthContainer>
            )}
          </ProductAllInfoContainer>
        </Container>
        {product &&
          showRequestButtonIfNotMyProduct &&
          !eligibleForAddToDeal && <RequestForm product={product} />}
      </Loader>
      <GetStartedModal />
      <RemindPasswordModal />
      <DealExistsModal />
    </Wrapper>
  );
};

export default ProductInfoItem;
