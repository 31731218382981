import OrangeButton from 'app/components/buttons/orange-button';
import OrangeButtonDisabled from 'app/components/buttons/orange-button-disabled';
import ConditionalContainer from 'app/components/misc/conditional-container';
import { anonymousUserId } from 'app/config';
import { useSelector } from 'app/helpers/redux';
import { hideTopProductsAdjustmentForType } from 'app/modules/marketplace/components/product-info/product-container';
import { getProductLink } from 'app/store/modules/products/actions';
import isBrowser from 'app/store/utils/is-browser';
import { sg } from 'app/utils/safe-get';
import { GAActions, GACategory, ReactGA } from 'client/tracker';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { IProps } from './index';

const AddToDeal: React.FC<IProps> = ({
  productDetails,
  isMarketplace,
  productAddToDeal,
  getDealFromUser,
  isAdmin,
  userId,
  showMoreGetOfferButtonInMarketplace,
  offerButton,
  redirectToDeal,
}) => {
  const type = 0;
  const prefix = 'product';
  const productPageLink = encodeURIComponent(
    getProductLink(productDetails, true)
  );
  showMoreGetOfferButtonInMarketplace = showMoreGetOfferButtonInMarketplace
    ? showMoreGetOfferButtonInMarketplace
    : true;
  const [eligibleForAddToDeal, setEligibleForAddToDeal] = useState<number>(0);
  const [dealRequestExist, setDealRequestExist] = useState<number>(0);
  const [eligibleDeals, setEligibleRequests] = useState<any>(null);
  const [dealDetailLink, setDealDetailLink] = useState<string>('');

  useEffect(() => {
    if (productDetails && userId) {
      getDealFromUser(
        userId,
        isAdmin,
        productDetails.Substance1.name.replace(/ .*/, ''),
        productDetails.Substance2
          ? productDetails.Substance2.name.replace(/ .*/, '')
          : undefined,
        productDetails.Substance3
          ? productDetails.Substance3.name.replace(/ .*/, '')
          : undefined,
        productDetails.doseFormCategory,
        productDetails.notAvailableMarkets.toString()
      ).then((eligible: any) => {
        if (eligible.requests.length > 0) {
          if (eligible.requests[0]!.Requests.length > 0) {
            const dealRequests = eligible.requests[0]!.Requests;
            dealRequests.map((d: any) => {
              if (
                d.product_id === productDetails.id &&
                d.buyer_user_id === eligible.requests[0]!.Buyer.id &&
                d.seller_user_id ===
                  parseInt(String(productDetails!.createdBy)) &&
                d.seller_company_id === productDetails!.Company.id
                // d.deal_type === eligible.requests[0]!.dealType
              ) {
                setDealRequestExist(1);
              }
            });
          }
        }
        setEligibleForAddToDeal(
          eligible.dealEligible === 1 && dealRequestExist === 0 ? 1 : 0
        );
        setEligibleRequests(
          dealRequestExist === 0 ? eligible.requests[0] : null
        );
        setDealDetailLink(
          eligible.dealEligible === 1
            ? `/deal-details/${eligible.requests[0]!.id}`
            : `/deal-details/`
        );
      });
    }
  }, [isMarketplace, eligibleForAddToDeal, dealRequestExist]);

  const addToDealButton = () => {
    return (
      <OrangeButton
        id="add-to-deal-button"
        label={'+ Add to deal'}
        onClick={() => {
          const countriesOfInterest = sg(
            () => eligibleDeals!.countriesOfInterest,
            undefined
          );

          productAddToDeal({
            dealId: eligibleDeals!.id,
            productId: productDetails!.id,
            createdBy: userId,
            sellerUserId: productDetails!.createdBy,
            sellerCompanyId: productDetails!.Company.id,
            buyerUserId: eligibleDeals!.Buyer.id,
            buyerCompanyId: eligibleDeals!.Buyer.Company.id,
            dealType: eligibleDeals.dealType,
            estimatedAnualQuantity: eligibleDeals!.estimatedAnnualQuantity,
            leadingPriceLevel: eligibleDeals!.targetSupplyPrice,
            market: countriesOfInterest!.toString(),
            comments: eligibleDeals!.comments,
            expectSignContract: eligibleDeals!.expectSignContract,
          });

          setEligibleForAddToDeal(0);
          setDealRequestExist(1);
        }}
      />
    );
  };
  const email = useSelector((state) => state.auth?.loggedInUser?.email);
  const uuid = isBrowser() && localStorage.getItem(anonymousUserId);

  return (
    <ConditionalContainer show={showMoreGetOfferButtonInMarketplace}>
      {isMarketplace &&
        eligibleForAddToDeal === 0 &&
        dealRequestExist === 1 && (
          <OrangeButtonDisabled
            label={'Added'}
            tooltipText={'This product is already added to an existing deal.'}
            originalWidth={true}
          />
        )}

      {/*no redirection. only used for marketplace*/}
      {isMarketplace &&
        eligibleForAddToDeal === 1 &&
        dealRequestExist === 0 &&
        !redirectToDeal &&
        addToDealButton()}

      {/*Redirect to deal details page from product details page on add to deal button click*/}
      {isMarketplace &&
        eligibleForAddToDeal === 1 &&
        dealRequestExist === 0 &&
        redirectToDeal && (
          <Link to={dealDetailLink} style={{ width: '100%', maxWidth: 140 }}>
            {addToDealButton()}
          </Link>
        )}

      {eligibleForAddToDeal === 0 && dealRequestExist === 0 && offerButton && (
        <Link
          to={`/${prefix}/${
            type + hideTopProductsAdjustmentForType
          }/${productPageLink}`}
          style={{ width: '100%', maxWidth: 140 }}
        >
          <OrangeButton
            onClick={() => {
              ReactGA.event({
                category: GACategory ? GACategory.RFQ : '',
                action: GAActions ? GAActions.RFQ_GET_OFFER : '',
                label: undefined,
                email,
                anonymousUserId: uuid,
              });
            }}
            label={'Get offer'}
          />
        </Link>
      )}
    </ConditionalContainer>
  );
};

export default AddToDeal;
