import { isNull, isUndefined } from 'lodash';

// custom imports
import { Company } from 'app/store/modules/auth/types';
import { sg } from 'app/utils/safe-get';

/**
 *
 * @description
 * Should return if all the qualification settings fields are filled or not
 * by default returns false
 *
 * @param comp Obj
 * @returns boolean
 *
 */
export const qualificationSettingsFilled = (comp: Company) => {
  if (!isUndefined(comp) && !isNull(comp)) {
    const isAnyFieldEmpty =
      !sg(() => comp.numberOfEmployees, false) ||
      !sg(() => comp.summary, false) ||
      !sg(() => comp.annualRevenue, false) ||
      !sg(() => comp.salesChannels, false) ||
      !sg(() => comp.therapyAreas, false) ||
      !sg(() => comp.portfolioProductsList, false);

    return !isAnyFieldEmpty;
  }
  return false;
};
