import * as u from 'app/store/modules/query-utils';
import {
  CompanyUtils,
  RequestUtils,
  SubstanceUtils,
  UserUtils,
} from 'app/store/modules/query-utils';
import { isFilterEmpty } from 'app/utils/filter-utils';
import { logger } from 'app/utils/logger';
import { sg } from 'app/utils/safe-get';
import gql from 'graphql-tag';
import { newProductsDate } from '.';
import { User } from '../../auth/types';
import { companyFragment } from '../../global/fragments/company';
import { allCountriesItem, IWithApolloClient } from '../../global/types';
import { IProduct, productFragment } from '../../products/fragments/product';
import { Substance } from '../../products/types';
import { dealSchema } from '../../query-utils/schema';
import { MarketplaceFilter, MarketplaceFilterType } from '../types';

export interface IGetMarketplaceItemsList extends IWithApolloClient {
  size: number;
  offset: number;
  filter?: MarketplaceFilter;
  isAdmin?: boolean;
  isLogged?: boolean;
}
export interface IGetMarketplaceItemsListReturn {
  marketplace_list: {
    ranking: number;
    productId: number;
    Product: IProduct;
    updatedAt: Date;
  }[];
  marketplace_list_aggregate: { aggregate: { totalCount: number } };
}

export async function getMarketplaceItemsListQuery(
  p: IGetMarketplaceItemsList
) {
  try {
    let showProducts =
      isFilterEmpty(p.filter!, MarketplaceFilterType.offerFor) &&
      isFilterEmpty(p.filter!, MarketplaceFilterType.productionCountry) &&
      isFilterEmpty(p.filter!, MarketplaceFilterType.registrationCountry) &&
      isFilterEmpty(p.filter!, MarketplaceFilterType.availableDocuments) &&
      isFilterEmpty(p.filter!, MarketplaceFilterType.packLanguage) &&
      isFilterEmpty(p.filter!, MarketplaceFilterType.doseFormTrade) &&
      isFilterEmpty(p.filter!, MarketplaceFilterType.doseCategoryTrade) &&
      isFilterEmpty(p.filter!, MarketplaceFilterType.therapyAreaTrade) &&
      isFilterEmpty(p.filter!, MarketplaceFilterType.withPriceProducts);

    if (!showProducts) {
      showProducts = isFilterEmpty(p.filter!, MarketplaceFilterType.offerFor);
    }

    const notDeleted = u._is_null('deleted_at');
    const companyWhere = u.nest('Company', u._eq('hidden', false));
    const dossierStatusWhere =
      p.filter && p.filter.dossierStatus
        ? u._eq('dossier_status', '$dossierStatus')
        : '';
    const dossierTypes = (p && p.filter && p.filter.dossierType) || [];
    const dossierTypeWhere = u._or(
      ...dossierTypes.map((dossierType) => u._eq('dossier_format', dossierType))
    );
    const doseForms = (p && p.filter && p.filter.formSpecification) || [];
    const doseCategories = (p && p.filter && p.filter.doseCategory) || [];
    const doseFormWhere = u._or(
      ...doseForms.map((formSpecification) =>
        u._eq('form_specification', `"${formSpecification}"`)
      )
    );
    const doseCategoryWhere = u._or(
      ...doseCategories.map((doseCategory) =>
        u._eq('dose_form_category', `"${doseCategory}"`)
      )
    );

    const isCountryOfOriginEu =
      p.filter!.countryOfOrigin && p.filter!.countryOfOrigin.includes('eu');
    const multiValueFieldFilterWhere = (
      filterName: string,
      dbFieldName: any
    ): string | boolean => {
      const values = (p && p.filter && p.filter[filterName]) || [];
      if (
        filterName === MarketplaceFilterType.masForSale &&
        values[0] === allCountriesItem.id
      ) {
        return u._and(
          u._is_null(dbFieldName, false),
          `${dbFieldName}: { _neq: ""}`
        );
      } else {
        return u._or(
          ...values.map((value: string) =>
            u._or(u._ilike(dbFieldName, u.escape(`%${value}%`)))
          ),
          filterName === MarketplaceFilterType.countryOfOrigin &&
            isCountryOfOriginEu &&
            u._eq('finished_dose_country_eu', true)
        );
      }
    };
    const therapyAreasFilterWhere = (): string | boolean => {
      const filterValues =
        (p && p.filter && p.filter[MarketplaceFilterType.therapyArea]) || [];
      return u._or(
        ...filterValues.map((filterValue: string) =>
          u._or(
            u.nest(
              'Substance1',
              u.nest('therapy_areas', u._eq('therapyareas_id', filterValue))
            ),
            u.nest(
              'Substance2',
              u.nest('therapy_areas', u._eq('therapyareas_id', filterValue))
            ),
            u.nest(
              'Substance3',
              u.nest('therapy_areas', u._eq('therapyareas_id', filterValue))
            )
          )
        )
      );
    };
    const productWhere = (alias: string) =>
      u.nest(
        alias,
        u._and(
          notDeleted,
          companyWhere,
          therapyAreasFilterWhere(),
          dossierStatusWhere,
          dossierTypeWhere,

          multiValueFieldFilterWhere(
            MarketplaceFilterType.countryOfOrigin,
            'finished_dose_country'
          ),

          multiValueFieldFilterWhere(
            MarketplaceFilterType.masForSale,
            'marketing_authorizations_achieved_country'
          ),

          multiValueFieldFilterWhere(
            MarketplaceFilterType.gmpApprovals,
            'gmp_approval_for_this_product_string'
          ),
          doseFormWhere,
          doseCategoryWhere,
          u._or(
            u.nest('Substance1', u._ilike('name', '$product')),
            u.nest('Substance2', u._ilike('name', '$product')),
            u.nest('Substance3', u._ilike('name', '$product'))
          ),
          u._gte('status', 1)
        )
      );

    const where = u.where(
      u._and(u._or(showProducts && productWhere('Product')))
    );

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return await p.client.query<IGetMarketplaceItemsListReturn>({
      query: gql`
          ${companyFragment.fragment(p.isAdmin, p.isLogged)}
          ${productFragment.fragment(p.isAdmin, p.isLogged)}
          query marketplaceListPage(
          ${u.params(
            ['$size', 'Int'],
            ['$offset', 'Int'],
            ['$product', 'String'],
            p.filter && p.filter.dossierStatus
              ? ['$dossierStatus', 'Int']
              : ['$dossierStatus', 'String'],
            ['$date', 'timestamptz'],
            ['$newProductsDate', 'timestamptz']
          )}
          ) {
              marketplace_list(
              ${u.limit('$size')}
              ${u.offset('$offset')}
              order_by: {ranking: desc, fast_product: desc, updated_at: desc}

          ${where}
          ) {
              ranking
              productId: productid           
              updatedAt: updated_at
              Product {
                  ...${productFragment.name}
                  Company { ...${companyFragment.name}}
              }
              
              
          }
          marketplace_list_aggregate(${where}) {
          aggregate { totalCount: count }
          }
          }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        size: p.size,
        offset: p.offset,
        product: u.like(
          sg(() => p.filter!.product, ''),
          'begining'
        ),
        dossierStatus: sg(() => p.filter!.dossierStatus, ''),
        date: new Date().toISOString(),
        newProductsDate,
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/marketplace/query.ts -> getMarketplaceItemsListQuery',
      e
    );
  }
}
export interface IGetDealReturn {
  deals: {
    id: number;
    Substance1: Substance;
    Substance2: Substance;
    Substance3: Substance;
    Broker: User;
    countriesOfInterest: string;
  }[];
}
export interface IGetDealAtMarketplace extends IWithApolloClient {
  userId: number;
  isAdmin: boolean;
  substance1: string;
  substance2?: string | undefined;
  substance3?: string | undefined;
  doseFormCategory?: string | undefined;
}
export function getProductEligibleToAddInDeal(p: IGetDealAtMarketplace) {
  try {
    let substanceFilter: any = null;
    if (p.substance2 === undefined && p.substance3 === undefined) {
      substanceFilter = u._or(
        u._and(
          u.nest('Substance1', u._ilike('name', '$substance1')),
          u._is_null('substance_2'),
          u._is_null('substance_3')
        ),
        u._and(
          u._is_null('substance_1'),
          u.nest('Substance2', u._ilike('name', '$substance1')),
          u._is_null('substance_3')
        ),
        u._and(
          u._is_null('substance_1'),
          u._is_null('substance_2'),
          u.nest('Substance3', u._ilike('name', '$substance1'))
        )
      );
    }
    if (p.substance2 !== undefined && p.substance3 === undefined) {
      substanceFilter = u._and(
        u._or(
          u.nest('Substance1', u._ilike('name', '$substance1')),
          u.nest('Substance2', u._ilike('name', '$substance1')),
          u.nest('Substance3', u._ilike('name', '$substance1'))
        ),
        u._or(
          u.nest('Substance1', u._ilike('name', '$substance2')),
          u.nest('Substance2', u._ilike('name', '$substance2')),
          u.nest('Substance3', u._ilike('name', '$substance2'))
        )
      );
    }
    if (p.substance2 !== undefined && p.substance3 !== undefined) {
      substanceFilter = u._and(
        u._or(
          u.nest('Substance1', u._ilike('name', '$substance1')),
          u.nest('Substance2', u._ilike('name', '$substance1')),
          u.nest('Substance3', u._ilike('name', '$substance1'))
        ),
        u._or(
          u.nest('Substance1', u._ilike('name', '$substance2')),
          u.nest('Substance2', u._ilike('name', '$substance2')),
          u.nest('Substance3', u._ilike('name', '$substance2'))
        ),
        u._or(
          u.nest('Substance1', u._ilike('name', '$substance3')),
          u.nest('Substance2', u._ilike('name', '$substance3')),
          u.nest('Substance3', u._ilike('name', '$substance3'))
        )
      );
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return p.client.query<IGetDealReturn>({
      query: gql`
        ${new u.Query('getFilteredDealMarketplace', {
          isAdmin: p.isAdmin,
        })
          .params(
            ['$substance1', 'String'],
            ['$substance2', 'String'],
            ['$substance3', 'String'],
            ['$userId', 'Int'],
            ['$doseFormCategory', 'String']
          )
          .query(dealSchema, 'deals')
          .where((t) =>
            t._and(
              t._or(substanceFilter),
              t._is_null('deleted_at'),
              t._eq('buyer_users_id', '$userId'),
              p.doseFormCategory
                ? t._ilike('dose_form_category', '$doseFormCategory')
                : undefined
            )
          )
          .select(
            ['id', 'id'],
            [
              'Substance1',
              'Substance1',
              SubstanceUtils.selectParams('id', 'name'),
            ],
            p.substance2
              ? [
                  'Substance2',
                  'Substance2',
                  SubstanceUtils.selectParams('id', 'name'),
                ]
              : undefined,
            p.substance3
              ? [
                  'Substance3',
                  'Substance3',
                  SubstanceUtils.selectParams('id', 'name'),
                ]
              : undefined,
            ['countries_of_interest', 'countriesOfInterest'],
            ['estimated_annual_quantity', 'estimatedAnnualQuantity'],
            ['target_supply_price', 'targetSupplyPrice'],
            ['comments', 'comments'],
            ['expect_sign_contract', 'expectSignContract'],
            ['deal_type', 'dealType'],
            ['dose_form_category', 'doseFormCategory'],
            ['Broker', 'Broker', UserUtils.selectParams('id')],
            [
              'Buyer',
              true,
              UserUtils.selectParams(
                'id',
                ['full_name', 'fullName'],
                ['first_name', 'firstName'],
                ['last_name', 'lastName'],
                ['email', 'email'],
                ['phone', 'phone'],
                [
                  'Company',
                  true,
                  p.isAdmin
                    ? CompanyUtils.selectParams('id', 'name')
                    : CompanyUtils.selectParams('id'),
                ]
              ),
            ],
            ['created_by', 'createdBy'],
            [
              'Requests',
              'Requests',
              RequestUtils.selectParams(
                'id',
                'product_id',
                'buyer_user_id',
                'seller_user_id',
                'seller_company_id',
                'deal_type'
              ),
            ]
          )
          .orderBy('created_at', 'desc')
          .toString()
          .toString()}
      `,
      fetchPolicy: 'no-cache',
      variables: {
        substance1: u.like(p.substance1, 'middle'),
        substance2: p.substance2
          ? u.like(p.substance2 ? p.substance2 : '', 'middle')
          : undefined,
        substance3: p.substance3
          ? u.like(p.substance3 ? p.substance3 : '', 'middle')
          : undefined,
        userId: p.userId,
        doseFormCategory: p.doseFormCategory
          ? u.like(p.doseFormCategory, 'middle')
          : undefined,
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/requests/query.ts -> getProductEligibleToAddInDeal',
      e
    );
  }
}
