import { media } from 'app/styles/breakpoint';
import React from 'react';
import styled from 'styled-components';

export const ViewButton = styled.div`
  width: fit-content;
  white-space: nowrap;
  padding: 0px 17px 0px 17px;
  height: 42px;
  border-radius: 3px;
  border: solid 2px ${(props) => props.theme.colors.accent};
  font-size: 18px;
  font-weight: 600;
  line-height: 1.06;
  text-align: center;
  color: ${(props) => props.theme.colors.accent};
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Nunito Sans';
  cursor: pointer;
  :hover {
    background-color: #fff5e5;
  }

  ${media.max.sm`
    width: 100%;
  `}
  ${media.max.xs`
    margin-bottom: 5px;`}
`;

interface IProps {
  onClick?: () => any;
  label: string;
  disabled?: boolean;
}

const MarketplaceViewButton: React.FC<IProps> = ({
  onClick,
  label,
  disabled,
}) => {
  return (
    <ViewButton onClick={disabled ? undefined : onClick}>{label}</ViewButton>
  );
};
export default MarketplaceViewButton;
