import { ConditionalContainer } from 'app/components/misc/conditional-container';
import GetStarted from 'app/modules/auth/get-started';
import LoginForm from 'app/modules/auth/login-form';
import Modal from 'app/components/modals/overrided-modal';
import { _, media } from 'app/styles/breakpoint';
import colors from 'app/styles/colors';
import React from 'react';
import styled from 'styled-components';

const ModalContainer = styled.div`
  margin: 15px;
  z-index: 100;
  padding-top: 0px;
  align-items: flex-start;
  ${_(media.max.lg)} {
    padding-top: 0px;
    margin: 5px;
  }
  ${_(media.max.md)} {
    margin: 0px;
  }
  ${_(media.max.sm)} {
    margin: 0px;
  }
`;

const IconContainer = styled.div`
  width: 100%;
  text-align: right;
  position: relative;
  > div {
    position: absolute;
    margin: 0 auto;
    right: 30px;
    top: 10px;

    ${_(media.max.xs)} {
      right: 10px;
      top: 0px;
    }
  }
`;

const CloseIcon = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: fixed;
`;

interface Props {
  closeModal: () => any;
  isOpen: boolean;
  isLanding?: boolean;
}
const customStyles = {
  content: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderWidth: 0,
    borderRadius: 6,
    boxShadow: colors.modalShadow,
    overflow: 'auto',
    position: 'static',
    margin: 'auto',
    maxHeight: '90%',
  },
  overlay: {
    backgroundColor: colors.darkModalOverlay,
    zIndex: 999,
    display: 'flex',
  },
};

const MarketplaceGetStartedModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  isLanding,
}) => {
  const [register, setRegister] = React.useState(true);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles as any}
      contentLabel="Get started"
      ariaHideApp={false}
    >
      <IconContainer>
        <div onClick={closeModal}>
          <CloseIcon src={'/img/close.svg'} />
        </div>
      </IconContainer>
      <ModalContainer>
        <ConditionalContainer show={register}>
          <GetStarted
            isModal={true}
            isLanding={isLanding}
            haveAccountCallback={() => setRegister(false)}
          />
        </ConditionalContainer>
        <ConditionalContainer show={!register}>
          <LoginForm
            isModal={true}
            modalSingupCallback={() => setRegister(true)}
          />
        </ConditionalContainer>
      </ModalContainer>
    </Modal>
  );
};

export default MarketplaceGetStartedModal;
