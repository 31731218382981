import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import User from '../atoms/icons/User';
import ChevronRight from '../atoms/icons/ChevronRight';
import MenuLink from './MenuLink';
import { ActiveLinkData } from './types';
import { MenuSublinks } from './MenuSublink';
import { logout } from 'app/store/modules/auth/actions';

// Types

export interface MenuDesktopProps {
  /**
   * What is the link data?
   */
  links: ActiveLinkData[];
  /**
   * Is the user logged in?
   */
  isLoggedIn: boolean;
  /**
   * User first name if logged in
   */
  firstName?: string;
  /**
   * class name of the component
   */
  className?: string;
}

const Links = styled.nav`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 20px;
  align-items: center;
`;

const MiscLinks = styled.div`
  display: grid;
  grid-template-columns: repeat(2, min-content);
  align-items: center;
`;

const RawLink = styled.span`
  cursor: pointer;
  padding: 0;
  font-weight: 400;
  width: max-content;
`;

const Desktop = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const AnchorButtonBase = styled.a`
  display: grid;
  column-gap: 8px;
  grid-auto-columns: min-content;
  grid-auto-flow: column;
  justify-content: center;
  align-items: center;
  border-width: 2px;
  border-style: solid;
  border-radius: 8px;
  cursor: pointer;
  white-space: nowrap;
  text-decoration: none;
  user-select: none;
  outline: none;
  transition: all 300ms ease 0s;
  padding: 6px 16px;
  font-size: 16px;
  line-height: 1.75;
  font-weight: 700;
  border-color: ${(props) => props.theme.colors.tertiaryMain};
  background-color: ${(props) => props.theme.colors.tertiaryMain};
  color: ${(props) => props.theme.colors.white};
  pointer-events: initial;
  & > svg {
    fill: ${(props) => props.theme.colors.white};
  }
`;
const AnchorButton = styled(AnchorButtonBase as any)`
  margin-left: 24px;
`;

const MenuDesktop: React.FC<MenuDesktopProps> = ({
  links,
  isLoggedIn,
  firstName = '',
  className = '',
}) => {
  const dispatch = useDispatch();

  return (
    <Desktop className={className}>
      <Links>
        {links.length > 0 &&
          links?.map(({ sublinks, link, ...linkData }, index) =>
            sublinks ? (
              <MenuSublinks
                key={`${link}${index}`}
                label={<RawLink>{linkData.text}</RawLink>}
                {...linkData}
              >
                {sublinks.map(
                  ({ link: sublink, text: sublinkText, ...sublinkData }) => (
                    <MenuLink
                      key={sublinkText}
                      link={sublink as string}
                      {...sublinkData}
                    >
                      {sublinkText}
                    </MenuLink>
                  )
                )}
              </MenuSublinks>
            ) : (
              <MenuLink
                key={`${link}${index}`}
                {...linkData}
                link={link as string}
              >
                {linkData.text}
              </MenuLink>
            )
          )}
      </Links>
      <MiscLinks>
        {isLoggedIn ? (
          <MenuSublinks
            label={
              <>
                <User />
                <RawLink>{firstName}</RawLink>
              </>
            }
            isRight
          >
            <MenuLink link="/users-settings" active={false} isSublink>
              Settings
            </MenuLink>
            <MenuLink active={false} onClick={() => dispatch(logout())}>
              Logout
            </MenuLink>
          </MenuSublinks>
        ) : (
          links.length > 0 && (
            <>
              <MenuLink link="/login" force active={true}>
                Sign In
              </MenuLink>
              <AnchorButton href="/get-started">
                Get Started <ChevronRight />
              </AnchorButton>
            </>
          )
        )}
      </MiscLinks>
    </Desktop>
  );
};

export default MenuDesktop;
