import ProductItem from 'app/modules/marketplace/components/marketplace-list/components/product-item';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { IProps } from './index';

const TopProductsContainer = styled.div`
  margin-bottom: 100px;
`;

const ProductContainer: React.FC<IProps> = ({
  type,
  productList,
  requestFetchTopProduct,
  isAdmin,
}) => {
  useEffect(() => {
    requestFetchTopProduct();
  }, [isAdmin]);
  return (
    <TopProductsContainer>
      {productList.map((x, index) => {
        return (
          <ProductItem
            key={index}
            listItem={x}
            isMarketplace={true}
            isSeo={true}
            deleteModal={() => {
              //
            }}
          />
        );
      })}
    </TopProductsContainer>
  );
};

export default ProductContainer;
