import { _, media } from 'app/styles/breakpoint';
import React from 'react';
import styled from 'styled-components';

const MarketingBubbleWrapper = styled.div<{
  isModal?: boolean;
}>`
  border-radius: 17.5px;
  background-color: ${(props) => props.theme.colors.accentLight};
  padding: 4px 10px;
  width: fit-content;
  display: flex;
  margin-top: 10px;
  ${(props) => !props.isModal && `margin-right: 15px;`}
  text-align: left;
  align-items: center;

  ${_(media.max.sm)} {
    margin-right: 0px;
    text-align: left;
    align-items: left;
  }
`;

const MarketingMessage = styled.div`
  font-size: 13px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  color: ${(props) => props.theme.colors.accent};
`;

export const MarketingSectionWrapper = styled.div<{ isModal?: boolean }>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  ${(props) => props.isModal && `flex-direction: column;`}

  ${_(media.max.sm)} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const MarketingBubble: React.FC<{
  isModal?: boolean;
}> = ({ children, isModal }) => (
  <MarketingBubbleWrapper isModal={isModal}>
    <MarketingMessage>{children}</MarketingMessage>
  </MarketingBubbleWrapper>
);
