import React from 'react';
import { CSSTransition } from 'react-transition-group';

import type { AnimateComponent } from './Animate.types';

const Animate: AnimateComponent = ({ duration = '300ms', ...restProps }) => {
  const timeout = 300;

  return <CSSTransition {...restProps} timeout={timeout} />;
};

export { Animate };
