import styled from 'styled-components';

// custom imports
import { Components as LoaderC } from 'app/components/loader';
import { _, media } from 'app/styles/breakpoint';

export const SuggestAlternativeSellersContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.background};
  ${_(media.max.xs)} {
    padding: 35px 20px 90px 20px;
  }
  ${_(media.min.sm)} {
    padding: 38px 27px 30px 31px;
  }
  ${_(media.min.tablet)} {
    padding: 53px 27px 30px 31px;
  }
  ${_(media.min.md)} {
    padding: 53px 35px 30px 40px;
  }
  ${LoaderC.SpinnerWrap} {
    background-color: inherit;
  }
`;

export const ButtonsContainer = styled.div`
  bottom: 30px;
  background-color: ${(props) => props.theme.colors.white};
  max-width: 960px;
  height: 93px;
  border-radius: 0 0 6px 6px;
  box-shadow: 0 2px 19px 0 rgba(143, 162, 191, 0.25);
  border: solid 0.8px rgba(201, 209, 219, 0.11);
  display: flex;
  justify-content: space-between;
  position: fixed;
  ${_(media.max.xs)} {
    width: 100%;
  }
  ${_(media.min.sm)} {
    width: calc(100% - 40px);
  }
`;

export const SkipButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  ${_(media.max.xs)} {
    margin: 8px 5px 2px 15px;
    flex-grow: 1;
  }
  ${_(media.min.sm)} {
    margin: 20px 5px 23px 20px;
    flex-grow: 1;
  }
  ${_(media.min.md)} {
    margin: 20px 44px 23px 44px;
  }
`;

export const SendButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  ${_(media.max.xs)} {
    margin: 32px 15px 12px 5px;
    flex-grow: 1;
  }
  ${_(media.min.sm)} {
    margin: 20px 15px 23px 5px;
    flex-grow: 1;
  }
  ${_(media.min.md)} {
    margin: 20px 44px 23px 44px;
  }
`;

export const Header = styled.div`
  min-height: 93px;
  display: flex;
  flex-direction: column;
`;

export const Heading = styled.div<{ isAuthenticated: boolean }>`
  ${(props) => props.isAuthenticated && 'margin-top: 23px;'};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.grayExtraDark};
  ${_(media.max.sm)} {
    font-size: 22px;
  }
  ${_(media.min.tablet)} {
    font-size: 24px;
  }
  ${_(media.min.md)} {
    font-size: 26px;
  }
`;

export const Subheading = styled.div`
  margin: 10px 0;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.grayExtraDark};
  ${_(media.max.xs)} {
    font-size: 16px;
    line-height: 1.33;
  }
  ${_(media.min.sm)} {
    font-size: 16px;
    line-height: 1.63;
  }
`;

export const SelectedText = styled.div`
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.grayText};
  min-width: 74px;
  ${_(media.max.xs)} {
    margin: 1px 0;
    font-size: 14px;
    line-height: 1.63;
  }
  ${_(media.min.sm)} {
    margin: 12px 20px;
    font-size: 16px;
    line-height: 1.63;
  }
  ${_(media.min.md)} {
    margin: 12px 20px;
    font-size: 16px;
    line-height: 1.63;
  }
`;
