import { setGetStartedModalOpen } from 'app/store/modules/auth/actions';
import { IStore } from 'app/store/types';
import { connect } from 'react-redux';
import MarketplaceGetStartedModal from './marketplace-get-started-modal';

export default connect(
  (state: IStore) => {
    return {
      isOpen: state.auth.getStartedModalOpen && !state.auth.resetPasswordModal,
    };
  },
  (dispatch) => {
    return {
      closeModal: () => dispatch(setGetStartedModalOpen(false)),
    };
  }
)(MarketplaceGetStartedModal);
