import styled from 'styled-components';

// custom imports
import { media } from 'app/styles/breakpoint';

export const Container = styled.div<{
  markUserSelfManagedItemForAdmin?: boolean;
}>`
  position: relative;
  width: 100%;
  padding: 26px 30px;
  box-shadow: ${(props) =>
    props.theme.colors.boxShadowParam + ' ' + props.theme.colors.filtersShadow};
  background-color: ${(props) => props.theme.colors.white};
  ${(props) =>
    props.markUserSelfManagedItemForAdmin
      ? `background-color: ${props.theme.colors.adminCreatedBackground};`
      : `background-color: ${props.theme.colors.white};`}
  border-radius: 3px;
  margin-bottom: 15px;
  margin-right: 15px;
  ${media.max.sm`
padding-left:5%;
padding-right:5%;
margin-right: 0;

`}
  .company-link {
    color: ${(props) => props.theme.colors.grayExtraDark};
  }
`;

export const Header = styled.div`
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.colors.grayLight};
  padding-bottom: 18px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  ${media.max.sm`
`}
`;

export const ProductNameContainer = styled.div<{ isClickable?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${(props) => props.isClickable && 'cursor: pointer;'}
`;

export const ProductHeader = styled.div`
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  color: ${(props) => props.theme.colors.grayExtraDark};
  margin-bottom: 1px;
  margin-top: 0;
  display: flex;
  align-items: flex-start;
`;

export const ProductSubHeader = styled.h3`
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: ${(props) => props.theme.colors.grayText};
  margin: 0;
  width: 100%;
`;

export const ButtonLine = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  min-width: 286px;
  justify-content: flex-end;
  ${media.max.sm`
display:none;
`}
`;

export const ButtonLineView = styled.div`
  display: flex;
  min-width: 290px;
  ${media.max.sm`
display:none;
`}
`;

export const ButtonDivider = styled.div`
  width: 5px;
  ${media.max.sm`
width: 20px`}
`;

export const ShowMobile = styled.div`
  display: none;
  ${media.max.sm`
display:flex;
flex-wrap:wrap;
margin-top: 18px;
`}
`;

export const ShowMobileView = styled.div`
  display: none;
  ${media.max.sm`
display:flex;
margin-top: 18px;
`}
  ${media.max.xs`
display: block;`}
`;

export const ProductAllInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const ProductInfoContainer = styled.div`
  min-width: 50%;
  max-width: 50%;
  ${media.max.sm`
width: 100%;
max-width: 100%;
`}
`;

export const ProductInfoFullWidthContainer = styled.div`
  min-width: 100%;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid ${(props) => props.theme.colors.grayLight};
  ${media.max.sm`
width: 100%;
`}
`;

export const DisabledLink = styled.div`
  cursor: default;
  opacity: 0.3;
`;
export const LinkWrap = styled.div`
  ${media.max.sm`width: 100%;`}
`;

export const YouGotDealForThisWrapper = styled.div`
  width: 100%;
`;

export const YouGotDealForThisRequest = styled.div`
  position: absolute;
  font-family: 'Nunito Sans';
  min-width: 260px;
  opacity: 0.84;
  border-radius: 2px;
  top: 7px;
  right: 0px;
  margin-right: 30px;
  margin-left: 30px;
  ${media.max.sm`
position: relative;
margin-right: 0px;
margin-left: 0px;
top: 0px;
margin-bottom: 4px;
width: fit-content;
`}
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.5;
  color: ${(props) => props.theme.colors.grayDark};
  background-color: ${(props) => props.theme.colors.primaryLight};
`;

export const YouGotDealForThisRequestIcon = styled.img`
  width: 11px;
  height: 11px;
  object-fit: contain;
  margin-right: 3px;
`;
