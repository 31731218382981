import React from 'react';

const ChevronRight: React.FC = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="#186CE8" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.502 17a1 1 0 01-.71-1.71l3.31-3.29-3.18-3.31a1 1 0 011.414-1.416l.006.006 3.86 4a1 1 0 010 1.4l-4 4a1 1 0 01-.7.32z"
      fill="inherit"
    />
  </svg>
);

export default ChevronRight;
