import React from 'react';
import { Helmet } from 'react-helmet-async';

interface SEOMetaProps {
  metaTitle: string;
  canonical?: string;
}

const productionRootUrl = 'https://www.pipelinepharma.com';

export const SetSeoMetaTitle: React.FC<SEOMetaProps> = ({
  metaTitle,
  canonical,
}) => {
  return (
    <Helmet>
      <title>{metaTitle}</title>
      <meta property="og:title" content={metaTitle} />
      {canonical && (
        <link rel="canonical" href={productionRootUrl + canonical} />
      )}
    </Helmet>
  );
};

export default SetSeoMetaTitle;
