import { media } from 'app/styles/breakpoint';
import React, { useState } from 'react';
import styled from 'styled-components';

const NotificationContainer = styled.div<{ marginBottom?: string }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  border-radius: 3px;
  box-shadow: 0 8px 24px 0 ${(props) => props.theme.colors.notificationShadow};
  border: solid 1px ${(props) => props.theme.colors.warningNotificationBorder};
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : '0px'};
  position: relative;
  background-color: ${(props) => props.theme.colors.white};
`;

const NotificationIconContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 3px;
  background-color: ${(props) =>
    props.theme.colors.warningNotificationIconBackground};
  ${media.max.sm`
  background-color: ${(props) =>
    props.theme.colors.warningNotificationIconBackground};
`}
`;

const NotificationTextContainer = styled.div<{ haveText: boolean }>`
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.warningNotificationText};
  text-align: left;
  ${(props) => props.haveText && `margin-bottom: 9px;`}
  ${media.max.sm`
  padding-left: 0px;
  `}
  ${media.max.xs`
  font-size: 14px;
  margin: 0;
  `}
`;

const NotificationTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px;
  align-items: flex-start;
`;
const NotificationTextTop = styled.div`
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.44;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.warningNotificationText};
`;

const NotificationTextInfo = styled.p`
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.grayExtraDark};
  margin: 0;
  ${media.max.sm`
  padding: 15px;
    `}
  ${media.max.xs`
  font-size: 12px;
  padding: 12px;
  `}
`;

const MobileNotification = styled.div`
  background-color: ${(props) =>
    props.theme.colors.warningNotificationIconBackground};
  display: flex;
  padding: 15px;
  align-items: center;
  ${media.max.xs`
  padding: 12px;
  `}
`;

const NotificationIcon = styled.img`
  width: 26.1px;
  height: 26.1px;
  object-fit: contain;
`;

const NotificationIconWrapper = styled.div`
  width: 26.1px;
  height: 26.1px;
  ${media.max.sm`
margin-right: 15px;
  `}
`;

const NotificationCloseIcon = styled.img`
  width: 16px;
  height: 16px;
  object-fit: contain;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

const ShowMobile = styled.div`
  display: none;
  ${media.max.sm`
  display:flex;
  width: 100%;
  flex-direction:column;
`}
`;

const HideMobile = styled.div`
  display: flex;
  flex-direction: row;
  ${media.max.sm`
  display:none;
`};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

interface Props {
  label: string;
  marginBottom?: string;
  text?: string;
  closable?: boolean;
  hideHeading?: boolean;
  className?: string;
  onHideClick?: () => any;
}
const Notification: React.FC<Props> = ({
  label,
  marginBottom,
  text,
  closable = true,
  hideHeading = false,
  className,
  onHideClick,
}) => {
  const [visible, setVisible] = useState(true);
  if (visible) {
    return (
      <NotificationContainer marginBottom={marginBottom} className={className}>
        <HideMobile>
          <NotificationIconContainer>
            <NotificationIconWrapper>
              <NotificationIcon src={'/img/notifications/warning.svg'} />
            </NotificationIconWrapper>
          </NotificationIconContainer>
          <NotificationTextWrapper>
            {!hideHeading && <NotificationTextTop>warning</NotificationTextTop>}
            <NotificationTextContainer
              haveText={text !== undefined && text !== ''}
            >
              {label}
            </NotificationTextContainer>
            {text && text !== '' && (
              <NotificationTextInfo> {text} </NotificationTextInfo>
            )}
          </NotificationTextWrapper>
        </HideMobile>
        <ShowMobile>
          <MobileNotification>
            <NotificationIconWrapper>
              <NotificationIcon src={'/img/notifications/warning.svg'} />
            </NotificationIconWrapper>
            <Wrapper>
              {!hideHeading && (
                <NotificationTextTop>warning</NotificationTextTop>
              )}
              <NotificationTextContainer
                haveText={text !== undefined && text !== ''}
              >
                {label}
              </NotificationTextContainer>
            </Wrapper>
          </MobileNotification>
          {text && text !== '' && (
            <NotificationTextInfo> {text} </NotificationTextInfo>
          )}
        </ShowMobile>
        {closable && (
          <NotificationCloseIcon
            src={'/img/notifications/close.svg'}
            onClick={() => {
              setVisible(false);
              if (onHideClick) {
                onHideClick();
              }
            }}
          />
        )}
      </NotificationContainer>
    );
  } else {
    return <div />;
  }
};

export const Components = {
  NotificationContainer,
  NotificationIconContainer,
  NotificationTextContainer,
  NotificationTextWrapper,
  NotificationTextTop,
  NotificationTextInfo,
  MobileNotification,
  NotificationIcon,
  NotificationIconWrapper,
  NotificationCloseIcon,
  ShowMobile,
  HideMobile,
  Wrapper,
};
export default Notification;
