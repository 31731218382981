import { routes } from 'app/config';
import { toTitleCase } from 'app/store/modules/global/actions';
import { IStore } from 'app/store/types';
import { media } from 'app/styles/breakpoint';
import React from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const BreadcrumContainer = styled.div<{ noMargin: boolean }>`
  width: 100%;
  background-color: ${(props) => props.theme.colors.background};
  width: 100%;

  padding-left: 10%;
  padding-right: 10%;

  ${media.max.md`
    padding-left: 5%;
    padding-right: 5%;
  `}

  ${(props) =>
    props.noMargin &&
    `
    padding-left: 0;
    padding-right: 0;
  ${media.max.md`
    padding-left: 5%;
    padding-right: 5%;
  `}

`};
  ${media.min.lg`
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0px;
  padding-right: 0px;
`}
`;

const Breadcrum = styled.div`
  padding-top: 25px;
  padding-bottom: 20px;
  min-height: 26px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  ${media.max.sm`
  padding-bottom: 5px;
  `}
`;

const BreadcrumHome = styled.div`
  font-size: 14px;
  line-height: 26px;
  color: ${(props) => props.theme.colors.muted};
  margin-right: 10px;
  cursor: pointer;
`;

const RightChevron = styled.img`
  width: 4px;
  height: 7px;
  object-fit: contain;
  margin-bottom: 2px;
  margin-right: 10px;
  display: inline;
`;

const CurrentLink = styled.div`
  font-size: 14px;
  line-height: 26px;
  color: ${(props) => props.theme.colors.grayExtraDark};
  cursor: pointer;
`;

interface Props {
  currPage?: string;
  noMargin?: boolean;
  client: any;
  onRouteClick: (route: string, client: any) => any;
}

const Breadcrumbs: React.FC<Props> = ({
  currPage,
  onRouteClick,
  noMargin = false,
  client,
}) => {
  const haveSpace = currPage && currPage.indexOf(' +') > 0;
  const indexOfSecond = currPage
    ? haveSpace
      ? currPage.indexOf(' +')
      : undefined
    : undefined;
  let currPageShort = currPage ? currPage.substr(0, indexOfSecond) : '';
  const route = useRouteMatch();
  const product = useSelector((x: IStore) => x.marketplace.filter.product);
  if (product && product !== '') {
    currPageShort = product;
  }

  return (
    <BreadcrumContainer noMargin={noMargin}>
      <Breadcrum>
        <BreadcrumHome>
          <Link to={routes.home}>Home</Link>
        </BreadcrumHome>
        <RightChevron src="/img/rightChevron.png" />
        <BreadcrumHome onClick={() => onRouteClick(routes.marketplace, client)}>
          <Link to={routes.marketplace}>Products for sale</Link>
        </BreadcrumHome>
        {route &&
          route.path !== routes.marketplaceSearch &&
          route.path !== routes.marketplace &&
          currPageShort !== '' && (
            <BreadcrumHome>
              <RightChevron src="/img/rightChevron.png" />
              <Link
                to={
                  '/' +
                  currPageShort.replace(' ', '-').toLowerCase() +
                  '-manufacturers'
                }
              >
                {currPageShort && toTitleCase(currPageShort)} manufacturers
              </Link>
            </BreadcrumHome>
          )}
        {route &&
          route.path !== routes.marketplaceSearch &&
          route.path !== routes.marketplace &&
          currPage !== '' && (
            <>
              <RightChevron src="/img/rightChevron.png" />
              <CurrentLink>{currPage}</CurrentLink>
            </>
          )}
        {route &&
          route.path === routes.marketplaceSearch &&
          product !== '' &&
          currPage !== '' && (
            <>
              <RightChevron src="/img/rightChevron.png" />
              <CurrentLink>
                {currPage && toTitleCase(currPage)} manufacturers{' '}
              </CurrentLink>
            </>
          )}
      </Breadcrum>
    </BreadcrumContainer>
  );
};

export default Breadcrumbs;
