import ToolTipOverlay from 'app/components/tooltip/tooltip-overlay';
import React from 'react';
// eslint-disable-next-line
// import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
const Wrapper = styled.div<{ marketingMessageExist: boolean }>`
  /*
  width: 97px;
  height: 23px;
  */
  border-radius: 3px;
  margin-right: 10px;
  ${(props) =>
    props.marketingMessageExist ? `margin-top: 10px;` : `margin-top: 5px;`}
  background-color: ${(props) => props.theme.colors.primary};
  cursor: pointer;
  .tooltip-style {
    width: 305px;
    border: solid 1px ${(props) => props.theme.colors.grayLight} !important;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.18px;
    color: ${(props) => props.theme.colors.grayExtraDark};
  }
  .place-top::before {
    border-top: 8px solid ${(props) => props.theme.colors.grayLight} !important;
  }
`;
const FastTrackContainer = styled.div`
  object-fit: contain;
  cursor: pointer;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4.5px;
  padding-bottom: 4.5px;
  display: flex;
  align-items: center;
  .fast-track-image {
    width: 20px;
    height: 13px;
    object-fit: contain;
    margin-left: 4px;
  }
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.white};
`;

const tooltipText =
  'This product is available through our fast-track program with online negotiation.';
interface OwnProps {
  marketingMessageExist: boolean;
}

const showInProduction = true;

const FastTrack: React.FC<OwnProps> = ({ marketingMessageExist }) => {
  if (showInProduction) {
    return (
      <Wrapper marketingMessageExist={marketingMessageExist}>
        <ToolTipOverlay tooltip={tooltipText}>
          <FastTrackContainer>
            FAST TRACK
            <img className={'fast-track-image'} src={'/img/fast-track.svg'} />
          </FastTrackContainer>
        </ToolTipOverlay>
      </Wrapper>
    );
  } else {
    return <div />;
  }
};

export default FastTrack;
