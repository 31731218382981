import {
  setRequestExistsLink,
  setRequestExistsModalOpen,
} from 'app/store/modules/requests/actions';
import { IStore } from 'app/store/types';
import { connect } from 'react-redux';
import DealExistsModal from './deal-exists-modal';

interface IStateProps {
  isOpen: boolean;
  link: string;
}
interface IDispatchProps {
  closeModal: () => void;
}
export interface IProps extends IStateProps, IDispatchProps {}

export default connect<IStateProps, IDispatchProps, any, IStore>(
  (state): IStateProps => {
    return {
      isOpen: state.requests.dealExistsModalOpen,
      link: state.requests.dealExistsLink,
    };
  },
  (dispatch) => {
    return {
      closeModal: () => {
        dispatch(setRequestExistsModalOpen(false));
        dispatch(setRequestExistsLink(''));
      },
    };
  }
)(DealExistsModal);
