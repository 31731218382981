import GetStarted from './get-started';

import { routes } from 'app/config';
import {
  getStarted,
  linkedinSuccess,
  setRegisterError,
} from 'app/store/modules/auth/actions';
import { IWithApolloClient } from 'app/store/modules/global/types';
import { IStore } from 'app/store/types';
import { push } from 'connected-react-router';
import { withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface IDispatchProps {
  toLogIn: () => any;
  register: (password: string, businessEmail: string, isModal?: boolean) => any;
  clearRegisterError: () => any;
  linkedinSuccessAction: () => any;
}
interface IStateProps {
  error: string;
  isOpen: boolean;
}
interface IOwnProps {
  isModal?: boolean;
  haveAccountCallback?: () => void;
  isLanding?: boolean;
}
type ComposedProps = IOwnProps & IWithApolloClient & RouteComponentProps;
export type IProps = IStateProps &
  IDispatchProps &
  ComposedProps &
  RouteComponentProps;
export default withApollo<IOwnProps>(
  withRouter(
    connect<IStateProps, IDispatchProps, ComposedProps, IStore>(
      (state) => ({
        error: state.auth.registerError,
        isOpen: state.auth.registerModalOpen,
      }),
      (dispatch, ownProps) => ({
        register: (password, businessEmail, isModal?) => {
          return dispatch(
            getStarted({
              password,
              businessEmail,
              isModal,
              client: ownProps.client,
            })
          );
        },
        toLogIn: () => dispatch(push(routes.login)),
        clearRegisterError: () => dispatch(setRegisterError('')),
        linkedinSuccessAction: () =>
          dispatch(linkedinSuccess({ client: ownProps.client })),
      })
    )(GetStarted)
  )
);
