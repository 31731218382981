/** Self-controlled Select input */
import * as React from 'react';
import onClickOutside from 'react-onclickoutside';

// custom imports
import { sendSearchToIntercom } from 'app/components/search/helpers';
import ToolTip from 'app/components/tooltip/tooltip-component';
import {
  ConditionalContainer,
  ErrorIcon,
  ErrorLine,
  ErrorMessage,
  Input,
  LabelStyle,
  Option,
  OptionsContainer,
  SelectInputContainer,
} from './styles';

interface IProps {
  value: string;
  label?: string;
  autocompleteValues: ISelectItem[];
  applyStringFilter?: boolean;
  amountOfVisibleOptions?: number;
  placeholder?: string;
  onChange?: (value: string, option?: ISelectItem) => void;
  onKeyPress?: any;
  onFocus?: () => void;
  onBlur?: () => void;
  error?: string;
  id?: string;
  tooltip?: string;
  autocomplete?: string;
}
interface IState {
  value: string;
  focused: boolean;
}

class SearchInputT extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);

    this.state = {
      value: props.value,
      focused: false,
    };

    this.handleOnChange = this.handleOnChange.bind(this);
    this.applyStringFilter = this.applyStringFilter.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleClickOutsideFun = this.handleClickOutsideFun.bind(this);
  }
  public handleClickOutsideFun(evt: any) {
    // ..handling code goes here...

    this.setState({ focused: false });
  }
  /** Listening for click outside of select component */
  public componentDidUpdate() {
    if (
      this.props.value !== this.state.value &&
      this.props.value === ''
      /*&&
      this.state.value.length > 1*/
    ) {
      this.setState({ value: '' });
    }
    if (this.props.value !== this.state.value && this.props.value.length > 0) {
      this.setState({ value: this.props.value });
    }
  }
  public render(): JSX.Element {
    const newOp = this.props.applyStringFilter
      ? this.applyStringFilter(this.state.value, this.props.autocompleteValues)
      : this.props.autocompleteValues
      ? this.props.autocompleteValues.slice(
          0,
          this.props.amountOfVisibleOptions
        )
      : [];
    const optionsContainerVisibility =
      this.state.focused && !!this.state.value.length && !!newOp.length;
    const substringAmount = 55;
    return (
      <SelectInputContainer onClick={(e) => e.stopPropagation()}>
        {this.props.label && (
          <LabelStyle>
            {this.props.label}

            {this.props.tooltip && <ToolTip tooltip={this.props.tooltip} />}
          </LabelStyle>
        )}
        <Input
          id={this.props.id}
          className="search-input-element-class"
          data-testid="search-input"
          placeholder={this.props.placeholder ? this.props.placeholder : ''}
          value={this.state.value}
          onChange={(e) => this.handleOnChange(e.target.value, undefined)}
          onFocus={this.handleFocus}
          onBlur={this.props.onBlur}
          autoComplete={this.props.autocomplete}
          onKeyPress={(e) => {
            if (this.props.onKeyPress) {
              this.props.onKeyPress(e);
            }
            if (e.key === 'Enter') {
              this.setState({ focused: false });
            }
          }}
        />
        <ConditionalContainer show={optionsContainerVisibility}>
          <OptionsContainer visibleBorder={optionsContainerVisibility}>
            {newOp.map((value, index) => (
              <Option
                key={index}
                onClick={() => {
                  sendSearchToIntercom(value.label);
                  this.handleOnChange(value.label, value);
                  this.handleBlur();
                }}
              >
                <p tabIndex={-1}>
                  {value && value.label && value.label.length > substringAmount
                    ? value.label.substr(0, substringAmount) + '...'
                    : value.label}
                </p>
              </Option>
            ))}
          </OptionsContainer>
        </ConditionalContainer>
        {this.props.error !== undefined && this.props.error.length > 0 && (
          <ErrorLine>
            <ErrorMessage>{this.props.error}</ErrorMessage>
            <ErrorIcon src={'/img/formError.png'} />
          </ErrorLine>
        )}
      </SelectInputContainer>
    );
  }
  /** Handles focusing of input field, to show options container. */
  private handleFocus(): void {
    if (this.props.onFocus) {
      this.props.onFocus();
    }
    this.setState({ focused: true });
  }

  /** Handles input changes and subscribes them to onChange function. */

  private handleOnChange(value: string, option?: ISelectItem): void {
    this.setState(
      { value },
      () => this.props.onChange && this.props.onChange(value, option)
    );
  }
  /** Hides options container. */
  private handleBlur(): void {
    this.setState({ focused: false });
  }

  /** Filters given options by inserted value. */
  private applyStringFilter(
    key: string,
    options?: ISelectItem[]
  ): ISelectItem[] {
    const newOptions: ISelectItem[] = [];

    if (options) {
      for (const option of options) {
        if (
          this.props.amountOfVisibleOptions &&
          newOptions.push(option) >= this.props.amountOfVisibleOptions
        ) {
          break;
        }
      }
    }
    return newOptions;
  }
}

const clickOutsideConfig = {
  handleClickOutside(instance: any) {
    return instance.handleClickOutsideFun;
  },
};
const SearchInput = onClickOutside(SearchInputT, clickOutsideConfig);

const Components = { SelectInputContainer, Input, OptionsContainer, Option };
export { SearchInput, Components };
export default SearchInput;
