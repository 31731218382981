import { GAActions, GACategory, ReactGA } from 'client/tracker';
import OrangeButton from 'app/components/buttons/orange-button';
import Radio from 'app/components/inputs/radio';
import Select from 'app/components/inputs/select';
import TextInput from 'app/components/inputs/text-input';
import { Company } from 'app/store/modules/auth/types';
import { ICreateProductDealAction } from 'app/store/modules/requests/actions';
import { toTitleCase } from 'app/store/modules/global/actions';
import {
  expectSignContractOptions,
  requestDealTypesEnum,
} from 'app/store/modules/global/types';
import { allCountries as countries } from 'app/store/modules/global/types';
import { getProductSubstanceName } from 'app/store/modules/products/actions';
import { Product } from 'app/store/modules/products/types';
import { qualificationSettingsFilled } from 'app/utils/qualification-settings-filled';
import sg from 'app/utils/safe-get';
import { anonymousUserId } from 'app/config';
import React, { useState } from 'react';
import {
  ButtonLine,
  ButtonLineItem,
  DealTypeContainer,
  DealTypeLabel,
  ErrorMessage,
  Header,
  InputContainer,
  ProposalContainer,
  ProposalTextContainer,
  RequestFormContainer,
} from './styles';
import { useSelector } from 'app/helpers/redux';
import isBrowser from 'app/store/utils/is-browser';

export const setDealTypeErrorMessage = 'Deal type is required';
export const setMarketOfInterestErrorMessage =
  'Market(s) of interest is required';
export const setEstimateAnualPurchQuantityErrorMessage =
  'Estimated annual purchase quantity is required';

export const expectSignContractErrorMessage = 'This field is required';

interface Props {
  submit: (fields: ICreateProductDealAction, modalShown: boolean) => any;
  product?: Product;
  client: any;
  userCompany?: Company;
}
const maxTextLen = 250;
const RequestForm: React.FC<Props> = ({
  submit,
  product,
  client,
  userCompany,
}) => {
  const [modalShown, setModalShown] = React.useState(false);
  const [marketOfInterest, setMarketOfInterest] = useState('');
  const [marketOfInterestError, setMarketOfInterestError] = useState('');
  const [marketOfInterestSelect, setMarketOfInterestSelect] = useState<
    ISelectItem[]
  >([]);
  const [estimateAnualPurchQuantity, setEstimateAnualPurchQuantity] = useState(
    ''
  );

  const [
    estimateAnualPurchQuantityError,
    setEstimateAnualPurchQuantityError,
  ] = useState('');
  const [
    leadingCompetitorPriceLevel,
    setLeadingCompetitorPriceLevel,
  ] = useState('');
  const [comments, setComments] = useState('');
  const [dealType, setDealType] = useState(0);
  const [dealTypeError, setDealTypeError] = useState('');
  const [expectSignContract, setExpectSignContract] = useState<ISelectItem>({
    value: '',
    label: 'Please select',
  });
  const [expectSignContractError, setExpectSignContractError] = useState('');

  const email = useSelector((state) => state.auth?.loggedInUser?.email);
  const anonymousUserIdItem =
    isBrowser() && localStorage.getItem(anonymousUserId);

  const validate = () => {
    let valid = true;
    if (dealType === 0) {
      setDealTypeError(setDealTypeErrorMessage);
      valid = false;
    }
    if (marketOfInterestSelect.length === 0 && product) {
      setMarketOfInterestError(setMarketOfInterestErrorMessage);
      valid = false;
    }
    if (estimateAnualPurchQuantity === '' && product) {
      setEstimateAnualPurchQuantityError(
        setEstimateAnualPurchQuantityErrorMessage
      );

      valid = false;
    }
    if (expectSignContract.value === '' && product) {
      setExpectSignContractError(expectSignContractErrorMessage);
      valid = false;
    }

    return valid;
  };
  const productName = product
    ? toTitleCase(getProductSubstanceName(product))
    : '';
  const productFormDose = product
    ? `${product.formSpecification} ${product.strength}`
    : ``;

  React.useEffect(() => {
    if (userCompany) {
      setModalShown(qualificationSettingsFilled(userCompany));
    }
  }, [userCompany]);

  return (
    <RequestFormContainer>
      <Header>
        Fill in your requirements to receive a commercial offer for{' '}
        {productName} {productFormDose}
      </Header>
      <form>
        <ProposalTextContainer>
          <ProposalContainer>
            {product && (
              <DealTypeContainer>
                <DealTypeLabel>Choose deal type</DealTypeLabel>
                <ErrorMessage>{dealTypeError}</ErrorMessage>
                <Radio
                  className={'deal-type-radio-item'}
                  id={
                    'deal-type-radio-item' +
                    requestDealTypesEnum.LicensingWithSupply.toString()
                  }
                  value={dealType.toString()}
                  label={'Licensing with supply'}
                  option={requestDealTypesEnum.LicensingWithSupply.toString()}
                  onChange={(option) => {
                    if (option) {
                      setDealType(parseFloat(option));
                      setDealTypeError('');
                    }
                  }}
                  tooltip={
                    'Your company will be responsible for a product registration in your market(s) and becomes a marketing authorization holder (MAH) as well as the brand owner. Your company gets a supply from the manufacturer after the product is registered.'
                  }
                />
                <Radio
                  className={'deal-type-radio-item'}
                  id={
                    'deal-type-radio-item' +
                    requestDealTypesEnum.TechnologyTransfer.toString()
                  }
                  value={dealType.toString()}
                  option={requestDealTypesEnum.TechnologyTransfer.toString()}
                  label={'Licensing without supply (Technology transfer)'}
                  onChange={(option) => {
                    if (option) {
                      setDealType(parseFloat(option));
                      setDealTypeError('');
                    }
                  }}
                  tooltip={
                    'Your company buys a CTD dossier and transfers the technology to the selected manufacturing facility. Licensing rights are defined in the agreement specifying the territory. '
                  }
                />
                <Radio
                  className={'deal-type-radio-item'}
                  id={
                    'deal-type-radio-item' +
                    requestDealTypesEnum.DistributionWithoutLicensing.toString()
                  }
                  value={dealType.toString()}
                  option={requestDealTypesEnum.DistributionWithoutLicensing.toString()}
                  label={'Distribution without licensing'}
                  onChange={(option) => {
                    if (option) {
                      setDealType(parseFloat(option));
                      setDealTypeError('');
                    }
                  }}
                  tooltip={
                    'Your company acts as a distributor in the selected market(s) while the marketing authorization (MA) and/or brand name belongs to the dossier owner/manufacturer or to the company chosen by the dossier owner/manufacturer.'
                  }
                />
                <Radio
                  className={'deal-type-radio-item'}
                  id={
                    'deal-type-radio-item' +
                    requestDealTypesEnum.SpecialImport.toString()
                  }
                  value={dealType.toString()}
                  option={requestDealTypesEnum.SpecialImport.toString()}
                  label={'Special Import'}
                  onChange={(option) => {
                    if (option) {
                      setDealType(parseFloat(option));
                      setDealTypeError('');
                    }
                  }}
                  tooltip={
                    'Import of pharmaceuticals which are not available in your country for various reasons (without regular registration) and for which the special import permit can be granted.'
                  }
                />
                <Radio
                  className={'deal-type-radio-item'}
                  id={
                    'deal-type-radio-item' +
                    requestDealTypesEnum.ChangeOfContractManufacturing.toString()
                  }
                  value={dealType.toString()}
                  option={requestDealTypesEnum.ChangeOfContractManufacturing.toString()}
                  label={'Change of Contract Manufacturing'}
                  onChange={(option) => {
                    if (option) {
                      setDealType(parseFloat(option));
                      setDealTypeError('');
                    }
                  }}
                />
                {/* <Radio
                  className={'deal-type-radio-item'}
                  id={
                    'deal-type-radio-item' +
                    requestDealTypesEnum.ParallelImport.toString()
                  }
                  value={dealType.toString()}
                  option={requestDealTypesEnum.ParallelImport.toString()}
                  label={'Parallel import'}
                  onChange={(option) => {
                    if (option) {
                      setDealType(parseFloat(option));
                      setDealTypeError('');
                    }
                  }}
                  tooltip={
                    'Import of pharmaceuticals which will be registered in your selected market(s) through parallel import registration process using COA and/or COO documents.'
                  }
                /> */}
              </DealTypeContainer>
            )}
            <Select
              optionList={countries
                .filter((x) => x.region !== true)
                .map((x: any) => {
                  const disabled = sg(
                    () => product!.notAvailableMarkets.includes(x.id),
                    false
                  );
                  return {
                    value: x.id,
                    label: disabled ? `${x.name} (unavailable)` : x.name,
                    isDisabled: disabled,
                  };
                })}
              isMulti={true}
              onChangeMulti={(val) => {
                setMarketOfInterestSelect(val),
                  setMarketOfInterest(val.map((x) => x.value).join(','));
                setMarketOfInterestError('');
              }}
              label={'Market(s) of interest'}
              id={'product-request-market-of-interest'}
              values={marketOfInterestSelect}
              zIndex={'unset'}
              error={marketOfInterestError}
              placeholder={'Start typing for suggestions'}
            />
            <InputContainer>
              <TextInput
                onChange={(value) => {
                  setEstimateAnualPurchQuantity(value);
                  if (value !== '') {
                    setEstimateAnualPurchQuantityError('');
                  }
                }}
                id={'product-request-purchase-quantity'}
                value={estimateAnualPurchQuantity}
                label={
                  product
                    ? 'Estimated annual purchase quantity indicating pack size'
                    : ''
                }
                placeholder={
                  product
                    ? 'Year 1: 1000 packs of 20 tablets (40 mg). Year 2: 2500 packs of 20 tablets (40 mg)'
                    : ''
                }
                multiline={true}
                error={estimateAnualPurchQuantityError}
                tooltip={
                  product
                    ? 'Please provide a non-binding estimated annual purchase forecast for each strength indicating pack size.'
                    : ''
                }
              />
            </InputContainer>

            <InputContainer>
              <TextInput
                onChange={(value) => {
                  setLeadingCompetitorPriceLevel(value);
                }}
                id={'product-request-target-price'}
                value={leadingCompetitorPriceLevel}
                label={'Target supply price indicating pack size and currency'}
                multiline={true}
                tooltip={
                  'Please provide your target price for supply in order to get a competitive commercial offer'
                }
                placeholder={'3.5 EUR per pack of 20 tablets (40 mg)'}
              />
            </InputContainer>
            {product && (
              <InputContainer small={true}>
                <Select
                  id={'product-request-expect-sign-contract'}
                  optionList={expectSignContractOptions}
                  onChange={(val: ISelectItem) => {
                    if (val.value === '' || val === undefined) {
                      setExpectSignContract({ value: '', label: '' });
                    } else {
                      setExpectSignContract(val);
                      setExpectSignContractError('');
                    }
                  }}
                  hideSelectedOptions={false}
                  error={expectSignContractError}
                  label={'I expect to sign the contract'}
                  placeholder={'Please select'}
                  value={
                    expectSignContract && expectSignContract.value !== ''
                      ? expectSignContract
                      : undefined
                  }
                />
              </InputContainer>
            )}

            <InputContainer>
              <TextInput
                onChange={(value) => {
                  setComments(value);
                }}
                id={'product-request-comments'}
                value={comments}
                label={'Comments'}
                multiline={true}
                placeholder={
                  product ? 'I need bioequivalence studies' : undefined
                }
                maxLength={maxTextLen}
              />
            </InputContainer>
          </ProposalContainer>
          <ButtonLine>
            <ButtonLineItem>
              <OrangeButton
                label={'Send'}
                onClick={() => {
                  if (validate()) {
                    submit(
                      {
                        client,
                        values: {
                          product,
                          dealType,
                          estimatedAnualQuantity: estimateAnualPurchQuantity,
                          leadingPriceLevel: leadingCompetitorPriceLevel,
                          market: marketOfInterest,
                          comments,
                          expectSignContract:
                            expectSignContract &&
                            expectSignContract.value !== ''
                              ? expectSignContract.value
                              : undefined,
                        },
                      },
                      modalShown
                    );

                    const id = product ? product.id : undefined;

                    ReactGA.event({
                      category: GACategory ? GACategory.Marketplace : '',
                      action: GAActions
                        ? product
                          ? GAActions.requestProduct
                          : ''
                        : '',
                      value: id,
                      email,
                      anonymousUserId: anonymousUserIdItem,
                    });
                    ReactGA.event({
                      category: GACategory ? GACategory.RFQ : '',
                      action: GAActions ? GAActions.RFQ_SEND_SUCCESS : '',
                      label: undefined,
                      email,
                      anonymousUserId: anonymousUserIdItem,
                    });
                  } else {
                    ReactGA.event({
                      category: GACategory ? GACategory.RFQ : '',
                      action: GAActions ? GAActions.RFQ_SEND_ERROR : '',
                      label: undefined,
                      email,
                      anonymousUserId: anonymousUserIdItem,
                    });
                  }
                }}
              />
            </ButtonLineItem>
          </ButtonLine>
        </ProposalTextContainer>
      </form>
    </RequestFormContainer>
  );
};

export default RequestForm;
