import { routes } from 'app/config';
import { MarketplaceExistingDeal } from 'app/store/modules/auth/types';
import { TypeOrigin } from 'app/store/modules/global/types';
import {
  setEditModal,
  setEditProductId,
  setEditTradeModal,
} from 'app/store/modules/products/actions';
import { AnyListItem, Product } from 'app/store/modules/products/types';
import { IStore } from 'app/store/types';
import { connect } from 'react-redux';
import ProductItem from './product-item';

interface IStateProps {
  product?: Product;
  isAdmin: boolean;
  userCompany?: number;
  userDealProducts: MarketplaceExistingDeal[];
}
interface IDispatchProps {
  editModal: (productId: number, type: number) => any;
}
interface IOwnProps {
  isMarketplace: boolean;
  useProductCache?: boolean;
  isOnboarding?: boolean;
  listItem: AnyListItem;
  deleteModal?: (offerId: number, type: number) => any;
  isSeo?: boolean;
  hideGetOfferButton?: boolean;
  changeProductSelection?: (id: number, isSelected: boolean) => any;
  isProductSelected?: boolean;
}

export type IProps = IStateProps & IDispatchProps & IOwnProps;
export default connect<IStateProps, IDispatchProps, IOwnProps, IStore>(
  (state, ownProps) => {
    let product = ownProps.listItem.productId
      ? state.products.productsCache[ownProps.listItem.productId]
      : undefined;

    if (
      !ownProps.useProductCache &&
      (state.router.location.pathname.includes('/product/') ||
        state.router.location.pathname.includes('/offer/') ||
        state.router.location.pathname.includes('/trade-offer/') ||
        state.router.location.pathname.includes('/wholesale-offer/') ||
        state.router.location.pathname.includes(routes.marketplace) ||
        state.router.location.pathname.includes('-manufacturers')) &&
      ownProps.listItem.productId
    ) {
      product =
        state.marketplace.marketplaceProductCache[ownProps.listItem.productId];
    }

    return {
      product,
      isAdmin: state.auth.loggedInUser.isAdmin,
      userCompany:
        state.auth.loggedInUser && state.auth.loggedInUser.Company
          ? state.auth.loggedInUser.Company.id
          : 0,
      userDealProducts: state.auth.navbarCount.marketplaceExistingDealIds,
    };
  },
  (dispatch) => ({
    editModal: (productId, type) => {
      dispatch(setEditProductId(productId));
      if (type === TypeOrigin.licensing) {
        dispatch(setEditModal(true));
      }
      if (type === TypeOrigin.trade) {
        dispatch(setEditTradeModal(true));
      }
    },
  })
)(ProductItem);
