import { media } from 'app/styles/breakpoint';
import styled from 'styled-components';

export const RequestFormContainer = styled.div`
  margin-top: 3px;
  width: 100%;
  padding: 26px 30px;
  box-shadow: ${(props) =>
    props.theme.colors.boxShadowParam +
    ' ' +
    props.theme.colors.filtersButtonShadow};
  background-color: ${(props) => props.theme.colors.white};
  margin-top: 15px;
  margin-right: 15px;

  ${media.max.sm`
    padding-left:5%;
    padding-right:5%;
    margin-right: 0;
  `}
`;

export const ProposalContainer = styled.div`
  width: 100%;
`;

export const ProposalTextContainer = styled.div`
  width: 70%;
  ${media.max.sm`
    width: 100%;
  `}
`;

export const ButtonLine = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 30px;
  ${media.max.sm`
    justify-content:space-between;
  `}
`;

export const ButtonLineItem = styled.div`
  :first-child {
    margin-right: 10px;
  }

  ${media.max.sm`
    flex: 1
  `}
`;

export const ErrorMessage = styled.div`
  font-size: 11px;
  color: ${({ theme }) => theme.colors.errorMessage};
`;

export const DealTypeContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const InputContainer = styled.div<{ small?: boolean }>`
  margin-top: 24px;
  ${(props) =>
    props.small &&
    `
    width: 300px;
    max-width: 300px;
    ${media.max.xs`
      width: 100%;
      min-width: 200px;
    `}
  `}
`;

export const DealTypeLabel = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.grayExtraDark};
`;

export const Header = styled.div`
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-size: 20px;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.grayExtraDark};
  margin-bottom: 25px;
  padding-bottom: 25px;
  border-bottom: 1px solid ${(props) => props.theme.colors.grayLight};
`;
