import { media } from 'app/styles/breakpoint';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div<{ vertical: boolean }>`
  width: 100%;
  display: flex;
  ${(props) => props.vertical && `flex-direction:column;`};
  padding-top: 2px;
  padding-bottom: 2px;
`;

const Label = styled.div<{ lineHeight?: string }>`
  min-width: 130px;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: ${(props) => props.theme.colors.grayText};
  ${(props) =>
    props.lineHeight
      ? `line-height:  ${props.lineHeight};`
      : 'line-height:  1.6;'}
  letter-spacing: normal;
  ${media.max.sm`
  min-width: 120px;
  padding-right: 5px;
  `}
`;

const Value = styled.div<{
  valueFontSize: string;
  valueFlex?: boolean;
  lineHeight?: string;
}>`
  word-break: break-word;
  min-width: 150px;

  font-size: ${(props) => props.valueFontSize};
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: ${(props) => props.theme.colors.grayExtraDark};
  ${(props) =>
    props.lineHeight
      ? `line-height:  ${props.lineHeight};`
      : 'line-height:  1.4;'}

  letter-spacing: normal;
  padding-right: 15px;
  ${media.max.md`
  min-width: 130px;
  `}
  ${media.max.sm`
  min-width: 120px;
  `}
  ${(props) => props.valueFlex && 'flex: 1'}
`;

export interface IProps {
  label?: any;
  value?: any;
  vertical?: boolean;
  valueFontSize?: string;
  valueFlex?: boolean;
  lineHeight?: string;
  className?: string;
}

const ProductInfo: React.FC<IProps> = ({
  label,
  value,
  vertical = false,
  valueFontSize = '15px',
  valueFlex,
  lineHeight,
  ...props
}) => {
  if (!value || value === '') {
    return null;
  }
  return (
    <Container vertical={vertical} className={props.className}>
      <Label lineHeight={lineHeight}>{label}</Label>
      <Value
        valueFontSize={valueFontSize}
        valueFlex={valueFlex}
        lineHeight={lineHeight}
      >
        {value ? value : ''}
      </Value>
    </Container>
  );
};

export default ProductInfo;
