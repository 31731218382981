import { IStore } from 'app/store/types';
import { connect } from 'react-redux';
import CookieWindow from './cookies-window';

export interface StateProps {
  screenWidth: number;
}

export default connect<StateProps, any, any, IStore>((state) => ({
  screenWidth: state.device.width,
}))(CookieWindow);
