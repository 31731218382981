import Modal from 'app/components/modals/overrided-modal';
import SuggestAlternativeSellers from 'app/modules/products/suggest-alternative-sellers';
import { User } from 'app/store/modules/auth/types';
import { _, media } from 'app/styles/breakpoint';
import { Breakpoint } from 'app/styles/breakpoint';
import colors from 'app/styles/colors';
import React from 'react';
import styled from 'styled-components';

const ModalContainer = styled.div<{ breakpoint: string }>`
  margin: 0;
  z-index: 100;
  padding-top: 0px;
  align-items: flex-start;
  min-height: calc(
    ${typeof window !== 'undefined' &&
      typeof window !== undefined &&
      window !== undefined
        ? window.innerHeight
        : '500'}px -
      ${(props) =>
        props.breakpoint === Breakpoint.ExtraSmall ? '50px' : '130px'}
  );
`;

const IconContainer = styled.div`
  width: 100%;
  text-align: right;
  position: relative;
  > div {
    position: absolute;
    z-index: 99;
    margin: 0 auto;
    right: 35px;
    top: 10px;

    ${_(media.max.xs)} {
      top: 0;
    }
  }
`;

const CloseIcon = styled.img`
  margin-top: 16px;
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: fixed;
`;

interface Props {
  closeModal: (loggedInUser: User) => any;
  isOpen: boolean;
  loggedInUser: User;
  breakpoint: string;
}

const SuggestAlternativeSellersModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  loggedInUser,
  breakpoint,
}) => {
  const customStyles = {
    content: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderWidth: 0,
      borderRadius: 6,
      boxShadow: colors.modalShadow,
      overflow: 'auto',
      position: 'static',
      margin: 'auto',
      marginTop: breakpoint === Breakpoint.ExtraSmall ? '0' : '20px',
      maxHeight:
        breakpoint === Breakpoint.ExtraSmall ? '95%' : 'calc(100% - 130px)',
      width:
        breakpoint === Breakpoint.ExtraSmall ? '100%' : 'calc(100% - 40px)',
      maxWidth: 960,
      padding: 0,
      backgroundColor: colors.background,
    },
    overlay: {
      backgroundColor: colors.darkModalOverlay,
      zIndex: 999,
      display: 'flex',
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => {
        closeModal(loggedInUser);
      }}
      style={customStyles as any}
      contentLabel="Suggesting alternative sellers"
      ariaHideApp={false}
      shouldCloseOnOverlayClick={false}
    >
      <IconContainer>
        <div
          onClick={() => {
            closeModal(loggedInUser);
          }}
        >
          <CloseIcon src={'/img/close.svg'} />
        </div>
      </IconContainer>
      <ModalContainer breakpoint={breakpoint}>
        <SuggestAlternativeSellers />
      </ModalContainer>
    </Modal>
  );
};

export default SuggestAlternativeSellersModal;
