import React from 'react';
import styled, { css } from 'styled-components';
import { Link as ReduxLink } from 'react-router-dom';

import classNames from 'classnames';
import { _, media } from 'app/styles/breakpoint';

export interface MenuLinkProps {
  /**
   * Link destination
   */
  link?: string;
  /**
   * Link destination
   */
  href?: string;
  /**
   * Link text -- Can be text elements
   */
  children: React.ReactNode;
  /**
   * If the link is active
   */
  active?: boolean;
  /**
   * If the link is active
   */
  force?: boolean;
  /**
   * If the link is active
   */
  isSublink?: boolean;
  /**
   * For overrides
   */
  className?: string;
  /**
   * For overrides
   */
  onClick?: () => void;
}

// type LinkType = {
//   href: string;
//   [key: string]: any;
// };

const Link = styled(ReduxLink)`
  font-weight: 700;
  ${_(media.max.sm)} {
    justify-self: start;
    grid-column: 1 / -1;
  }
`;

const StyledLink = styled.a<{ $active: boolean; $text: string }>`
    display: flex;
    flex-direction: column;
    align-items: center;

    text-decoration: none;
    white-space: nowrap;
    transition: color 300ms;

    cursor: pointer;
    background-color: initial;
    border: initial;
    font-size: 16px;
  
    ${({ $active }) => {
      if ($active) {
        return css`
          font-weight: 700;
          color: ${(props) => props.theme.colors.primaryMain};
        `;
      } else {
        return css`
          font-weight: 400;
          color: ${(props) => props.theme.colors.black};
        `;
      }
    }}}

    &:hover {
        color: ${(props) => props.theme.colors.primaryMain};
    }

    &:active {
        color: ${(props) => props.theme.colors.primaryMain};
        font-weight: 700;

    }
    
    &::after {
        content: ${({ $text }) => `'${$text}'`};
        height: 0;
        visibility: hidden;
        overflow: hidden;
        user-select: none;
        pointer-events: none;
        font-weight: 700;
        
        @media speech {
          display: none;
        }
    }
`;

const MenuLink: React.FC<MenuLinkProps> = ({
  active = false,
  children,
  link,
  className,
  force,
  ...restProps
}) => {
  return link ? (
    force ? (
      <StyledLink
        $active={active}
        $text={children as string}
        className={classNames('MenuLink', className)}
        href={link}
      >
        {children}
      </StyledLink>
    ) : (
      <Link {...restProps} to={link as string}>
        <StyledLink
          $active={active}
          $text={children as string}
          className={classNames('MenuLink', className)}
        >
          {children}
        </StyledLink>
      </Link>
    )
  ) : (
    <StyledLink
      $active={false}
      $text={children as string}
      className={classNames('MenuLink', className)}
      as="button"
      href="#"
      style={{ padding: 0 }}
      {...restProps}
    >
      {children}
    </StyledLink>
  );
};

export default MenuLink;
