const menuSectionData = {
  publicLinks: [
    {
      text: 'Marketplace',
      link: 'https://www.pipelinepharma.com/marketplace',
      force: true,
    },
    {
      text: 'For buyers',
      link: 'https://www.pipelinepharma.com/buyer',
      force: true,
    },
    {
      text: 'For sellers',
      link: 'https://www.pipelinepharma.com/seller',
      force: true,
    },
    {
      text: 'About Us',
      link: 'https://www.pipelinepharma.com/about-us',
      force: true,
    },
    {
      text: 'Blog',
      link: 'https://www.pipelinepharma.com/blog',
      force: true,
    },
  ],
  privateLinks: [
    {
      text: 'Marketplace',
      link: 'https://www.pipelinepharma.com/marketplace',
      force: true,
    },
    { text: 'Buying', link: '/deals/buying' },
    {
      text: 'Selling',
      sublinks: [
        { text: 'Products', link: '/list-products' },
        { text: 'Deals', link: '/deals/selling' },
      ],
    },
  ],
  adminLinks: [
    {
      text: 'Marketplace',
      link: 'https://www.pipelinepharma.com/marketplace',
      force: true,
    },
    {
      text: 'Products',
      sublinks: [
        { text: 'List', link: '/list-products' },
        {
          text: 'Onboarding',
          link: '/onboarding-products',
        },
      ],
    },
    { text: 'Deals', link: '/deals' },
    { text: 'Requests', link: '/requests' },
    { text: 'Archive', link: '/requests-archive' },
    { text: 'Substances', link: '/list-substances' },
  ],
};

export const privateLegacyPaths = [
  'list-products',
  'onboarding-products',
  'list-substances',
  'deals',
  'deal-details',
  'requests',
  'requests-archive',
  'request-details',
  'users-settings',
];

export const publicLegacyPaths = [
  '/contacts',
  '/faq-for-buyers',
  '/faq-for-sellers',
  '/pharmaceutical-companies',
  '/pharmaceutical/manufacturers/countries',
  '/gdpr-compliant',
  '/terms-of-service',
  '/privacy',
  '/cookies',
];

export default menuSectionData;
