import { media } from 'app/styles/breakpoint';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

export const OrangeButtonContainer = styled.div`
  .tooltip-style {
    width: 305px;
    border: solid 1px ${(props) => props.theme.colors.grayLight} !important;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.18px;
    color: ${(props) => props.theme.colors.grayExtraDark};
  }
  .place-top::before {
    border-top: 8px solid ${(props) => props.theme.colors.grayLight} !important;
  }
`;

export const OrangeButtonStyle = styled.div<{ originalWidth?: boolean }>`
  width: 140px;
  height: 42px;
  border-radius: 3px;
  background-color: #f1b65a;
  font-size: 18px;
  font-weight: bold;
  line-height: 19px;
  text-align: center;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${(props) => props.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Nunito Sans';
  cursor: not-allowed;
  ${media.max.sm`
  
  ${(props: any) => !props?.originalWidth && `width: 100%;`}   
  `}
`;
interface Props {
  label: string;
  onClick?: () => any;
  id?: string;
  tooltipText?: string;
  originalWidth?: boolean;
}
const OrangeButtonDisabled: React.FC<Props> = ({
  label,
  onClick,
  id,
  tooltipText,
  originalWidth,
}) => {
  const tooltipId = `${label}-${id}`;

  return (
    <OrangeButtonContainer onClick={onClick}>
      {tooltipText && (
        <ReactTooltip
          id={tooltipId}
          effect="solid"
          multiline={true}
          className="tooltip-style"
          type="light"
          offset={{ right: 50 }}
          border={true}
        >
          {tooltipText}
        </ReactTooltip>
      )}
      <OrangeButtonStyle
        id={id}
        data-tip={true}
        data-for={tooltipId}
        originalWidth={originalWidth}
      >
        {label}
      </OrangeButtonStyle>
    </OrangeButtonContainer>
  );
};

export default OrangeButtonDisabled;
