import { formatDateString } from 'app/utils/format-datestring';
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

// custom imports
import AdminButton from 'app/components/buttons/admin-button';
import MarketViewButton from 'app/components/buttons/marketview-button';
import ConditionalContainer from 'app/components/misc/conditional-container';
import CheckBoxSimple from 'app/components/inputs/check-box-simple';
import FastTrack from 'app/components/inputs/fast-track';
import AddToDeal from 'app/modules/marketplace/components/marketplace-list/components/add-to-deal';
import ProductInfo from 'app/modules/marketplace/components/marketplace-list/components/product-info';
import {
  Props as OverlayProps,
  ToolTipOverlay,
} from 'app/components/tooltip/tooltip-overlay';
import { routes } from 'app/config';
import { MarketplaceExistingDeal } from 'app/store/modules/auth/types';
import { TypeOrigin } from 'app/store/modules/global/types';
import { getProductName } from 'app/store/modules/marketplace/actions';
import {
  getAvailableFor,
  getCountries,
  getCountriesEuAsOne,
  getDossierComplete,
  getDossierStatus,
  getDossierType,
  getGPMApprovals,
  getProductLink,
  getStabilityZones,
} from 'app/store/modules/products/actions';
import { sg, sgg } from 'app/utils/safe-get';
import { IProps } from './index';
import { MarketingBubble, MarketingSectionWrapper } from '../marketing-bubble';
import {
  ButtonDivider,
  ButtonLine,
  ButtonLineView,
  Container,
  DisabledLink,
  Header,
  LinkWrap,
  ProductAllInfoContainer,
  ProductHeader,
  ProductInfoContainer,
  ProductInfoFullWidthContainer,
  ProductNameContainer,
  ProductSubHeader,
  ShowMobile,
  ShowMobileView,
  YouGotDealForThisRequest,
  YouGotDealForThisRequestIcon,
  YouGotDealForThisWrapper,
} from './styles';
import { ProductCategoryEnum } from 'app/store/modules/products/types';

const dossierUnderDevelopmentId = 2;

const ProductItem: React.FC<IProps> = ({
  product,
  editModal,
  isAdmin,
  deleteModal,
  isMarketplace,
  listItem,
  isOnboarding,
  isSeo,
  userDealProducts,
  hideGetOfferButton,
  changeProductSelection,
  isProductSelected,
  ...props
}) => {
  let id = 0;
  let type = 0;
  let prefix = '';
  let productPageLink = '';
  let productName = '';
  let formStrenght = '';
  let marketingMessage = '';
  let company = '';
  let companyId;
  let updatedAt = '';
  let createLink = '/';
  let updateLink = '/';
  let markUserSelfManagedItemForAdmin = false;
  let productDeal: MarketplaceExistingDeal | undefined;
  let dealLink = '/';
  let adminComments = '';
  let adminCreated;
  let fastProduct;

  if (listItem.productId) {
    id = listItem.productId;
  }

  if (product) {
    prefix = 'product';
    type = TypeOrigin.licensing;
    productPageLink = encodeURIComponent(getProductLink(product, true));

    productName = product ? getProductName(product) : '';
    formStrenght =
      (product.formSpecification ? product.formSpecification : '') +
      ' ' +
      (product.strength ? product.strength : '') +
      (product.volume ? ' - ' + product.volume : '');
    marketingMessage = product.marketingMessage ? product.marketingMessage : '';

    company = product.Company ? product.Company.name : '';
    companyId = product.Company ? product.Company.id : undefined;
    updatedAt = formatDateString(product.updatedAt);
    createLink = routes.new_offer + '/' + (product ? product.id : '');
    updateLink = routes.update_product + '/' + (product ? product.id : '');
    markUserSelfManagedItemForAdmin = !product.adminCreated && isAdmin;
    adminComments = isAdmin ? product.adminComments : '';
    adminCreated = product.adminCreated;
    fastProduct = product.fastProduct;
    const dealLen = userDealProducts.filter(
      (x) =>
        x.Product &&
        x.Product.mainProductId &&
        x.Product.mainProductId.toString() === id.toString()
    ).length;
    productDeal = userDealProducts
      ? sg(
          () =>
            userDealProducts.filter(
              (x) =>
                x.Product &&
                x.Product.mainProductId &&
                x.Product.mainProductId.toString() === id.toString()
            )[dealLen - 1],
          undefined
        )
      : undefined;
  }

  if (productDeal) {
    if (productDeal.archivedAt) {
      dealLink = '/archive-info/' + productDeal.id;
    } else {
      dealLink = '/request-details/' + productDeal.id;
    }
  }

  const showMoreGetOfferButtonInMarketplace =
    !hideGetOfferButton &&
    product !== null &&
    product !== undefined &&
    product.Company !== undefined &&
    product.Company !== null &&
    !(props.userCompany === product.Company.id && !product.adminCreated);

  const disabled = product ? product.status === 0 : false;
  const LinkWCheck = disabled
    ? ({ children, ...p }: OverlayProps) => (
        <ToolTipOverlay {...p}>
          <DisabledLink>{children}</DisabledLink>
        </ToolTipOverlay>
      )
    : ({ children, ...p }: LinkProps) => <Link {...p}>{children}</Link>;
  const renderButtonProduct = () => {
    return (
      <>
        <LinkWrap>
          <LinkWCheck
            to={createLink}
            tooltip={
              'You can add offer once product is approved by Pipelinepharma admin'
            }
          >
            <MarketViewButton label={'Add offer'} disabled={disabled} />
          </LinkWCheck>
        </LinkWrap>
        <ButtonDivider key={'2'} />
        {isMarketplace || isOnboarding ? (
          <MarketViewButton
            label={'Edit'}
            onClick={() => {
              if (product) {
                editModal(id, TypeOrigin.licensing);
              }
            }}
          />
        ) : (
          <LinkWrap>
            <Link to={updateLink}>
              <MarketViewButton label={'Edit'} />
            </Link>
          </LinkWrap>
        )}
        <ButtonDivider />
        <ButtonDivider />
        <MarketViewButton
          label={'Delete'}
          onClick={() => {
            if (product && deleteModal) {
              deleteModal(id, TypeOrigin.licensing);
            }
          }}
        />
      </>
    );
  };
  const renderButton = () => {
    return (
      <>
        {!isSeo && (
          <ConditionalContainer
            show={
              isAdmin ||
              sg(
                () =>
                  product !== undefined &&
                  product !== null &&
                  props.userCompany === product.Company.id &&
                  !product.adminCreated,
                false
              )
            }
          >
            <AdminButton
              label={'Delete'}
              onClick={() => {
                if (product && deleteModal) {
                  deleteModal(id, TypeOrigin.licensing);
                }
              }}
            />
            <ButtonDivider />
            <AdminButton
              label={'Edit'}
              onClick={() => {
                if (product) {
                  editModal(id, TypeOrigin.licensing);
                }
              }}
            />
            <ButtonDivider />
          </ConditionalContainer>
        )}
        <ConditionalContainer show={productDeal && !isAdmin ? true : false}>
          <YouGotDealForThisWrapper>
            <YouGotDealForThisRequest>
              <YouGotDealForThisRequestIcon src={'/img/error-icon-gray.svg'} />{' '}
              You’ve already sent request for this product
            </YouGotDealForThisRequest>
          </YouGotDealForThisWrapper>
          <Link to={dealLink} style={{ maxWidth: 140 }}>
            <AdminButton marginRight={'15px'} label={'View deal'} />
          </Link>
        </ConditionalContainer>
        <AddToDeal
          isMarketplace={isMarketplace}
          offerButton={true}
          redirectToDeal={false}
          productDetails={product}
          showMoreGetOfferButtonInMarketplace={
            showMoreGetOfferButtonInMarketplace
          }
        />
      </>
    );
  };

  const shouldRenderFullWidth = () => {
    if (product) {
      const all =
        getCountries(product.notAvailableMarkets) +
        getCountries(product.registeredIn) +
        getAvailableFor(product) +
        adminComments;

      return all.length > 0;
    } else {
      return false;
    }
  };

  const companyLink = (
    <a
      className="company-link"
      href={
        'https://django.pipelinepharma.com/admin/pp_admin/companies/' +
        companyId
      }
      target={'_blank'}
      rel="noreferrer"
    >
      {company}
    </a>
  );

  return (
    <Container
      markUserSelfManagedItemForAdmin={markUserSelfManagedItemForAdmin}
    >
      <Header>
        <ProductNameContainer
          onClick={() => {
            if (changeProductSelection) {
              changeProductSelection(
                sg(() => product!.id, 0),
                !isProductSelected
              );
            }
          }}
          isClickable={!!changeProductSelection}
        >
          {isMarketplace && showMoreGetOfferButtonInMarketplace ? (
            <Link to={`/${prefix}/${type}/${productPageLink}`}>
              <ProductHeader>{productName}</ProductHeader>
              <ProductSubHeader>{formStrenght}</ProductSubHeader>
            </Link>
          ) : (
            <div>
              <ProductHeader>
                {!!changeProductSelection && (
                  <CheckBoxSimple
                    value={!!isProductSelected}
                    onChange={() => {
                      if (changeProductSelection) {
                        changeProductSelection(
                          sgg(() => product!.id, 0),
                          !isProductSelected
                        );
                      }
                    }}
                  />
                )}
                {productName}
              </ProductHeader>
              <ProductSubHeader>{formStrenght}</ProductSubHeader>
            </div>
          )}

          <MarketingSectionWrapper>
            {marketingMessage !== '' &&
              marketingMessage !== undefined &&
              marketingMessage !== null && (
                <MarketingBubble>{marketingMessage}</MarketingBubble>
              )}
            {(adminCreated === false || fastProduct) && (
              <FastTrack
                marketingMessageExist={
                  marketingMessage !== undefined &&
                  marketingMessage !== null &&
                  marketingMessage.length > 0
                }
              />
            )}
          </MarketingSectionWrapper>
        </ProductNameContainer>
        {!changeProductSelection &&
          (isMarketplace ? (
            <ButtonLine>{renderButton()}</ButtonLine>
          ) : (
            <ButtonLineView>{renderButtonProduct()}</ButtonLineView>
          ))}
      </Header>
      <ProductAllInfoContainer>
        <ProductInfoContainer>
          {product && product?.productCategory && (
            <ProductInfo
              label={'Product Category'}
              value={product && ProductCategoryEnum[product?.productCategory]}
            />
          )}
          {product && (
            <ProductInfo
              label={'Dossier type'}
              value={product && getDossierType(product.dosierType)}
            />
          )}
          {product && (
            <ProductInfo
              label={'Dossier status'}
              value={product && getDossierStatus(product.dosierStatus)}
            />
          )}
          {product && product.dosierStatus === dossierUnderDevelopmentId && (
            <ProductInfo
              label={'Completion date'}
              value={product && getDossierComplete(product)}
            />
          )}
          {product && (
            <ProductInfo
              label={'GMP approvals'}
              value={
                product && getGPMApprovals(product.gmpApprovalForThisProduct)
              }
            />
          )}
          {product && (
            <ProductInfo
              label={'Free MAs'}
              value={
                product &&
                getCountries(product.marketingAuthorizationsAchievedCountry)
              }
            />
          )}
          {product && (
            <ProductInfo
              label={'Shelf life'}
              value={product && product.shelfLife !== '' && product.shelfLife}
            />
          )}
        </ProductInfoContainer>
        <ProductInfoContainer>
          {isAdmin && <ProductInfo label={'Company'} value={companyLink} />}
          {isAdmin && <ProductInfo label={'Last update'} value={updatedAt} />}
          {product && (
            <ProductInfo
              label={'Country of origin'}
              value={
                product && getCountriesEuAsOne(product.finishedDoseCountry)
              }
            />
          )}
          {product && (
            <ProductInfo
              label={'Stability zones'}
              value={product && getStabilityZones(product.stabilityDataZones)}
            />
          )}
          {product &&
            product.clinicalData &&
            product.clinicalData !== '' &&
            product.clinicalData !== null && (
              <ProductInfo
                label={'Clinical data'}
                value={product.clinicalData}
              />
            )}
          {product && (
            <ProductInfo
              label={'CPP available from'}
              value={product && getCountries(product.cppAvailableFrom)}
            />
          )}
          {product && (
            <ProductInfo
              label={'Batch size'}
              value={product && product.batchSize !== '' && product.batchSize}
            />
          )}
          {product && (
            <ProductInfo
              label={'MOQ'}
              value={product && product.moq !== '' && product.moq}
            />
          )}
          {product && (
            <ProductInfo
              label={'Pack size'}
              value={product && product.packSize !== '' && product.packSize}
            />
          )}
        </ProductInfoContainer>
        {shouldRenderFullWidth() && (
          <ProductInfoFullWidthContainer>
            {product && (
              <ProductInfo
                label={'Unavailable markets'}
                value={product && getCountries(product.notAvailableMarkets)}
              />
            )}
            {product && (
              <ProductInfo
                label={'Registered in'}
                value={product && getCountries(product.registeredIn)}
              />
            )}
            {product && (
              <ProductInfo
                label={'Available for'}
                value={product && getAvailableFor(product)}
              />
            )}
            {product && isAdmin && (
              <ProductInfo label={'Admin comments'} value={adminComments} />
            )}
          </ProductInfoFullWidthContainer>
        )}
      </ProductAllInfoContainer>
      {isMarketplace ? (
        <ShowMobile>{renderButton()}</ShowMobile>
      ) : (
        <ShowMobileView>{renderButtonProduct()}</ShowMobileView>
      )}
    </Container>
  );
};

export default ProductItem;
