import { setBuyerQualificationModalShown } from 'app/store/modules/auth/actions';
import {
  createProductDeal,
  ICreateProductDealAction,
  setCreateRequestAfterRegister,
} from 'app/store/modules/requests/actions';
import { IStore } from 'app/store/types';
import { withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import RequestForm from './request-form';

export default withApollo<any>(
  connect(
    (state: IStore) => {
      return {
        userCompany: state.auth.userCompany,
      };
    },
    (dispatch, ownProps) => {
      return {
        submit: (params: ICreateProductDealAction, modalShown: boolean) => {
          dispatch(
            setCreateRequestAfterRegister({
              product: undefined,
            })
          );
          dispatch(createProductDeal(params));
          dispatch(setBuyerQualificationModalShown(modalShown));
        },
      };
    }
  )(RequestForm)
);
